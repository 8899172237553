import { useEffect, useState } from "react";
import {
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../redux/queries/users";
import {
  Button,
  Datepicker,
  Label,
  Select,
  Spinner,
  TextInput,
  useThemeMode,
} from "flowbite-react";
import emptyIcon from "../../assets/empty.png";
import ImageView from "../../components/ImageView";
import { CiEdit } from "react-icons/ci";
import Modal from "react-responsive-modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { LiaTimesSolid } from "react-icons/lia";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore } from "react-icons/ci";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  useAssignCityMutation,
  useGetAssignedCityQuery,
  useGetCitiesQuery,
} from "../../redux/queries/location";
import Reactselect from "react-select";
import { lowerCase } from "lodash";
import CustomPagination from "../../components/CustomPagination";
import moment from "moment";

interface UsersCard {
  id: string;
  country: BranchCountry;
  type: string;
}
const CustomerUsers = (props: UsersCard) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<User | undefined>(undefined);
  const { id, country, type } = props;
  const [modal, setModal] = useState("");
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: id,
    type: type,
  });
  const { data: users, isLoading } = useGetUsersQuery(filters);
  console.log('id', id)

  if (isLoading) {
    return (
      <div className="flex justify-center items-center pt-5">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
      <div className="bg-white dark:bg-gray-800 p-2">
        <span className="font-semibold text-[13px] dark:text-gray-200">
          Company {type}s
        </span>
      </div>
      <CustomPagination
        setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
        isLoading={isLoading}
        data={users?.data}
        total={users?.count ?? 0}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Phone
              </th>
              <th scope="col" className="px-3 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {users?.data.map((item) => (
              <tr
                key={item.uuid}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="px-6">
                  <ImageView url={item.avatar_link} className="w-8 h-8" />
                </td>
                <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">
                  {item.name}
                </td>
                <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">
                  {item.email === "" ? "-" : item.email}
                </td>
                <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">
                  {item.phone}
                </td>
                <td className="px-3 py-4">
                  <CustomDropdown
                    trigger={
                      <div className="">
                        <CiCircleMore className="text-2xl" />
                      </div>
                    }
                    menu={[
                      {
                        icon: CiEdit,
                        title: "Edit",
                        action: () => {
                          setIsOpen(true);
                          setModal("EDIT");
                          setUserDetails(item);
                        },
                      },
                      {
                        icon: MdOutlineAssignmentTurnedIn,
                        title: "Assign",
                        action: () => {
                          setIsOpen(true);
                          setModal("ASSIGN");
                          setUserDetails(item);
                        },
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CustomPagination>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {modal === "EDIT" && (
          <EditUser
            user={userDetails}
            country={country}
            close={() => setIsOpen(false)}
          />
        )}
        {modal === "ASSIGN" && (
          <AssignLocation
            user={userDetails}
            country={country}
            close={() => setIsOpen(false)}
          />
        )}
      </Modal>
      {users?.status === 404 && (
        <div className="flex items-center justify-center my-5">
          <div className="flex flex-col items-center">
            <img src={emptyIcon} alt="empty record" className="w-20" />
            <span className="dark:text-gray-400 mt-2 text-xs">
              No record found
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerUsers;

interface UserCard {
  user: User | undefined;
  close: () => void;
  country?: BranchCountry;
}

interface IFormInput {
  name: string;
  email?: string;
  phone: string;
  status: string;
  nin?: string;
  bvn?: string;
  religion?: string;
  whatsapp: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup
    .string()
    .min(4, "Phone number is a required field")
    .required("Phone number is a required field"),
  nin: yup.string(),
  bvn: yup.string(),
  religion: yup.string(),
  status: yup.string().required("Status is a required field"),
  whatsapp: yup.string().required("Whatsapp is a required field"),
});
const EditUser = (props: UserCard) => {
  const { close, user, country } = props;
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { mode } = useThemeMode();
  const [phoneData, setPhoneData] = useState<any>();
  const [whatsappData, setWhatsappData] = useState<any>();
  const [others, setOthers] = useState({
    birthday: user?.birthday ? user?.birthday : "",
    wedding_anniversary: user?.wedding_anniversary
      ? user?.wedding_anniversary
      : "",
  });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormInput) => {
    const payload = {
      ...data,
      birthday: others.birthday,
      wedding_anniversary: others.wedding_anniversary,
      phone_code: phoneData?.countryCode ?? "ng",
      whatsapp_code: whatsappData?.countryCode ?? "ng",
    };
    const res = await updateUser({ ...payload, uuid: user?.uuid });
    //console.log(res);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        close();
      } else toast.error(`${res.data.data} - ${res.data.message as string}`);
    } else toast.error("Unable to update record");
  };

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phone", user.phone);
      setValue("nin", user.nin);
      setValue("religion", user.religion);
      setValue("bvn", user.bvn);
      setValue("status", user.status.toString());
      setValue("whatsapp", user.whatsapp);
    }
  }, [user, setValue]);

  const religionOption = [
    "Christianity",
    "Muslim",
    "Traditional worshiper",
    "Others",
  ];

  return (
    <div className="w-[600px] h-[600px] rounded-t-lg bg-white">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Edit User Info</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="bg-white p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-3">
          <div>
            <ImageView url={user?.avatar_link} className="w-20 h-20" />
          </div>
          <div className="flex-1">
            <div
              className="grid grid-cols-2 gap-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Name"
                  {...register("name")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Email" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Email"
                  {...register("email")}
                  color={errors?.email ? "failure" : "gray"}
                  helperText={
                    errors.email && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.email?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Birthday" />
                <Datepicker
                  value={moment(others.birthday).format("Do MMM, YYYY")}
                  onSelectedDateChanged={(date: any) =>
                    setOthers((prev) => ({
                      ...prev,
                      birthday: moment(date).format("YYYY-MM-DD"),
                    }))
                  }
                  className="w-full"
                  style={{
                    backgroundColor:
                      mode === "light" ? "rgb(249 250 251)" : "rgb(75 85 99)",
                    border: `1px solid ${
                      mode === "light" ? "rgb(209 213 219)" : "rgb(75 85 99)"
                    }`,
                    width: "100%",
                    outline: "none",
                    height: "35px",
                    fontSize: "13px",
                    borderRadius: "5px",
                  }}
                  name="date"
                  datepicker-format={"YYYY-MM-DD"}
                />
              </div>
              <div>
                <Label value="Phone" />
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <PhoneInput
                        value={value}
                        onChange={(phone, countryData) => {
                          onChange(phone);
                          setPhoneData(countryData);
                        }}
                        country={lowerCase(country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "auto",
                          paddingRight: "5px",
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          top: -20,
                          left: 0,
                        }}
                      />
                      {errors.phone && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.phone?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
              <div>
                <Label value="Wedding Anniversary" />
                <Datepicker
                  value={moment(others.wedding_anniversary).format(
                    "Do MMM, YYYY"
                  )}
                  onSelectedDateChanged={(date: any) =>
                    setOthers((prev) => ({
                      ...prev,
                      wedding_anniversary: moment(date).format("YYYY-MM-DD"),
                    }))
                  }
                  className="w-full"
                  style={{
                    backgroundColor:
                      mode === "light" ? "rgb(249 250 251)" : "rgb(75 85 99)",
                    border: `1px solid ${
                      mode === "light" ? "rgb(209 213 219)" : "rgb(75 85 99)"
                    }`,
                    width: "100%",
                    outline: "none",
                    height: "35px",
                    fontSize: "13px",
                    borderRadius: "5px",
                  }}
                  name="date"
                  datepicker-format={"YYYY-MM-DD"}
                />
              </div>
              <div>
                <Label value="status" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("status")}
                  color={errors?.status ? "failure" : "gray"}
                  helperText={
                    errors.status && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.status?.message}!
                      </span>
                    )
                  }
                >
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </Select>
              </div>
              <div>
                <Label value="Nin" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="NIN"
                  {...register("nin")}
                />
              </div>
              <div>
                <Label value="BVN" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="BVN"
                  {...register("bvn")}
                />
              </div>
              <div>
                <Label value="Religion" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("religion")}
                >
                  <option value="" disabled hidden>
                    Select a Religion
                  </option>
                  {religionOption.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label value="WhatsApp Number" />
                <Controller
                  name="whatsapp"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <PhoneInput
                        value={value}
                        onChange={(phone, countryData) => {
                          onChange(phone);
                          setWhatsappData(countryData);
                        }}
                        country={lowerCase(country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "whatsapp",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.whatsapp
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.whatsapp
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "auto",
                          paddingRight: "5px",
                          backgroundColor: errors?.whatsapp
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.whatsapp
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          bottom: 20,
                          right: 0,
                        }}
                      />
                      {errors.whatsapp && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.whatsapp?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="xs"
            className="rounded-sm"
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

const AssignLocation = (props: UserCard) => {
  const { close, user, country } = props;
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const filters = {
    country_id: country?.id?.toString(),
    staff_uuid: user?.uuid,
  };
  const [assignCity, { isLoading }] = useAssignCityMutation();
  const handleChange = (option: any) => {
    setSelectedOption(option);
  };
  const { data: cityList } = useGetAssignedCityQuery(filters);
  const { data: cities } = useGetCitiesQuery(filters);

  const formattedOptions = cities?.data?.map((option: City) => ({
    value: option.id,
    label: option.name,
  }));

  const onSubmit = async () => {
    const payload = {
      city_id: selectedOption?.value,
      staff_uuid: user?.uuid,
    };

    if (selectedOption?.value) {
      const res = await assignCity({ ...payload });
      if ("data" in res) {
        if (res.data.status === 200 || res.data.status === 100) {
          toast.success(res.data.message);
          close();
        } else
          toast.error(
            `${res.data.data.join("-")} - ${res.data.message as string}`
          );
      } else toast.error("Unable to update record");
    } else {
      toast.error("Select City");
    }
  };

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Assign Info</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="bg-white p-3 pb-5" onSubmit={onSubmit}>
        <div className="flex gap-3">
          <div>
            <ImageView url={user?.avatar_link} className="w-20 h-20" />
          </div>
          <div className="flex-1">
            <div className="grid grid-cols-2 gap-3">
              <div>
                <Label value="City" className="dark:text-gray-800" />
                <Reactselect
                  value={selectedOption}
                  onChange={handleChange}
                  options={formattedOptions}
                  isSearchable
                  placeholder="Add City"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {cityList?.data && (
            <div className="border-t border-b border-grey-800 mb-4 pt-5 pb-5 mt-4">
              <Label
                value="List of city assigned:"
                className="dark:text-gray-800 font-medium"
              />
              <div className="flex-wrap flex gap-3 items-center mt-4">
                {cityList?.data?.map((assignCity) => (
                  <span
                    key={assignCity.uuid}
                    className="bg-gray-300 text-black p-1 text-xs font-medium border border-gray-400 rounded"
                  >
                    {assignCity?.city?.name}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="xs"
            className="rounded-sm"
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
