import { TbBrandWish, TbEdit, TbPackages, TbTruckReturn } from "react-icons/tb";
import {
  IoListCircleSharp,
  IoNotifications,
  IoStatsChart,
} from "react-icons/io5";
import {
  MdAssignmentAdd,
  MdRequestQuote,
  MdPointOfSale,
  MdLocalGroceryStore,
  MdPayments,
  MdLocalHospital,
  MdPedalBike,
} from "react-icons/md";
import { ImPriceTags } from "react-icons/im";
import { PiUsersFourFill, PiUsersFill, PiGearFineBold } from "react-icons/pi";
import { FaFileInvoice, FaQuestionCircle } from "react-icons/fa";
import { FaChartPie, FaPeopleGroup, FaPerson, FaWallet } from "react-icons/fa6";
import { BsBlockquoteLeft, BsBookshelf, BsBank } from "react-icons/bs";
import {
  BiSolidBarcode,
  BiSolidSortAlt,
  BiTransferAlt,
  BiSolidPlaneTakeOff,
} from "react-icons/bi";
import { IoMdGitBranch } from "react-icons/io";
import { RiCouponLine, RiStockFill } from "react-icons/ri";
import { SiAuth0, SiGoogletagmanager, SiFuturelearn } from "react-icons/si";
import { LuHistory } from "react-icons/lu";
import { GiMeepleGroup, GiExpense, GiCardDiscard } from "react-icons/gi";
import { CiMoneyCheck1 } from "react-icons/ci";
import { TiMessages } from "react-icons/ti";
import { GrTransaction, GrOverview } from "react-icons/gr";
import { AiOutlineStock } from "react-icons/ai";
import { LiaWindowRestoreSolid } from "react-icons/lia";
import { HiCash } from "react-icons/hi";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { CiBank } from "react-icons/ci";

const sideBarMenu = [
  {
    title: "Dashboard",
    route: "/dashboard",
    icon: IoStatsChart,
    isNew: false,
    code: "dash",
    type: ["manufacturer", "pharmacy"],
  },
  {
    title: "Product",
    route: "",
    icon: TbPackages,
    isNew: false,
    code: "prod",
    type: ["manufacturer", "pharmacy"],
    children: [
      {
        title: "Product List",
        route: "/products",
        icon: IoListCircleSharp,
        code: "prod-list",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Add Product",
        route: "/add-product",
        icon: MdAssignmentAdd,
        code: "prod-add",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Product Barcode",
        route: "/barcode",
        icon: BiSolidBarcode,
        code: "prod-brc",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Discards",
        route: "/discards",
        icon: GiCardDiscard,
        code: "prod-dsc",
        type: ["manufacturer", "pharmacy"],
      },
    ],
  },

  {
    title: "Sales",
    route: "",
    icon: MdRequestQuote,
    isNew: false,
    code: "iord",
    type: ["manufacturer", "pharmacy"],
    children: [
      {
        title: "Add Invoice",
        route: "/invoice",
        icon: FaFileInvoice,
        code: "iord-inv",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Orders",
        route: "/orders",
        icon: ImPriceTags,
        code: "iord-ord",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Order Returns",
        route: "/returnwithoutid",
        icon: TbTruckReturn,
        code: "iord-out",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Add Quotation",
        route: "/quotations",
        icon: BsBlockquoteLeft,
        code: "iord-quo",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Returns History",
        route: "/salesreturnhistory",
        icon: TbTruckReturn,
        code: "iord-out",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "POS",
        route: "/pos",
        icon: MdPointOfSale,
        code: "iord-pos",
        type: ["manufacturer", "pharmacy"],
      },
    ],
  },
  {
    title: "Purchases",
    route: "",
    icon: RiStockFill,
    isNew: false,
    code: "pur",
    type: ["manufacturer", "pharmacy"],
    children: [
      {
        title: "Add Purchase",
        route: "/add-purchase",
        icon: MdLocalGroceryStore,
        code: "pur-aph",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Local Purchases",
        route: "/purchase-history",
        icon: RiCouponLine,
        code: "pur-phi",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Direct Purchases",
        route: "/online-purchases",
        icon: LuHistory,
        code: "pur-dir",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Purchase Returns",
        route: "/purchase-return",
        icon: TbTruckReturn,
        code: "pur-rtn",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Stock Transfer",
        route: "/stock-history",
        icon: LuHistory,
        code: "pur-str",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Return History",
        route: "/return-purchase-history",
        icon: TbTruckReturn,
        code: "pur-rtn-his",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Add Stock Transfer",
        route: "/stock-transfer",
        icon: BiTransferAlt,
        code: "pur-astr",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "WishList",
        route: "/wishlist",
        icon: TbBrandWish,
        code: "pur-wil",
        type: ["manufacturer", "pharmacy"],
      },
    ],
  },
  {
    title: "Stock Count",
    route: "",
    icon: BiSolidSortAlt,
    code: "stc",
    type: ["manufacturer", "pharmacy"],
    children: [
      {
        title: "Stock Count",
        route: "/stock-count",
        icon: BiSolidSortAlt,
        code: "stc-cnt",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Shelfs",
        route: "/shelfs",
        icon: BsBookshelf,
        code: "stc-shlf",
        isNew: false,
        type: ["manufacturer", "pharmacy"],
      },
    ],
  },
  {
    title: "Stock Management",
    route: "",
    icon: SiGoogletagmanager,
    code: "stm",
    type: ["manufacturer", "pharmacy"],
    children: [
      {
        title: "Product Management",
        route: "/product-management",
        icon: TbEdit,
        isNew: false,
        code: "stm-pmng",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Product Statistics",
        route: "/product-statistics",
        icon: FaChartPie,
        isNew: false,
        code: "stm-stat",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Stock Movement",
        route: "/stock-movement",
        icon: AiOutlineStock,
        isNew: false,
        code: "stm-smv",
        type: ["manufacturer", "pharmacy"],
      },
    ],
  },
  {
    title: "Customers",
    route: "",
    icon: PiUsersFourFill,
    code: "cst",
    type: ["manufacturer", "pharmacy"],
    children: [
      {
        title: "Patients",
        route: "/patients",
        icon: FaPerson,
        isNew: false,
        code: "cst-pha",
        type: ["pharmacy"],
      },
      {
        title: "Pharmacy",
        route: "/customers",
        icon: PiUsersFourFill,
        isNew: false,
        code: "cst-cst",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Manufacturer",
        route: "/manufacturer",
        icon: PiUsersFourFill,
        isNew: false,
        code: "cst-man",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Hospital",
        route: "/hospital",
        icon: MdLocalHospital,
        isNew: false,
        code: "cst-hos",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Selling Groups",
        route: "/selling-groups",
        icon: GiMeepleGroup,
        code: "cst-slg",
        type: ["manufacturer", "pharmacy"],
      },
    ],
  },
  {
    title: "Manage Staffs",
    route: "",
    icon: PiUsersFill,
    code: "empm",
    type: ["manufacturer", "pharmacy"],
    children: [
      {
        title: "Employees",
        route: "/employees",
        icon: PiUsersFill,
        isNew: false,
        code: "empm-emp",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Departments",
        route: "/departments",
        icon: LiaWindowRestoreSolid,
        isNew: false,
        code: "emp-dpt",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Attendance",
        route: "/attendance",
        icon: FaPeopleGroup,
        isNew: false,
        code: "empm-atd",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Permissions",
        route: "/permissions",
        icon: SiAuth0,
        isNew: false,
        code: "empm-per",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Payout",
        route: "/payout",
        icon: MdPayments,
        isNew: false,
        code: "empm-out",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Riders",
        route: "/riders",
        icon: MdPedalBike,
        isNew: false,
        code: "empm-out",
        type: ["manufacturer", "pharmacy"],
      },
    ],
  },
  {
    title: "Accounting",
    route: "",
    icon: BsBank,
    isNew: false,
    code: "acc",
    type: ["manufacturer", "pharmacy"],
    children: [
      {
        title: "Transactions",
        route: "/transactions",
        icon: GrTransaction,
        code: "acc-trns",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Wallet",
        route: "/wallet",
        icon: FaWallet,
        code: "acc-wlt",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Cheques",
        route: "/cheque",
        icon: CiMoneyCheck1,
        code: "acc-chq",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Cash",
        route: "/cash",
        icon: HiCash,
        code: "acc-csh",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Bank",
        route: "/bank",
        icon: CiBank,
        code: "acc-bnk",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Loans",
        route: "/loanhistory",
        icon: BiSolidPlaneTakeOff,
        code: "acc-lon",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Income Statement",
        route: "/income",
        icon: SiFuturelearn,
        code: "acc-inc",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Customer Overview",
        route: "/overview",
        icon: GrOverview,
        code: "acc-cov",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Expenses",
        route: "/expenses",
        icon: GiExpense,
        code: "acc-exp",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Add Loans",
        route: "/addloans",
        icon: BiSolidPlaneTakeOff,
        code: "acc-lon",
        type: ["manufacturer", "pharmacy"],
      },
      {
        title: "Invoices",
        route: "/invoices",
        icon: LiaFileInvoiceSolid,
        code: "acc-inv",
        type: ["manufacturer", "pharmacy"],
      },
    ],
  },
  {
    title: "Branches",
    route: "/branches",
    icon: IoMdGitBranch,
    isNew: false,
    code: "brn",
    type: ["manufacturer", "pharmacy"],
  },
  {
    title: "Notifications",
    route: "/notifications",
    icon: IoNotifications,
    isNew: true,
    code: "ntc",
    type: ["manufacturer", "pharmacy"],
  },
  {
    title: "Settings",
    route: "/settings",
    icon: PiGearFineBold,
    isNew: false,
    code: "stn",
    type: ["manufacturer", "pharmacy"],
  },

  {
    title: "Ask Support",
    route: "/contact",
    icon: TiMessages,
    isNew: false,
    code: "ctu",
    type: ["manufacturer", "pharmacy"],
  },

  {
    title: "FAQ",
    route: "/faq",
    icon: FaQuestionCircle,
    isNew: false,
    code: "faq",
    type: ["manufacturer", "pharmacy"],
  },
];

export { sideBarMenu };
