import { Avatar, Badge } from "flowbite-react";
import { useAppSelector } from "../../lib/hook";
import { useGetNotificationsQuery } from "../../redux/queries/notifications";
import moment from "moment";
import { HiClock } from "react-icons/hi";
import utills from "../../lib/functions";
import { PiEnvelopeFill } from "react-icons/pi";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const NotificationsPage = () => {
  const location = useLocation();
  const { state } = location || {};
  const { message, subject, created_at } = state || {};
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { data: notifications } = useGetNotificationsQuery({
    company: user?.company.uuid,
    user: user?.uuid,
    // limit: '0,10'
  });

  const [activeNotifications, setActiveNotifications] = useState({ subject: "", message: "", created_at: "" });

  useEffect(() => {
    if (message) {
      setActiveNotifications({ subject, message, created_at });
    }
  }, []);
  return (
    <div>
      <h2 className="dark:text-gray-100 font-bold mb-3">Notifications</h2>
      <div className="flex gap-2">
        <div className="min-w-[350px] bg-white dark:bg-gray-800 rounded-md h-[calc(100vh-115px)] overflow-auto">
          {" "}
          {notifications?.data?.map((noti) => (
            <div
              key={crypto.randomUUID()}
              onClick={() => {
                setActiveNotifications({ subject: noti.subject, message: noti.message, created_at: noti.created_at });
              }}
              className="flex items-start gap-2 p-3 border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
            >
              <Avatar rounded />
              <div>
                <h3 className="font-semibold text-gray-900 dark:text-gray-100 mb-1">
                  {noti.subject.replace("{company_name}", noti.company?.name || "")}
                </h3>
                <span
                  className="text-xs text-gray-700 dark:text-gray-400"
                  dangerouslySetInnerHTML={{ __html: utills._truncateString(noti.message, 100) }}
                ></span>
                <div className=" text-xs font-semibold flex items-center gap-2">
                  {" "}
                  <Badge color="blue" className="w-max py-0.5 text-[11px]" icon={HiClock}>
                    {moment(noti.created_at).fromNow()}
                  </Badge>
                  {!noti.read_at && (
                    <Badge className="w-max py-0.5 text-[11px]" icon={PiEnvelopeFill}>
                      un-read
                    </Badge>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {notifications?.data && (
          <div className="flex-1 bg-white dark:bg-gray-800 rounded-md h-max">
            <div className="border-b dark:border-gray-700 p-3 text-sm dark:text-gray-100 font-semibold">
              {activeNotifications.created_at
                ? moment(activeNotifications.created_at).format("LLL")
                : moment(notifications.data[0].created_at).format("LLL")}
            </div>
            <div className="p-3">
              <h3 className="dark:text-gray-100">Subject:</h3>

              <div className="p-3 mt-2 border-2 bg-gray-100 dark:bg-gray-700 dark:text-gray-100 dark:border-gray-700 rounded-md text-gray-700">
                {activeNotifications.subject ? activeNotifications.subject : notifications.data[0].subject}
              </div>
            </div>

            <div className="p-3 mb-5">
              <h3 className="dark:text-gray-100">Message:</h3>

              <div
                dangerouslySetInnerHTML={{ __html: activeNotifications.message ? activeNotifications.message : notifications.data[0].message }}
                className="p-3 mt-2 border-2 bg-gray-100 dark:bg-gray-700 dark:text-gray-100 dark:border-gray-700 rounded-md text-gray-700"
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;
