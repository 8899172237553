import { api } from "../baseConfig";

interface payload {
  company_uuid?: string;
  limit?: string;
}

interface PurReturnResponse {
  count: number;
  data: purReturn[];
  status: number;
  total_cost: number;
  total_cost_return: number;
}

interface IStockCount {
  company_count_uuid?: string;
  company?: string;
  shelf_uuid?: string;
}

interface IStockList {
  status: number;
  count: number;
  data: {
    id: number;
    uuid: string;
    name: string;
    company: string;
    verified: number;
    created_at: string;
    updated_at: string;
  }[];
}

interface IStockCountPayload {
  company: string;
  name: string;
}
export const stockSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getStocks: builder.query<any, string>({
      query: (id) => ({
        url: `/stocks/${id}`,
        method: "GET",
      }),
      providesTags: ["Stock"],
    }),
    getPurchaseHistory: builder.query<IPurchaseHistoryResponse, any>({
      query: (data) => ({
        url: "/stocks",
        method: "GET",
        params: data,
      }),
      providesTags: ["Stock"],
    }),
    getOnlinePurchase: builder.query<IPurchaseHistoryResponse, any>({
      query: (data) => ({
        url: "/v2/orders",
        method: "GET",
        params: data,
      }),
      providesTags: ["Stock"],
    }),
    getSingleOnlinePurchase: builder.query<
      ISinglePurchaseHistoryResponse,
      string
    >({
      query: (uuid) => ({
        url: `/v2/orders/${uuid}`,
        method: "GET",
      }),
      providesTags: ["Stock"],
    }),
    createStock: builder.mutation({
      query: (data) => ({
        url: "/stocks/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stock"],
    }),
    getStockCount: builder.query<IStockCountResponse, IStockCount>({
      query: (data) => ({
        url: "/takestocks",
        method: "GET",
        params: data,
      }),
      providesTags: ["Stock"],
    }),
    markAsCompleted: builder.mutation({
      query: (data) => ({
        url: "/takestocks/stocks/mark_as_completed",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stock"],
    }),
    getStockDataList: builder.query<IStockList, any>({
      query: (payload) => ({
        url: "/takestocks/stocks",
        method: "GET",
        params: payload,
      }),
      providesTags: ["Stock"],
    }),
    createStockCount: builder.mutation<any, IStockCountPayload>({
      query: (data) => ({
        url: "/takestocks/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stock"],
    }),
    verifyOrderItem: builder.mutation({
      query: (data) => ({
        url: "/pos/order/verify",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stock", "Orders"],
    }),
    deleteStock: builder.mutation<any, string>({
      query: (uuid) => ({
        url: `/takestocks/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Stock"],
    }),
    updatePurchase: builder.mutation({
      query: ({ uuid, data }) => ({
        url: `/stocks/${uuid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Stocks", "Stock"],
    }),
    returnPurchasedItem: builder.mutation({
      query: (data) => ({
        url: "/stocks/return_item",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stock"],
    }),
    purReturn: builder.query<PurReturnResponse, payload>({
      query: (data) => ({
        url: "/stocks/return_items",
        method: "GET",
        params: data,
      }),
      providesTags: ["Stock"],
    }),
    purReturnWithOutId: builder.mutation<any, any>({
      query: (data) => ({
        url: "/stocks/return_item_purchase_without_id",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Stock"],
    }),
  }),
});
export const {
  useGetStocksQuery,
  useCreateStockMutation,
  useGetStockCountQuery,
  useMarkAsCompletedMutation,
  useGetStockDataListQuery,
  useCreateStockCountMutation,
  useDeleteStockMutation,
  useGetPurchaseHistoryQuery,
  useLazyGetPurchaseHistoryQuery,
  useVerifyOrderItemMutation,
  useUpdatePurchaseMutation,
  useReturnPurchasedItemMutation,
  usePurReturnWithOutIdMutation,
  useLazyGetOnlinePurchaseQuery,
  useGetSingleOnlinePurchaseQuery,
  usePurReturnQuery,
} = stockSlice;
