import { useState } from "react";
import { useGetProductsQuery } from "../../redux/queries/products";
import { Spinner } from "flowbite-react";
import utills from "../../lib/functions";
import ImageView from "../../components/ImageView";
import PaginationControl from "../../components/PaginationControl";

interface UsersCard {
  id: string;
}

const CustomerDrugs = (props: UsersCard) => {
  const { id } = props;
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: id,
  });
  const { data: products, isLoading } = useGetProductsQuery(filters);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center pt-5">
        <Spinner />
      </div>
    );
  }
  
  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <div className="bg-white dark:bg-gray-800 p-2">
          <span className="font-semibold text-[13px] dark:text-gray-200">
            Company Products
          </span>
        </div>
        <PaginationControl
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={products?.data}
          total={products?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-4 py-2">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Product name
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Stock
                </th>
                <th scope="col" className="px-6 py-3">
                  Price&#40;&#8358;&#41;
                </th>
              </tr>
            </thead>
            <tbody>
              {products?.data?.map((item) => {
                const imgurl =
                  item.images_links[0] ===
                  "https://dev-assets.gohealthy.ng/items/"
                    ? utills._default_img
                    : item.images_links[0];
                return (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-4">
                      <ImageView url={imgurl} className="w-6 h-6" />
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.name}
                    </th>
                    <td className="px-6 py-4">{item.quantity}</td>
                    <td className="px-6 py-4">{item.stock}</td>
                    <td className="px-6 py-4">
                      {utills._currency_format(item.selling_price, "NGN")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </PaginationControl>
      </div>
    </div>
  );
};

export default CustomerDrugs;
