import { useGetSalesReturnsQuery } from "../../redux/queries/order";
import { useAppSelector } from "../../lib/hook";
import Breadcrumb from "../../components/Breadcrumb";
import CustomPagination from "../../components/CustomPagination";
import { useState } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import utills from "../../lib/functions";
import { GrTransaction } from "react-icons/gr";
import BranchSwitcher from "../../components/BranchSwitcher";

const ReturnHistory = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    company: defaultBranchUUid,
    //verified: 1,
    limit: "0,10",
    //type: 14,
  });
  const { data: orders, isLoading } = useGetSalesReturnsQuery(filters);

  console.log("orders", orders);
  return (
    <div>
      <Breadcrumb title="Return History" />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Returns
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <GrTransaction className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._figure_format(Number(orders?.count) ?? 0)}</span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Cost
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaArrowUp className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>
              {utills._currency_format(Number(orders?.total_cost) ?? 0, "NGN")}
            </span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Price
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaArrowDown className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>
              {utills._currency_format(
                Number(orders?.total_return_cost) ?? 0,
                "NGN"
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="flex flex-wrap items-center gap-3">
          <BranchSwitcher
            sizing="sm"
            defaultCompany={filters.company}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, company: value }))
            }
          />
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={orders?.data}
          total={orders?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Customer
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantity returned
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Order no
                </th>
              </tr>
            </thead>
            <tbody>
              {orders?.data?.map((item: any) => (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">{item.buyer.name}</td>
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">{item.returned_quantity}</td>
                  <td className="px-6 py-4">
                    {utills._currency_format(item.price ?? 0, "NGN")}
                  </td>
                  <td className="px-6 py-4">{item.order_uuid}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>
    </div>
  );
};

export default ReturnHistory;
