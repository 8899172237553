import { Button, Datepicker, Label } from "flowbite-react";
import { useState } from "react";
import { useGetWalletsQuery } from "../../redux/queries/Payments";
import moment from "moment";
import PaginationControl from "../../components/PaginationControl";
import utills from "../../lib/functions";
import { FaWallet } from "react-icons/fa";

interface IWallet {
  id: string;
}

const CustomerWallet = (props: IWallet) => {
  const { id } = props;
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: id,
    //staff: id,
  });
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
  });
  const { data: walletHistory, isLoading } = useGetWalletsQuery(filters);

  const applyFilter = () => {
    setFilters((prev) => ({ ...prev, ...filterFields, limit: "0,10" }));
  };

  const reset = () => {
    setFilterFields({
      start_date: "",
      end_date: "",
    });
    setFilters({
      limit: "0,10",
      company: id,
      //staff: id,
    });
  };

  return (
    <div>
      <div className="bg-white mt-4 p-2 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-18 rounded-lg p-3 flex flex-col justify-between w-48">
          <div className="flex justify-between items-center">
            <span className="text-xs font-semibold text-gray-700 dark:text-white">
              Total Balance
            </span>
            <div className="bg-white rounded p-1 flex items-center justify-center">
              <FaWallet className="text-red-500" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-xl">
            <span>
              {utills._currency_format(
                Number(walletHistory?.total ?? 0),
                "NGN"
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="flex justify-between items-end gap-3">
          <div>
            <Label
              htmlFor="start-date"
              value="Start Date"
              className="text-sm"
            />
            <Datepicker
              value={filterFields.start_date}
              onSelectedDateChanged={(date) =>
                setFilterFields((prev) => ({
                  ...prev,
                  start_date: moment(date).format("YYYY-MM-DD"),
                }))
              }
              name="date"
              datepicker-format={"YYYY-MM-DD"}
            />
          </div>

          <div>
            <Label htmlFor="end-date" value="End Date" className="text-sm" />
            <Datepicker
              value={filterFields.end_date}
              onSelectedDateChanged={(date) =>
                setFilterFields((prev) => ({
                  ...prev,
                  end_date: moment(date).format("YYYY-MM-DD"),
                }))
              }
              name="date"
              datepicker-format={"YYYY-MM-DD"}
            />
          </div>

          <div className="flex gap-2">
            <Button size="sm" onClick={applyFilter}>
              Apply
            </Button>
            <Button outline size="sm" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <PaginationControl
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={walletHistory?.data}
            total={walletHistory?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Transaction Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Debit(₦)
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Credit(₦)
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                {walletHistory?.data?.map((item) => (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">
                      {moment(item.crdate).format("LLL")}
                    </td>
                    <td className="px-6 py-4">{item.type}</td>
                    
                    <td className="px-6 py-4">
                      {Number(item.amount) < 0
                        ? utills._figure_format(
                            Number(item.amount.replace("-", ""))
                          )
                        : "-"}
                    </td>
                    <td className="px-6 py-4">
                      {Number(item.amount) >= 0
                        ? utills._figure_format(Number(item.amount))
                        : "-"}
                    </td>
                    <td className="px-6 py-4">
                      {utills._figure_format(item.balance)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PaginationControl>
        </div>
      </div>
    </div>
  );
};

export default CustomerWallet;
