import { useAppSelector } from "../../lib/hook";
import Breadcrumb from "../../components/Breadcrumb";
import CustomPagination from "../../components/CustomPagination";
import { useState } from "react";
import { usePurReturnQuery } from "../../redux/queries/stock";
import utills from "../../lib/functions";
import ImageView from "../../components/ImageView";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import BranchSwitcher from "../../components/BranchSwitcher";

const ReturnHistory = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    company_uuid: defaultBranchUUid,
    limit: "0,10",
  });
  const { data: returns, isLoading } = usePurReturnQuery(filters);
  console.log(returns);

  return (
    <div>
      <Breadcrumb title="Purchase Return History" />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Returns
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <GrTransaction className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._figure_format(Number(returns?.count) ?? 0)}</span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Cost
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaArrowUp className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>
              {utills._currency_format(Number(returns?.total_cost) ?? 0, "NGN")}
            </span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Price
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaArrowDown className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>
              {utills._currency_format(
                Number(returns?.total_cost_return) ?? 0,
                "NGN"
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="flex flex-wrap items-center gap-3">
          <BranchSwitcher
            sizing="sm"
            defaultCompany={filters.company_uuid}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, company_uuid: value }))
            }
          />
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={returns?.data}
          total={returns?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer name
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  order id
                </th>
              </tr>
            </thead>
            <tbody>
              {returns?.data?.map((item) => (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="p-4">
                    <ImageView className="w-8 h-8" url={utills._default_img} />
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.stock.buyer ? item.stock.buyer.name : "-"}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.item.name}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.item.quantity}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {utills._figure_format(Number(item.price))}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.stock.invoice_id ?? "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>
    </div>
  );
};

export default ReturnHistory;
