import { Dispatch } from "@reduxjs/toolkit";
import { Howl } from "howler";
import toast from "react-hot-toast";
import { updateUserBranch } from "../redux/slices/appUser";

const sound = new Howl({ src: ["./beep.mp3"] });
const error = new Howl({ src: ["./error.mp3"] });

export function _currency_format(digit: number, cur: string, local?: string) {
  return new Intl.NumberFormat(local ?? "en-NG", {
    style: "currency",
    currency: cur,
  }).format(digit);
}

export function _figure_format(digit: number, local?: string) {
  return new Intl.NumberFormat(local ?? "en-NG").format(digit);
}

export function _play_sound() {
  sound.play();
}
export function _play_error_sound() {
  error.play();
}

export function _total_item_in_cart(cartItems: Cart[]): Totals {
  const result = cartItems.reduce(
    (acc, cartItem) => {
      acc.totalQuantity += cartItem.quantity;
      acc.totalPriceInNaira += cartItem.quantity * cartItem.price_in_naira;
      acc.totalPriceInDollar += cartItem.quantity * cartItem.price_in_dollar;

      return acc;
    },
    { totalQuantity: 0, totalPriceInNaira: 0, totalPriceInDollar: 0 }
  );

  return result;
}

export function _total_item_price(cartItems: EdittingItem[]): Totals {
  const result = cartItems.reduce(
    (acc, cartItem) => {
      acc.totalQuantity += cartItem.quantity;
      acc.totalPriceInNaira += cartItem.quantity * cartItem.price;
      acc.totalPriceInDollar += cartItem.quantity * cartItem.price;

      return acc;
    },
    { totalQuantity: 0, totalPriceInNaira: 0, totalPriceInDollar: 0 }
  );

  return result;
}

export function _invoice_total__price(cartItems?: CompanyOrderItem[]): {
  totalPriceInNaira: number;
} {
  if (!cartItems) return { totalPriceInNaira: 0 };
  const result = cartItems.reduce(
    (acc, cartItem) => {
      acc.totalPriceInNaira += Number(cartItem.price) * cartItem.quantity;

      return acc;
    },
    { totalPriceInNaira: 0 }
  );

  return result;
}

export function _generate_barcode() {
  let barcode = "";
  for (let i = 0; i < 10; i++) {
    barcode += Math.floor(Math.random() * 10); // Generate a random digit (0-9)
  }
  return barcode;
}

export function _fallback_img(
  event?: React.SyntheticEvent<HTMLImageElement, Event> | Event
) {
  if (event) {
    const target = (event as React.SyntheticEvent<HTMLImageElement, Event>)
      .target as HTMLImageElement;
    target.src = "https://dev-assets.gohealthy.ng/users/default.jpg";
  }
}

export const _default_img = "https://dev-assets.gohealthy.ng/users/default.jpg";

export function _build_result_el(data: ProductInterface) {
  const wrapperDiv = document.createElement("div");
  const containerDiv = document.createElement("div");
  const img = document.createElement("img");
  const itemNameDiv = document.createElement("div");
  const detailsDiv = document.createElement("div");
  const barcodeSpan = document.createElement("span");
  const priceSpan = document.createElement("span");

  wrapperDiv.classList.add(
    "flex",
    "p-2",
    "items-center",
    "gap-3",
    "w-full",
    "hover:bg-gray-300",
    "dark:hover:bg-gray-700",
    "cursor-pointer"
  );
  containerDiv.classList.add("dark:text-white", "text-xs", "w-full", "px-2");
  itemNameDiv.classList.add(
    "font-semibold",
    "text-[13px]",
    "mb-[3px]",
    "flex",
    "justify-between",
    "items-center"
  );
  detailsDiv.classList.add("flex", "justify-between");

  img.src = "https://dev-assets.gohealthy.ng/users/default.jpg";
  img.className = "w-8 h-8";
  img.alt = "sample";
  img.onerror = (e: Event | string) => {
    if (typeof e !== "string") {
      _fallback_img(e);
    }
  };
  itemNameDiv.textContent = data.name;
  barcodeSpan.textContent = `Barcode: ${data.barcode}`;
  priceSpan.textContent = _currency_format(data.price_in_naira, "NGN");

  detailsDiv.appendChild(barcodeSpan);
  detailsDiv.appendChild(priceSpan);
  containerDiv.appendChild(itemNameDiv);
  containerDiv.appendChild(detailsDiv);
  wrapperDiv.appendChild(img);
  wrapperDiv.appendChild(containerDiv);
  return wrapperDiv;
}

export function _get_status_value(status: number, statuses: OrderStatus[]) {
  const st = statuses.find((item) => item.id === status);
  return st?.name ?? "Null";
}

type AsyncFunction<T> = (payload?: any) => Promise<T>;
export function _asynchronous_toast<T>(
  func: AsyncFunction<T>,
  successText: string,
  errorMsg: string,
  payload?: any
) {
  let result = false;
  toast.promise(
    new Promise(async (resolve, reject) => {
      try {
        const res = await func(payload);
        //@ts-ignore
        if (res.error) reject(res);
        else {
          resolve(res);
          result = true;
        }
      } catch (error) {
        reject(error);
      }
    }),
    {
      loading: "Processing please wait...",
      success: () => successText,
      error: () => errorMsg,
    },
    {
      style: {
        // minWidth: "250px",
      },
      success: {
        duration: 5000,
        icon: "🔥",
      },
    }
  );
  return result;
}

export function _remove_invalid_link(data: any) {
  const invalidUrls = ["https://dev-assets.gohealthy.ng/items/"];
  return data.filter((item: any) => !invalidUrls.includes(item));
}

interface Variants {
  id: number;
  name: string;
  quantity: number;
  is_required: number;
  price: number;
}

export function _display_variant(variants: Variants[]) {
  let requiredVariants = [];
  for (const item of variants) {
    if (item.is_required === 1) requiredVariants.push(item.is_required);
  }
  return requiredVariants.length > 1;
}

// export function _get_product_variants(variants: Variants[]) {
//   let requiredVariants = [];
//   for (const item of variants) {
//     if (item.is_required === 1) requiredVariants.push(item);
//   }
//   return requiredVariants.length > 1
// }

export async function _update_user_branch<T>(
  userId: string,
  func: AsyncFunction<T>,
  dispatch: Dispatch
) {
  const res = await func(userId);
  //@ts-ignore
  if ("data" in res) {
    //@ts-ignore
    dispatch(updateUserBranch(res.data.data));
  }
}

export const _full_screen = () => {
  const docElement = document.documentElement;

  // Check if the document is currently in fullscreen mode
  if (document.fullscreenElement) {
    // IE/Edge

    // If in fullscreen, exit fullscreen mode
    if (document.exitFullscreen) {
      document.exitFullscreen(); // Exit fullscreen
    }
  } else {
    // If not in fullscreen, enter fullscreen mode
    if (docElement.requestFullscreen) {
      docElement.requestFullscreen(); // Request fullscreen
    }
  }
};

export function _get_default_company(
  user: AppUser,
  branches?: UserCompaniesRecord[]
) {
  if (!branches) return;
  if (branches.length === 0) return;
  const mainBranch = branches.find(
    (item) => item.company.type === user.company.type
  );
  if (mainBranch) return mainBranch.company.uuid;
  else return branches[0].company.uuid;
}

export function checkPermmission(permission: string, user_permission?: string,) {
  const perms = user_permission?.split(",") ?? []
  if (perms.includes(permission.split("-")[0])) return true
  return false

}

export function checkSubLinkAccess(permission: string, user_permission?: string,) {
  const perms = user_permission?.split(",") ?? []
  if (perms.includes(permission)) return true
  return false

}

export function _truncateString(str: string, charLimit: number): string {
  if (str.length > charLimit) return str.slice(0, charLimit) + "...";
  return str;
}
