import { memo, useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Banner,
  Button,
  Label,
  Pagination,
  Spinner,
  TextInput,
} from "flowbite-react";
import { IoIosAdd } from "react-icons/io";
import { BsBookshelf } from "react-icons/bs";
import Modal from "react-responsive-modal";
import { useAppSelector } from "../../lib/hook";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { LiaTimesSolid } from "react-icons/lia";
import BranchSwitcher from "../../components/BranchSwitcher";
import {
  useCreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetDepartmentsQuery,
  useUpdateDepartmentMutation,
} from "../../redux/queries/departments";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import { MdOutlineLockReset } from "react-icons/md";
import emptyIcon from "../../assets/empty.png";
import utills from "../../lib/functions";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore } from "react-icons/ci";
import { IoTrashOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { useGetUsersQuery } from "../../redux/queries/users";
import moment from "moment";

const Departments = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [isOpen, setIsOpen] = useState(false);
  const [activeId, setActiveId] = useState<number>(0);
  const [selectedDepartment, setSelectedDepartment] = useState<number | null>(
    null
  );
  const [filters, setFilters] = useState({
    limit: "0,10",
    company_uuid: defaultBranchUUid,
  });

  const [filterFields, setFilterFields] = useState({
    name: "",
  });

  const { data: departments, isLoading } = useGetDepartmentsQuery(filters);

  const editShelf = (id: number) => {
    setSelectedDepartment(id);
    setIsOpen(true);
  };
  const handleSearch = () => {
    setFilters((prev) => ({
      ...prev,
      name: filterFields.name,
    }));
  };

  useEffect(() => {
    if (!departments) return;
    if (departments.data.length > 0) {
      const [firstDepartment] = departments.data;
      setActiveId(firstDepartment.id);
    }
  }, [isLoading]);

  return (
    <div>
      <Breadcrumb
        title="Departments List"
        rightButton={
          <Button size="xs" onClick={() => setIsOpen(true)}>
            <IoIosAdd className="mr-2 h-5 w-5" /> Create Department
          </Button>
        }
      />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Employees Departments.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Manage departments and see all data of each department.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <BsBookshelf className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="mt-5 p-3 bg-white dark:bg-gray-800 rounded-sm mb-5 flex justify-between items-center">
        <div className="grid grid-cols-4 gap-3">
          
          <div>
            <TextInput
              value={filterFields.name}
              sizing="sm"
              type="text"
              placeholder="Search by Department Name"
              onChange={(e) => {
                setFilterFields((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
          </div>
        </div>

        <div>
          <button
            onClick={handleSearch}
            type="button"
            className="p-2.5 ms-2 text-sm font-medium text-white bg-[#167490] rounded-lg border border-[#167490] hover:bg-[#167490]/80 focus:ring-4 focus:outline-none"
          >
            <FaSearch />
          </button>
          <button
            onClick={() => {
              setFilterFields({
                name: "",
              });
              setFilters({
                limit: "0,10",
                company_uuid: defaultBranchUUid,
              });
            }}
            type="button"
            className="p-2.5 ms-2 text-sm font-medium text-[#167490] bg-transparent rounded-lg border border-[#167490] hover:bg-[#167490]/80 hover:text-white"
          >
            <MdOutlineLockReset />
          </button>
        </div>
      </div>

      {departments?.data.length === 0 && (
        <div className="flex items-center justify-center my-5">
          <div className="flex flex-col items-center">
            <img src={emptyIcon} alt="empty record" className="w-20" />
            <span className="dark:text-gray-400 mt-2 text-xs">No record</span>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center items-center mt-5 gap-2">
          <Spinner />
          <Spinner />
          <Spinner />
        </div>
      )}

      <div className="flex gap-5">
        <div className="flex flex-col gap-3">
          {departments?.data.map((department) => (
            <DepartmentCard
              key={department.id}
              department={department}
              activeId={activeId}
              clickDepartment={(x) => setActiveId(x)}
              edit={editShelf}
            />
          ))}
        </div>
        <div className="flex-1">
          <DepartmentDetails
            department={
              activeId > 0
                ? departments?.data.find((x) => x.id === activeId)
                : undefined
            }
          />
        </div>
      </div>

      <Modal
        blockScroll={false}
        open={isOpen}
        showCloseIcon={false}
        onClose={() => {
          setIsOpen(false);
          setSelectedDepartment(null);
        }}
        center
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
      >
        <DepartmentForm
          close={() => {
            setIsOpen(false);
            setSelectedDepartment(null);
          }}
          item={
            selectedDepartment
              ? departments?.data.find((x) => x.id === selectedDepartment)
              : undefined
          }
        />
      </Modal>
    </div>
  );
};

export default Departments;

interface IDepartmentDetails {
  department?: Singledepartment;
}
const DepartmentDetails = memo((props: IDepartmentDetails) => {
  const { department } = props;
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company.uuid,
    department_uuid: "",
  });

  const { data: users, isSuccess, isLoading } = useGetUsersQuery(filters);

  useEffect(() => {
    if (department?.uuid) {
      setFilters((prev) => ({ ...prev, department_uuid: department?.uuid }));
    }
  }, [department]);

  if (!department) {
    return null;
  }

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    setFilters((prev) => ({ ...prev, limit: `${(page - 1) * 10},10` }));
  };

  return (
    <div className="w-full sticky top-16">
      <div className="bg-[#167490] text-white flex items-center justify-between h-10 px-3">
        <span>#{department.id}</span>
        <span className="capitalize">{department.name}</span>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Employee Name
              </th>
              <th scope="col" className="px-6 py-3">
                Gender
              </th>
              <th scope="col" className="px-6 py-3">
                Last Active
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.data?.map((item: any) => (
              <tr
                key={item.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td>
                  <img
                    className="w-10"
                    src={item.avatar_link}
                    alt={item.name}
                  />
                </td>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.name}
                </th>
                <td
                  className="px-6 py-4"
                  style={{ textTransform: "uppercase" }}
                >
                  {item.gender || "-"}
                </td>
                <td className="px-6 py-4">
                  {moment(item.lastactive).format("LLL")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && (
          <div className="flex items-center justify-center my-5">
            <Spinner aria-label="Default status example" />
          </div>
        )}
        {isSuccess && (
          <div className="flex overflow-x-auto sm:justify-end mt-3 mb-5 mr-2">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(users?.count ?? 0 / 10)}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
});

interface IDepartmentCard {
  department: Singledepartment;
  activeId: number;
  clickDepartment: (id: number) => void;
  edit: (id: number) => void;
}
const DepartmentCard = (props: IDepartmentCard) => {
  const { department, activeId, clickDepartment, edit } = props;
  const [deleteDepartment] = useDeleteDepartmentMutation();

  const deleteHandler = (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action)
      utills._asynchronous_toast(
        deleteDepartment,
        "Record Deleted",
        "Unable to delete",
        id
      );
  };
  return (
    <div
      onClick={() => clickDepartment(department.id)}
      className="border rounded-sm border-gray-400 dark:border-gray-700 bg-white dark:bg-gray-800 w-[300px] cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-900"
    >
      <div
        className={`flex items-center justify-between gap-3 p-2 ${
          activeId === department.id
            ? "bg-[#167490] text-white"
            : "bg-transparent text-[#167490]"
        } rounded-t-sm`}
      >
        <div className="flex flex-1 items-center gap-3">
          <BsBookshelf />{" "}
          <span className="text-[#12px] w-[200px] font-semibold truncate capitalize ...">
            {department.name}
          </span>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <CustomDropdown
            trigger={
              <div className="">
                <CiCircleMore className="text-2xl" />
              </div>
            }
            menu={[
              {
                icon: FaRegEdit,
                title: "Edit",
                action: () => edit(department.id),
              },
              {
                icon: IoTrashOutline,
                title: "Delete",
                action: () => deleteHandler(department.uuid),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

interface IFormInput {
  name: string;
}

interface IDepartmentForm {
  close: () => void;
  item?: Singledepartment;
}

const schema = yup.object().shape({
  name: yup.string().required("Shelf name is required"),
});

const DepartmentForm = (props: IDepartmentForm) => {
  const { close, item } = props;
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [companyId, setCompanyId] = useState(defaultBranchUUid);

  const [createDepartment, { isLoading: isCreating }] =
    useCreateDepartmentMutation();
  const [updateDepartment, { isLoading: isUpdating }] =
    useUpdateDepartmentMutation();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const loading = item ? isUpdating : isCreating;

  const onSubmit = async (data: IFormInput) => {
    const payload = { ...data, company_uuid: companyId };
    if (item) {
      const updatePayload = { ...payload, uuid: item.uuid };
      const res = await updateDepartment(updatePayload);
      console.log(res);
      if ("data" in res) {
        if (res?.data?.status === 200) {
          toast.success("Department Updated Successfully");
          close();
        } else toast.error("Unable to update record");
      } else toast.error("Unable to update record");
    } else {
      const res = await createDepartment(payload);
      if ("data" in res) {
        if (res?.data?.status === 200) {
          toast.success("Department Created Successfully");
          close();
        } else toast.error("Unable to create record");
      } else toast.error("Unable to create record");
    }
  };

  useEffect(() => {
    if (item) {
      setValue("name", item.name);
      setCompanyId(item.company_uuid);
    }
  }, [item, setValue]);

  return (
    <form
      className="bg-white dark:bg-gray-800 w-[400px] rounded-lg"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="bg-[#167490] rounded-t-lg flex items-center px-3 justify-between h-10">
        <span className="text-white font-semibold text-sm">
          {item ? "Edit Shelf" : "Create Shelf"}
        </span>
        <button type="button" className="text-white" onClick={close}>
          <LiaTimesSolid />
        </button>
      </div>

      <div className="p-5 px-10">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="company" value="Select company or branch" />
          </div>
          <BranchSwitcher
            onChange={(val) => setCompanyId(val)}
            defaultCompany={companyId}
          />
        </div>

        <div className="mt-2">
          <div className="mb-2 block">
            <Label htmlFor="name" value="Department name" />
          </div>
          <TextInput
            sizing="sm"
            id="name"
            className="hide-spin-buttons"
            type="text"
            disabled={loading}
            autoComplete="off"
            {...register("name")}
            color={errors?.name ? "failure" : "gray"}
            helperText={
              errors.name && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.name?.message}!
                </span>
              )
            }
          />
        </div>

        <div className="mt-8">
          <Button
            isProcessing={loading}
            disabled={loading}
            type="submit"
            size="xs"
            className="w-full rounded-md"
          >
            {item ? "Update" : "Submit"}
          </Button>
        </div>
      </div>
    </form>
  );
};
