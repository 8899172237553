import Breadcrumb from "../../components/Breadcrumb";
import { Badge, Banner, Button, Label } from "flowbite-react";
import { LuHistory } from "react-icons/lu";
import { useGetStockListQuery } from "../../redux/queries/order";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import { useGetSettingsQuery } from "../../redux/queries/settings";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore, CiViewList } from "react-icons/ci";
import { FaArrowDown, FaArrowUp, FaRegEdit } from "react-icons/fa";
import { useEffect, useState } from "react";
import ViewItems from "./ViewItems";
import { useNavigate } from "react-router-dom";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppSelector } from "../../lib/hook";
import CustomPagination from "../../components/CustomPagination";
import { MdPedalBike } from "react-icons/md";
import AssignRider from "./AssignRider";
import CustomDatePicker from "../../components/CustomDatePicker";
import moment from "moment";
import { GrTransaction } from "react-icons/gr";
import { PiExam } from "react-icons/pi";
import { TfiPackage } from "react-icons/tfi";

const StockTransferHistory = () => {
  const navigate = useNavigate();
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: defaultBranchUUid,
    buyer: "",
    start_date: "",
    end_date: "",
  });
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
  });
  const [isOpen, setIsOpen] = useState("");
  const [orderUuid, setOrderUuid] = useState("");
  const { data: stockHistory, isLoading } = useGetStockListQuery({
    ...filters,
  });
  const { data: settings } = useGetSettingsQuery();
  const order_status = settings?.data.order_status ?? [];

  const handleClose = () => {
    setIsOpen("");
    setOrderUuid("");
  };

  useEffect(() => {
    if (stockHistory?.data.length) {
      setFilterFields((prev) => ({
        ...prev,
        start_date: stockHistory?.data[stockHistory?.data.length - 1]
          ?.created_at.length
          ? moment(
              stockHistory?.data[stockHistory?.data.length - 1]?.created_at
            ).format("YYYY-MM-DD HH:mm:ss")
          : "",
        end_date: stockHistory?.data[0]?.created_at.length
          ? moment(stockHistory?.data[0]?.created_at).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
      }));
    }
  }, [stockHistory?.data]);

  const reset = () => {
    setFilters({
      limit: "0,10",
      company: defaultBranchUUid,
      start_date: "",
      end_date: "",
      buyer: "",
    });
  };

  return (
    <div>
      <Breadcrumb title="Stock Transfer History" />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Stock Transfer History.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Stock transfer history records.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <LuHistory className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Transactions
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <GrTransaction className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._figure_format(Number(470000) ?? 0)}</span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Transfer
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaArrowUp className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._currency_format(Number(4700000) ?? 0, "NGN")}</span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Received
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaArrowDown className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._currency_format(Number(4700000) ?? 0, "NGN")}</span>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4 p-4 bg-white rounded-md mb-4 dark:bg-gray-700">
        <button
          onClick={() =>
            setFilters((prev) => ({
              ...prev,
              company: defaultBranchUUid,
              buyer: "",
            }))
          }
          className={`${"rounded-lg border-2 h-10 w-32 text-sm font-semibold border-[#167490]"} ${
            filters.company
              ? "bg-[#167490] text-white"
              : "text-[#167490] hover:bg-[#167490]/10"
          }`}
        >
          Outgoing
        </button>
        <button
          onClick={() =>
            setFilters((prev) => ({
              ...prev,
              buyer: defaultBranchUUid,
              company: "",
            }))
          }
          className={`${"rounded-lg border-2 h-10 w-32 text-sm font-semibold border-[#167490]"} ${
            filters.buyer
              ? "bg-[#167490] text-white"
              : "text-[#167490] hover:bg-[#167490]/10"
          }`}
        >
          Incoming
        </button>
      </div>

      <div className="flex justify-between items-center flex-wrap gap-3 bg-white px-3 py-5 rounded-sm dark:bg-gray-800">
        <div className="flex items-center gap-3">
          <div>
            <BranchSwitcher
              sizing="sm"
              defaultCompany={filters.company}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company: value }))
              }
            />
          </div>

          <CustomDatePicker
            start={
              filters.start_date.length
                ? filters.start_date
                : filterFields.start_date
            }
            onStartChange={(date) =>
              setFilters((prev) => ({
                ...prev,
                start_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              }))
            }
            end={
              filters.end_date.length ? filters.end_date : filterFields.end_date
            }
            onEndChange={(date) =>
              setFilters((prev) => ({
                ...prev,
                end_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              }))
            }
          />
        </div>

        <div className="flex gap-2">
          <Button outline size="xs" onClick={reset}>
            Reset
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={stockHistory?.data}
          total={stockHistory?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-9">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Cost Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Items
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Rider
                </th>
                <th scope="col" className="px-6 py-3">
                  Supplier
                </th>
                <th scope="col" className="px-6 py-3">
                  Recipient
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {stockHistory?.data.map((stock) => (
                <tr
                  key={stock.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    onClick={(e) => e.stopPropagation()}
                    className="px-6 py-4"
                  >
                    <ImageView url={utills._default_img} className="w-8 h-8" />
                  </td>
                  <td className="px-6 py-4">#{stock.id}</td>
                  <td className="px-6 py-4">
                    {utills._currency_format(stock.total_price ?? 0, "NGN")}
                  </td>
                  <td className="px-6 py-4">{stock.items}</td>
                  <td className="px-6 py-4">
                    <Badge className="w-max" color="indigo">
                      {utills._get_status_value(stock.status, order_status)}
                    </Badge>
                  </td>
                  <td className="px-6 py-4">
                    {stock.rider ? stock.rider.name : "-"}
                  </td>
                  <td className="px-6 py-4">{stock.company.name}</td>
                  <td className="px-6 py-4">{stock.buyer_details?.name}</td>
                  <td className="px-6 py-4">
                    <CustomDropdown
                      trigger={
                        <div className="">
                          <CiCircleMore className="text-2xl" />
                        </div>
                      }
                      menu={[
                        {
                          icon: FaRegEdit,
                          title: "Edit",
                          action: () => {
                            navigate(`/stock-history/${stock.uuid}`);
                          },
                        },
                        {
                          icon: MdPedalBike,
                          title: "Assign Rider",
                          action: () => {
                            setOrderUuid(stock.uuid);
                            setIsOpen("RIDER");
                          },
                        },
                        {
                          icon: CiViewList,
                          title: "View Items",
                          action: () => {
                            setOrderUuid(stock.uuid);
                            setIsOpen("VIEW");
                          },
                        },
                        {
                          icon: PiExam,
                          title: "Mark as Vetted",
                          action: () => {},
                        },
                        {
                          icon: TfiPackage,
                          title: "Mark as Packed",
                          action: () => {},
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>
      <ViewItems
        isOpen={isOpen === "VIEW"}
        close={handleClose}
        orderUuid={orderUuid}
      />

      <AssignRider
        isOpen={isOpen === "RIDER"}
        close={handleClose}
        orderUuid={orderUuid}
      />
    </div>
  );
};

export default StockTransferHistory;
