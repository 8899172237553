import { Banner, Button, Checkbox } from "flowbite-react";
import { useAppSelector } from "../../lib/hook";
import {
  useGetWithdrawalConQuery,
  useWithdrawConMutation,
} from "../../redux/queries/company";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiMoneyWithdraw } from "react-icons/bi";

const WithdrawalConfirmation = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { data } = useGetWithdrawalConQuery({
    company: defaultBranchUUid,
  });

  const [queries, setQueries] = useState([
    { value: 0, label: "Enable Loan Withdrawal Confirmation" },
    {
      value: 0,
      label: "Enable Wallet Withdrawal Confirmation",
    },
  ]);

  useEffect(() => {
    if (data) {
      setQueries([
        {
          value: typeof data?.loan === "number" ? data.loan : 0,
          label: "Enable Loan Withdrawal Confirmation",
        },
        {
          value: typeof data?.wallet === "number" ? data.wallet : 0,
          label: "Enable Wallet Withdrawal Confirmation",
        },
      ]);
    }
  }, [data]);

  //console.log(data, queries);
  const [withdrawCon, { isLoading: isSubmitting }] = useWithdrawConMutation();

  const handleSubmit = async () => {
    const res = await withdrawCon({
      company: defaultBranchUUid,
      loan: queries[0].value,
      wallet: queries[1].value,
    });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
      } else {
        toast.error("Failed to Update");
      }
    } else toast.error("Failed to Update");
  };

  return (
    <div className="mt-10">
      <Banner className="mb-5">
        <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
          <div className="mb-4 md:mb-0 md:mr-4">
            <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
              Withdrawal Confirmation
            </h2>
          </div>
          <div className="flex flex-shrink-0 items-center">
            <BiMoneyWithdraw className="text-5xl text-gray-500 dark:text-white" />
          </div>
        </div>
      </Banner>

      <div className="p-4 flex flex-col items-start gap-3">
        {queries?.map((item) => (
          <div className="flex gap-2 items-center">
            <Checkbox
              id={item.label}
              checked={item.value === 1}
              onChange={(e) => {
                const newQueries = queries?.map((query) =>
                  query.label === item.label
                    ? { ...query, value: e.target.checked ? 1 : 0 }
                    : query
                );
                setQueries(newQueries);
              }}
            />
            <label htmlFor={item.label} className="capitalize">
              {item.label}
            </label>
          </div>
        ))}
      </div>

      <div className="mt-10 flex items-center justify-end mr-10">
        <Button
          disabled={isSubmitting}
          isProcessing={isSubmitting}
          size="xs"
          className="rounded-sm"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default WithdrawalConfirmation;
