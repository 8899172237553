import { useEffect, useState } from "react";
import PaginationControl from "../../components/PaginationControl";
import {
  useClearLoanFnMutation,
  useLoanRepaymentQuery,
} from "../../redux/queries/Payments";
import utills from "../../lib/functions";
import { Button, Label, Select, Textarea, TextInput } from "flowbite-react";
import moment from "moment";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { useGetUserCompaniesQuery } from "../../redux/queries/company";
import { useAppSelector } from "../../lib/hook";
import { useGetUsersQuery } from "../../redux/queries/users";
import CustomSelect from "../../components/CustomSelect";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";

interface CustomerLoanProps {
  id: string;
  type: string;
  loaner: null | string;
}

const CustomLoan = ({ loaner, id, type }: CustomerLoanProps) => {
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: id,
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (loaner) {
      setFilters((prev) => ({
        ...prev,
        company: loaner,
      }));
    }
  }, [loaner]);

  const { data: loans, isLoading } = useLoanRepaymentQuery(filters);

  return (
    <div>
      <div className="bg-white mt-4 p-2 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-start gap-5 justify-between">
        <div className="bg-gray-100 dark:bg-gray-800 h-18 rounded-lg p-3 flex flex-col justify-between w-48">
          <div className="flex justify-between items-center">
            <span className="text-xs font-semibold text-gray-700 dark:text-white">
              Loan Used
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-xl">
            <span>
              {utills._currency_format(Number(loans?.loan_used ?? 0), "NGN")}
            </span>
          </div>
        </div>

        {type !== "manufacturer" && (
          <Button size="xs" outline onClick={() => setIsOpen(!isOpen)}>
            Clear Loan
          </Button>
        )}
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <PaginationControl
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={loans?.data}
          total={loans?.count ?? 0}
        >
          <table className="w-full text-[10px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Expected Repayment(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount Repaid(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Default Amount(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {loans?.data?.map((item, i) => (
                <tr
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="col" className="px-6 py-3">
                    {i + 1}
                  </th>
                  <td className="px-6 py-4">
                    {utills._figure_format(Number(item.expected))}
                  </td>
                  <td className="px-6 py-4">
                    {utills._figure_format(Number(item.amount))}
                  </td>
                  <td className="px-6 py-4">
                    {utills._figure_format(
                      Number(item.expected) - Number(item.amount)
                    )}
                  </td>
                  <td className="px-6 py-4">
                    {moment(item.crdate).format("LLL")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginationControl>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ClearLoan
          close={() => setIsOpen(false)}
          loaner={loaner}
          id={id}
          type={type}
        />
      </Modal>
    </div>
  );
};

export default CustomLoan;

interface ClearProps {
  close: () => void;
  id: string;
  loaner: string | null;
  type: string;
}

const ClearLoan = ({ close, id, type, loaner }: ClearProps) => {
  const [data, setData] = useState({
    amount: "",
    description: "",
  });

  const [ClearLoanFn, { isLoading: isClearing }] = useClearLoanFnMutation();

  const empty = isEmpty(data.amount) || isEmpty(data.description);

  const onSubmit = async () => {
    const payload = {
      ...data,
      loaner: loaner,
      user: id,
      type: "clear_loan",
      company: id,
    };

    const res = await ClearLoanFn(payload);
    //console.log(res, payload);
    if ("data" in res) {
      const { data } = res;
      if (data.status === 200) {
        toast.success("Record Updated!");
        close();
      } else {
        toast.error(data?.data ? data?.data[0] : data.message);
      }
    } else {
      toast.error("Failed to perform action!!");
    }
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Clear Loan</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <div className="bg-white dark:bg-gray-700 p-3 pb-5">
        <div className="mt-3">
          <Label value="Amount" />
          <TextInput
            sizing="sm"
            type="number"
            onChange={(e) =>
              setData((prev) => ({ ...prev, amount: e.target.value }))
            }
          />
        </div>
        <div className="mt-3">
          <Label value="Description" />
          <Textarea
            id="description"
            placeholder="Put down a description..."
            rows={5}
            onChange={(e) =>
              setData((prev) => ({ ...prev, description: e.target.value }))
            }
          />
        </div>

        <div className="mt-3 flex items-center justify-end">
          <Button
            disabled={isClearing || empty}
            isProcessing={isClearing}
            size="xs"
            className="rounded-sm"
            onClick={onSubmit}
          >
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
};
