import { api } from "../baseConfig";

interface Filters {
  parent_uuid?: string;
  type?: string;
  limit?: string;
  name?: string;
  status?: number;
}

interface Address {
  lat: string;
  lng: string;
  name: boolean;
}

interface footerPayload {
  footer?: string;
  company_uuid: string;
}

interface BranchLoad {
  name: string;
  phone: string | number;
  city_id?: string | number;
  email: string;
  markup?: number;
  status?: string;
  address?: {
    name: string;
    lat: number;
    lng: number;
  };
  parent_uuid?: string;
  type: string;
  uuid?: string;
}
interface UserBranch {
  user?: string;
  company?: string;
}

interface SingleBranches {
  status: number;
  message: string;
  data: Branch;
}

export const companySlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<Branches, Filters>({
      query: (filters) => ({
        url: "/companies",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Companies"],
    }),
    getCompany: builder.query<SingleBranches, string>({
      query: (id) => ({
        url: `/companies/${id}`,
        method: "GET",
      }),
      providesTags: ["Companies"],
    }),
    getUserCompanies: builder.query<UserCompaniesApiResponse, UserBranch>({
      query: (data) => ({
        url: `/companies/branches`,
        method: "GET",
        params: data,
      }),
      providesTags: ["Companies"],
    }),
    createCompany: builder.mutation<CompanyResponse, BranchLoad>({
      query: (data) => ({
        url: "/companies",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),
    deleteCompany: builder.mutation<any, string>({
      query: (id) => ({
        url: `/companies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Companies"],
    }),
    updateCompany: builder.mutation<CompanyResponse, any>({
      query: (data) => ({
        url: `/companies/${data.uuid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),
    addUserToCompany: builder.mutation({
      query: (data) => ({
        url: "/companies/assign_user_to_branch",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),
    maintainPrice: builder.mutation<any, any>({
      query: (data) => ({
        url: "/companies/price/maintainance",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),
    withdrawCon: builder.mutation<any, any>({
      query: (data) => ({
        url: "/companies/payment/rule",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),
    getWithdrawalCon: builder.query<any, any>({
      query: (data) => ({
        url: `/companies/payment/rule`,
        method: "GET",
        params: data,
      }),
      providesTags: ["Companies"],
    }),
    removeUserFromCompany: builder.mutation({
      query: (data) => ({
        url: "/companies/remove_user_from_branch",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),
    footerNote: builder.mutation<any, footerPayload>({
      query: (data) => ({
        url: "/companies/settings",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),
    getFooterNote: builder.query<any, footerPayload>({
      query: (data) => ({
        url: `/companies/settings`,
        method: "GET",
        params: data,
      }),
      providesTags: ["Companies"],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
  useAddUserToCompanyMutation,
  useGetCompanyQuery,
  useGetUserCompaniesQuery,
  useMaintainPriceMutation,
  useWithdrawConMutation,
  useGetWithdrawalConQuery,
  useRemoveUserFromCompanyMutation,
  useFooterNoteMutation,
  useGetFooterNoteQuery,
} = companySlice;
