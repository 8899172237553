import { useEffect, useRef, useState } from "react";
import { useLazyGetOrderQuery } from "../../redux/queries/order";
import Modal from "react-responsive-modal";
import { FaTimes } from "react-icons/fa";
import { Button } from "flowbite-react";
import { IoPrint } from "react-icons/io5";
import { useReactToPrint } from "react-to-print";
import utills from "../../lib/functions";
import { useGetUserQuery, useLazyGetUserQuery } from "../../redux/queries/users";
import { useAppSelector } from "../../lib/hook";
import moment from "moment";

interface IPrintModal {
  isOpen: boolean;
  close: () => void;
  order?: CompanyOrder;
  orderId?: string;
}

const InvoicePos = (props: IPrintModal) => {
  const { close, isOpen, orderId } = props;
  const [getOrder, { data: order, isLoading }] = useLazyGetOrderQuery();

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId, getOrder]);

  if (!order?.data) return null;

  return (
    <Modal
      open={isOpen}
      onClose={close}
      classNames={{
        modalContainer: "__remove_modal_bg",
      }}
      showCloseIcon={false}
    >
      <div className="bg-white max-w-[302px]">
        <div className="flex justify-between items-center">
          <div></div>
          <button onClick={close}>
            <FaTimes />
          </button>
        </div>
        {!orderId ? (
          <div className="bg-red-200 border border-red-600 p-2 rounded-md">
            <span className="text-red-500">Can not find id</span>
          </div>
        ) : (
          <PrintPages order={order?.data} items={order?.data?.items} />
        )}
      </div>
    </Modal>
  );
};

export default InvoicePos;

const PrintPages = ({ order, items }: { order?: CompanyOrder; items?: CompanyOrderItem[] }) => {
  const elRef = useRef(null);
  const [getUserRecord] = useLazyGetUserQuery();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { data: userData } = useGetUserQuery(user?.uuid as string);
  const [buyer, setBuyer] = useState<User | undefined>();

  const handlePrint = useReactToPrint({
    content: () => elRef.current,
  });

  const buyerRecord = async () => {
    const res = await getUserRecord(order?.buyer_details.uuid ?? "");
    if (res.data?.data) {
      setBuyer(res.data.data);
    }
  };

  const totalPrice = utills._invoice_total__price(items);

  useEffect(() => {
    buyerRecord();
  }, []);

  return (
    <>
      <Button className="" size="xs" onClick={(e: unknown) => handlePrint(e)}>
        <IoPrint className="mr-2 h-4 w-4" />
        Print
      </Button>

      <div className="p-2" ref={elRef}>
        <div className="text-center border-b border-black pb-2">
          <div className="text-[16px] font-bold">{userData?.data.company.name}</div>
          <div className="text-[10px]">{userData?.data.company.address.name}</div>
          <div className="text-[10px]">
            <span>Tel: {userData?.data.company.phone}</span> - <span>Whatsapp: {userData?.data.company.whatsapp}</span> -{" "}
            <span>Email: {userData?.data.company.email}</span>{" "}
          </div>
        </div>

        <div className=" mt-0">
          <div className="text-[10px]">
            <div>To: {buyer?.company.name}</div>
            <div>Email: {buyer?.company.email}</div>
            <div>Address: {buyer?.company.address2 ?? buyer?.company.address.name}</div>
          </div>

          <div className="text-[10px] border-t border-black pt-2">
            <div>{order?.order.type === 11 ? "INVOICE" : order?.order.type === 10 ? "QUOTATION" : "RECEIPT"} NO::  #{order?.id}</div>
            <div>Invoice ID: {order?.invoice_id}</div>
            <div>Branch: {order?.company.name}</div>
            <div>SERVED BY:: {order?.staff.name}</div>
            <div>Date: {moment(order?.created_at).format("LLL")}</div>
          </div>
        </div>

        <div className="mt-0">
          <table className="w-full text-[9px]">
            <thead className="w-full bg-black/80 text-white text-[10px]">
              <tr>
                <th className="px-2 text-start">QTY</th>
                <th className="text-start">NAME</th>
                <th className="text-start">RATE</th>
                <th className="text-start">VALUE</th>
              </tr>
            </thead>
            <tbody>
              {items
                ?.filter((x) => !x.return)
                .map((item) => (
                  <tr key={crypto.randomUUID()} className="border-b border-black">
                    <td className="px-2 py-0">
                      {item.quantity} {item.unit}
                    </td>
                    <td className="font-semibold">{item.name}</td>
                    <td>{utills._currency_format(Number(item.price), "NGN")}</td>
                    <td>{utills._currency_format(Number(item.price) * item.quantity, "NGN")}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex items-center justify-between">
            <div className="text-[10px] flex gap-2 font-semibold mt-1">
              <span>Delivery Fee: {utills._currency_format(order?.delivery_price ?? 0, "NGN")}</span>
              <span>Total Due: {utills._currency_format(totalPrice.totalPriceInNaira, "NGN")}</span>
              <span>Total Paid: {utills._currency_format(order?.amount_paid ?? 0, "NGN")}</span>
              <span>Balance: {utills._currency_format((order?.total_price ?? 0) - (order?.amount_paid ?? 0), "NGN")}</span>
            </div>
          </div>
        </div>

        <div className="mt-5 mb-5">
          <div className="text-xs italic border-t text-center">
            All payments must be strictly made to Grace Johnsons Ltd 1013842180 Zenith Bank. Our return policy covers 2 weeks after purchase.
          </div>
        </div>
      </div>
    </>
  );
};
