import { Button, Label, Textarea } from "flowbite-react";
import { useEffect, useState } from "react";
import {
  useFooterNoteMutation,
  useGetFooterNoteQuery,
} from "../../redux/queries/company";
import { useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";

const Footer = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { data: note, isLoading: isNoting } = useGetFooterNoteQuery({
    company_uuid: defaultBranchUUid,
  });
  const [data, setData] = useState({
    footer: "",
  });
  const [footerNote, { isLoading: isPosting }] = useFooterNoteMutation();

  const onSubmit = async () => {
    const res = await footerNote({
      ...data,
      company_uuid: defaultBranchUUid,
    });

    if ("data" in res) {
      const { data } = res;
      if (data.status === 200) {
        toast.success(data.message);
      } else {
        toast.error("Failed!!");
      }
    } else {
      toast.error("Failed!!");
    }
  };

  useEffect(() => {
    if (note?.data?.footer.length) {
      setData({
        footer: note?.data?.footer,
      });
    }
  }, [note?.data?.footer]);

  return (
    <div className="flex items-center justify-center mt-5">
      <div className="xl:max-w-xl w-full px-2 xl:px-0">
        <div className="bg-white dark:bg-gray-700 p-3 pb-5">
          <div className=" mt-3">
            <div className="mb-2 block">
              <Label htmlFor="address" value={"Footer Description"} />
            </div>

            <Textarea
              disabled={isPosting || isNoting}
              id="description"
              placeholder="Put down the receipt footer note description here..."
              rows={5}
              value={data.footer}
              onChange={(e) => setData({ footer: e.target.value })}
            />
          </div>
          <div className="mt-7 flex items-center justify-end">
            <Button
              disabled={isPosting || isNoting || data.footer.length === 0}
              isProcessing={isPosting || isNoting}
              size="xs"
              className="rounded-sm"
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
