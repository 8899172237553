import { Button } from "flowbite-react";
import { features } from "../../route/data";
import { ImCancelCircle } from "react-icons/im";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

const Subscription = () => {
  return (
    <div className="px-4 mt-5">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs uppercase bg-[#1f91b2] text-white">
            <tr>
              <th scope="col" className="px-6 py-5">
                Features
              </th>
              <th
                scope="col"
                className="px-6 py-5 border-l dark:border-gray-700"
              >
                Standard
              </th>
              <th
                scope="col"
                className="px-6 py-5 border-l dark:border-gray-700"
              >
                Premium
              </th>
            </tr>
          </thead>
          <tbody>
            {features.map((item, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4 border-l dark:border-gray-700">
                    {item.standard ? (
                      <IoCheckmarkCircleSharp color="green" size={25} />
                    ) : (
                      <ImCancelCircle color="red" size={25} />
                    )}
                  </td>
                  <td className="px-6 py-4 border-l dark:border-gray-700">
                    {item.premium ? (
                      <IoCheckmarkCircleSharp color="green" size={25} />
                    ) : (
                      <ImCancelCircle color="red" size={25} />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td className="px-6 py-4 border-l dark:border-gray-700 text-center">
                <Button size="xs" className="rounded-sm w-full">
                  Select
                </Button>
              </td>
              <td className="px-6 py-4 border-l dark:border-gray-700 text-center">
                <Button size="xs" className="rounded-sm w-full">
                  Select
                </Button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default Subscription;
