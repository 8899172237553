import { Banner, Button, Checkbox } from "flowbite-react";
import { IoPricetags } from "react-icons/io5";
import { useAppSelector } from "../../lib/hook";
import {
  useGetUserCompaniesQuery,
  useMaintainPriceMutation,
} from "../../redux/queries/company";
import { useState } from "react";
import toast from "react-hot-toast";

const ManagePricing = () => {
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const { data: userBranches } = useGetUserCompaniesQuery({
    user: user?.uuid,
  });
  const [companies, setCompanies] = useState<string[]>([]);
  const [maintainPrice, { isLoading }] = useMaintainPriceMutation();

  const handleSubmit = async () => {
    const res = await maintainPrice({ company: defaultBranchUUid, companies });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
      } else {
        toast.error("Failed to maintain price");
      }
    } else toast.error("Failed to maintain price");
  };

  return (
    <div className="mt-10">
      <Banner className="mb-5">
        <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
          <div className="mb-4 md:mb-0 md:mr-4">
            <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
              Maintain Same Pricing
            </h2>
          </div>
          <div className="flex flex-shrink-0 items-center">
            <IoPricetags className="text-5xl text-gray-500 dark:text-white" />
          </div>
        </div>
      </Banner>

      <div className="p-4 flex flex-col items-start gap-3">
        {userBranches?.data.map((item) =>
          item.company.uuid !== defaultBranchUUid ? (
            <div className="flex gap-2 items-center">
              <Checkbox
                id={item.company.uuid}
                onChange={(e) => {
                  if (e.target.checked)
                    setCompanies([...companies, item.company.uuid]);
                  else {
                    const updated = companies.filter(
                      (i) => i !== item.company.uuid
                    );

                    setCompanies(updated);
                  }
                }}
              />
              <label htmlFor={item.company.uuid} className="capitalize">
                {item.company.name}
              </label>
            </div>
          ) : null
        )}
      </div>

      <div className="mt-10 flex items-center justify-end mr-10">
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          size="xs"
          className="rounded-sm"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ManagePricing;
