import Breadcrumb from "../../components/Breadcrumb";
import { useAppSelector } from "../../lib/hook";
import moment from "moment";
import utills from "../../lib/functions";
import { Badge, Button } from "flowbite-react";
import CustomPagination from "../../components/CustomPagination";
import BranchSwitcher from "../../components/BranchSwitcher";
import CustomDatePicker from "../../components/CustomDatePicker";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import CustomSelect from "../../components/CustomSelect";
import { useGetOrdersQuery } from "../../redux/queries/order";
import { useEffect, useState } from "react";

const Invoices = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    company: defaultBranchUUid,
    limit: "0,10",
    start_date: "",
    end_date: "",
    other_party: "",
  });
  const [active, setActive] = useState(0);
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
  });
  const [resetValue, setResetValue] = useState(false);
  const { data: orders, isLoading: isLoadingOrders } =
    useGetOrdersQuery(filters);
  console.log(orders);
  const [companiesFilters, setCompaniesFilters] = useState({
    limit: "0,10",
    status: 1,
    name: "",
  });
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetCompaniesQuery(companiesFilters);

  const todayFn = () => {
    setActive(1);
    if (orders?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const yesterdayFn = () => {
    setActive(2);
    if (orders?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .startOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .endOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const lastWeekFn = () => {
    setActive(3);
    if (orders?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  useEffect(() => {
    if (orders?.data.length) {
      setFilterFields((prev) => ({
        ...prev,
        start_date: orders?.data[orders?.data.length - 1]?.created_at.length
          ? moment(orders?.data[orders?.data.length - 1]?.created_at).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        end_date: orders?.data[0]?.created_at.length
          ? moment(orders?.data[0]?.created_at).format("YYYY-MM-DD HH:mm:ss")
          : "",
      }));
    }
  }, [orders?.data]);

  const reset = () => {
    setActive(0);
    setFilters({
      limit: "0,10",
      company: defaultBranchUUid,
      other_party: "",
      start_date: "",
      end_date: "",
    });
    setResetValue(true);
  };

  const getStatus = (status: number) => {
    switch (status) {
      case 3:
        return "Pickup";
      case 1:
        return "Paid";
      case 4:
        return "Pickup";
      case 7:
        return "Delivered";
      case 34:
        return "Vetted";
      case 35:
        return "Ready";
      default:
        return "Unpaid";
    }
  };

  const getColor = (status: number) => {
    switch (status) {
      case 3:
        return "success";
      case 1:
        return "success";
      case 4:
        return "success";
      case 7:
        return "success";
      case 34:
        return "indigo";
      case 35:
        return "success";
      default:
        return "warning";
    }
  };

  return (
    <div>
      <Breadcrumb title="Invoice History" />
      <div className="mt-5 mb-10 flex flex-wrap items-center gap-5 2xl:gap-10">
        <div className="flex justify-between h-32 flex-1">
          <div className="bg-green-100 flex-1 flex gap-2 justify-center flex-col items-center rounded-tl-xl rounded-bl-xl shadow-md">
            <span className="text-sm font-semibold text-green-900">
              Orders Received
            </span>
            <span className="text-gray-800 font-semibold text-[25px]">
              {utills._currency_format(Number(2400) ?? 0, "NGN")}
            </span>
          </div>
          <div className="bg-green-500 py-4 px-4 2xl:px-8 flex gap-2 justify-center flex-col items-center rounded-tr-xl rounded-br-xl shadow-md text-gray-800">
            <span className="text-sm font-semibold">Total Count</span>
            <span className="font-semibold text-[25px]">
              {utills._figure_format(Number(0) ?? 0)}
            </span>
          </div>
        </div>

        <div className="flex justify-between h-32 flex-1">
          <div className="bg-orange-100 flex-1 flex gap-2 justify-center flex-col items-center rounded-tl-xl rounded-bl-xl shadow-md">
            <span className="text-sm font-semibold text-orange-900">
              Orders Processed
            </span>
            <span className="text-gray-800 font-semibold text-[25px]">
              {utills._currency_format(Number(4500) ?? 0, "NGN")}
            </span>
          </div>
          <div className="bg-orange-500 py-4 px-4 2xl:px-8 flex gap-2 justify-center flex-col items-center rounded-tr-xl rounded-br-xl shadow-md text-gray-800">
            <span className="text-sm font-semibold">Total Count</span>
            <span className="font-semibold text-[25px]">
              {utills._figure_format(Number(0) ?? 0)}
            </span>
          </div>
        </div>

        <div className="flex justify-between h-32 flex-1">
          <div className="bg-blue-100 flex-1 flex gap-2 justify-center flex-col items-center rounded-tl-xl rounded-bl-xl shadow-md">
            <span className="text-sm font-semibold text-blue-900">
              Orders Delivered
            </span>
            <span className="text-gray-800 font-semibold text-[25px]">
              {utills._currency_format(Number(5500) ?? 0, "NGN")}
            </span>
          </div>
          <div className="bg-blue-500 py-4 px-4 2xl:px-8 flex gap-2 justify-center flex-col items-center rounded-tr-xl rounded-br-xl shadow-md text-gray-800">
            <span className="text-sm font-semibold">Total Count</span>
            <span className="font-semibold text-[25px]">
              {utills._figure_format(Number(0) ?? 0)}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex gap-5 items-center">
        <div className="flex justify-between items-center flex-wrap gap-3 w-full">
          <div className="flex flex-wrap items-center gap-3">
            <div className="flex items-center gap-2">
              <Button size="xs" onClick={todayFn} outline={active !== 1}>
                Today
              </Button>
              <Button size="xs" onClick={yesterdayFn} outline={active !== 2}>
                Yesterday
              </Button>
              <Button size="xs" onClick={lastWeekFn} outline={active !== 3}>
                Last Week
              </Button>
            </div>
            <CustomDatePicker
              start={
                filters.start_date.length
                  ? filters.start_date
                  : filterFields.start_date
              }
              onStartChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  start_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                }))
              }
              end={
                filters.end_date.length
                  ? filters.end_date
                  : filterFields.end_date
              }
              onEndChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  end_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                }))
              }
            />
            <BranchSwitcher
              sizing="sm"
              defaultCompany={filters.company}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company: value }))
              }
            />
            <div className="">
              <CustomSelect
                isClearable={false}
                disabled={isLoadingOrders || isLoadingCompanies}
                loading={isLoadingOrders || isLoadingCompanies}
                placeholder="Select Customer..."
                inputClasses="h-9"
                options={
                  companies?.data.map((customer) => ({
                    label: customer.name,
                    value: customer.uuid,
                  })) ?? []
                }
                onSelect={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    other_party: val.value,
                  }));
                  setResetValue(false);
                }}
                onSearch={(text) => {
                  setCompaniesFilters((prev) => ({ ...prev, name: text }));
                  setResetValue(false);
                }}
                reset={resetValue}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <Button outline size="xs" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoadingOrders}
          data={orders?.data}
          total={orders?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Invoice No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Items
                </th>
                <th scope="col" className="px-6 py-3">
                  Recipient
                </th>
                <th scope="col" className="px-6 py-3">
                  Rider
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {orders?.data?.map((item) => {
                return (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">
                      {moment(item.created_at).format("LLL")}
                    </td>
                    <td className="px-6 py-4">#{item.id}</td>
                    <td className="px-6 py-4">{item.total_price}</td>
                    <td className="px-6 py-4">{item.items}</td>
                    <td className="px-6 py-4">
                      {item.order.buyer ? item.order.buyer.name : "-"}
                    </td>
                    <td className="px-6 py-4">
                      {item.rider ? item.rider.name : "-"}
                    </td>

                    <td className="px-6 py-4">
                      <Badge className="w-fit" color={getColor(item.status)}>
                        {getStatus(item.status)}
                      </Badge>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CustomPagination>
      </div>
    </div>
  );
};

export default Invoices;
