import { Avatar, Badge, Dropdown } from "flowbite-react";
import { IoNotifications } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useGetNotificationsQuery } from "../redux/queries/notifications";
import utills from "../lib/functions";
import { useAppSelector } from "../lib/hook";
import moment from "moment";
import { HiClock } from "react-icons/hi";
import { PiEnvelopeFill } from "react-icons/pi";

const Notifications = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { data: notifications } = useGetNotificationsQuery({
    company: user?.company.uuid,
    user: user?.uuid,
    // limit: '0,10'
  });

  return (
    <Dropdown
      label="jcdnjcndj"
      dismissOnClick={false}
      renderTrigger={() => (
        <button className="items-center relative justify-center rounded-lg border border-gray-200 bg-white p-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">
          <IoNotifications className="h-4 w-4" />
          <div className="w-2 h-2 bg-red-500 rounded-full absolute -top-1 -left-1"></div>
        </button>
      )}
    >
      <div className="overflow-y-auto max-h-[550px]">
        {notifications?.data?.map((noti) => (
          <Dropdown.Item
            key={crypto.randomUUID()}
            as="div"
            onClick={() => navigate("/notifications", { state: { created_at: noti.created_at, subject: noti.subject, message: noti.message } })}
            className="max-w-[400px]"
          >
            <div className="flex items-start gap-2">
              <Avatar rounded />
              <div>
                <h3 className="font-semibold text-gray-900 dark:text-gray-100 mb-1">
                  {noti.subject.replace("{company_name}", noti.company?.name || "")}
                </h3>
                <span
                  className="text-xs text-gray-700 dark:text-gray-400"
                  dangerouslySetInnerHTML={{ __html: utills._truncateString(noti.message, 200) }}
                ></span>
                <div className=" text-xs font-semibold flex items-center gap-2">
                  {" "}
                  <Badge color="blue" className="w-max py-0.5 text-[11px]" icon={HiClock}>
                    {moment(noti.created_at).fromNow()}
                  </Badge>
                  {!noti.read_at && (
                    <Badge className="w-max py-0.5 text-[11px]" icon={PiEnvelopeFill}>
                      un-read
                    </Badge>
                  )}
                </div>
              </div>
            </div>
          </Dropdown.Item>
        ))}
      </div>

      {/* <div>
        show morw
      </div> */}
    </Dropdown>
  );
};

export default Notifications;
