import { useRef, useState } from "react";
import { Button, TextInput, Select, Tooltip } from "flowbite-react";
import { HiShoppingCart } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import { TiTimes } from "react-icons/ti";
import { BsGear } from "react-icons/bs";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { MdOutlineOpenInFull } from "react-icons/md";
import { CiShoppingTag } from "react-icons/ci";
import { IoGridOutline } from "react-icons/io5";
import Cart from "./Cart";
import CartProducts from "./CartProducts";
import { useAppSelector } from "../lib/hook";
import utills from "../lib/functions";
// import Drawer from "react-modern-drawer";
import Drawer from "@mui/material/Drawer";
import NewCustomer from "./NewCustomer";
import Shipping from "./Shipping";
import Discount from "./Discount";
import autocomplete from "autocompleter";
import { useLazyGetUsersQuery } from "../redux/queries/users";
import { FcSearch } from "react-icons/fc";
import { FaFileInvoice } from "react-icons/fa";
import { IoMdReturnLeft } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { exitUser } from "../redux/slices/appUser";
import { useDispatch } from "react-redux";
import PlaceOrder from "./PlaceOrder";
import toast from "react-hot-toast";
import Settings from "./Settings";
import { useCreateOrderMutation } from "../redux/queries/order";
import { useLazyGetProductQuery } from "../redux/queries/products";
import { clearCart } from "../redux/slices/cart";
import { Modal } from "react-responsive-modal";
import InvoicePdf from "../components/InvoicePdf";
import { useReactToPrint } from "react-to-print";
import { switchCompany } from "../redux/slices/utills";
import BranchSwitcher from "../components/BranchSwitcher";
import { useLogoutMutation } from "../redux/queries/auth";

interface MyInterface {
  label: string;
  value: string;
}

const POS = () => {
  const invoicePdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => invoicePdfRef.current,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart } = useAppSelector((state) => state.cartAndDraft);
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [screen, setScreen] = useState("1");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [invoiceModal, setInvoiceModal] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState("");
  const [action, setAction] = useState<string>("");
  const [postedOrder, setPostedOrder] = useState<CompanyOrder | null>(null);
  const [deliveryFee, setDeliveryFee] = useState("");
  const [discount, setDiscount] = useState("");
  const [needFocus, setNeedFocus] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState<MyInterface | null>(
    null
  );

  const [getCustomers] = useLazyGetUsersQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [getProduct] = useLazyGetProductQuery();
  const [createOrder] = useCreateOrderMutation();
  const [logout, { isLoading: isLogginOut }] = useLogoutMutation();

  const getTotalPrice = (type?: string) => {
    const totals = utills._total_item_in_cart(cart);
    const totalPValue = type
      ? totals.totalPriceInNaira
      : totals.totalPriceInNaira + Number(deliveryFee) - Number(discount);
    const formatedPrice = utills._currency_format(totalPValue, "NGN");
    return formatedPrice;
  };

  const openDrawer = (sc: string) => {
    setScreen(sc);
    setIsOpen(true);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const el = e.target;
    setSearchKey(el.value);
    const autoColmpleteEl = autocomplete<MyInterface>({
      input: el,
      emptyMsg: "No items found",
      minLength: 1,
      className: "bg-white dark:bg-gray-800",
      fetch: async (text: string, update: (items: MyInterface[]) => void) => {
        const res = await getCustomers({
          limit: "0,10",
          type: user?.type === "pharmacy" ? "user" : "pharmacy",
          status: 1,
          name: text.toLowerCase(),
        });
        const result = res.data?.data.map((item) => ({
          label:
            user?.type === "manufacturer"
              ? `${item.name} - (${item.company.name} Pharmarcy)`
              : item.name,
          value: item.uuid,
        }));
        if (result) update(result);
      },
      onSelect: (item: MyInterface) => {
        setSearchKey("");
        setSelectedCustomer(item);
        utills._play_sound();
        autoColmpleteEl.destroy();
      },
      render: function (
        item: MyInterface,
        currentValue: string
      ): HTMLDivElement | undefined {
        const itemElement = document.createElement("div");
        itemElement.classList.add(
          "p-2",
          "border",
          "border-gray-500",
          "text-xs",
          "hover:bg-gray-300",
          "cursor-pointer"
        );
        itemElement.classList.add("dark:text-white", "dark:hover:bg-gray-700");
        itemElement.textContent = item.label;
        return itemElement;
      },
    });
  };

  const placeInvoiceAndQuotation = async (type: number, name: string) => {
    let p = [];
    if (cart.length === 0) {
      utills._play_error_sound();
      toast.error("Please add item to the cart!!!");
      return;
    } else if (!selectedCustomer) {
      utills._play_error_sound();
      toast.error("Please select a customer!!!");
      return;
    }
    setLoading(true);
    setAction(name);
    for (const item of cart) {
      const res = await getProduct(item.itemId);
      if ("data" in res) {
        if (res.data?.status === 200) {
          const product = res.data?.data;
          p.push({
            uuid: product.uuid,
            name: product.name,
            price: item.price_in_naira,
            quantity: item.quantity,
            company: product.company.uuid,
            variant_id: item.variant_id ?? undefined,
            verified: item.verified,
          });
        } else {
          setLoading(false);
          toast.error("One if the items in cart could not be found");
          return;
        }
      } else {
        setLoading(false);
        toast.error("Unable to validate items");
        return;
      }
    }
    const payload: OrderPayload = {
      type: type,
      user: selectedCustomer?.value ?? "",
      payment_mode: 2,
      items: p,
      discount: Number(discount) ?? 0,
      delivery_fee: Number(deliveryFee) ?? 0,
    };
    const res = await createOrder(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        setPostedOrder(res.data.data);
        setInvoiceModal(true);
        toast.success(`${name} created`);
        dispatch(clearCart());
        utills._play_sound();
        resetDiscountAndShipping();
      } else toast.error(`Unable to create ${name}`);
    } else toast.error(`Failed to create ${name}`);
    setLoading(false);
  };

  const resetDiscountAndShipping = () => {
    setDiscount("");
    setDeliveryFee("");
  };

  return (
    <div className=" flex flex-col h-screen bg-gray-200">
      <div className="bg-black w-full flex justify-between items-center p-1">
        <div className="flex items-center gap-1">
          <Tooltip content="Go back to Products">
            <Button
              size="xs"
              className="rounded-sm p-0"
              onClick={() => navigate("/products")}
            >
              <IoMdReturnLeft />
            </Button>
          </Tooltip>
          <Button size="xs" color="dark" className="rounded-sm p-0">
            <IoGridOutline />
          </Button>
          <BranchSwitcher
            onChange={(text) => dispatch(switchCompany(text))}
            styling={{ padding: "0 29px 0 10px", borderRadius: 3 }}
          />
        </div>
        <div className="flex items-center gap-1">
          <Button
            size="xs"
            color="dark"
            className="rounded-sm p-0"
            onClick={utills._full_screen}
          >
            <MdOutlineOpenInFull />
          </Button>
          <Button
            size="xs"
            color="dark"
            className="rounded-sm p-0"
            onClick={() => openDrawer("5")}
          >
            <BsGear />
          </Button>
          <Button
            size="xs"
            color="dark"
            className="rounded-sm p-0"
            isProcessing={isLogginOut}
            disabled={isLogginOut}
            onClick={async () => {
              dispatch(exitUser());
              await logout({});
            }}
          >
            <RiLogoutCircleRLine className="mr-2" />
            Logout
          </Button>
        </div>
      </div>
      <div className="flex  p-3 flex-1 gap-2 dark:bg-gray-800">
        <CartProducts setQuantityFocus={(id) => setNeedFocus(id)} />
        <div className="md:w-[60%] xl:w-[35%]">
          <div className="bg-white dark:bg-gray-700 w-full h-full rounded-md p-2 flex flex-col">
            <div className="flex-1 flex flex-col overflow-hidden">
              <div className="flex">
                <div className="flex-1 me-2">
                  <TextInput
                    sizing="sm"
                    type="text"
                    icon={FcSearch}
                    placeholder="search customer..."
                    className="rounded-sm"
                    onChange={handleInput}
                    value={searchKey}
                    style={{ borderRadius: 2 }}
                  />
                </div>
                <Tooltip content="Add new customer">
                  <Button
                    size="sm"
                    className="rounded-sm py-1"
                    onClick={() => openDrawer("1")}
                  >
                    <FaPlus className="h-3 w-3" />
                  </Button>
                </Tooltip>
              </div>
              <div>
                <div className="dark:text-white truncate text-[11px] mt-2">
                  Customer: {selectedCustomer?.label}{" "}
                </div>
              </div>
              <div className="flex items-center flex-wrap gap-2">
                <Button
                  size="xs"
                  color="dark"
                  className="mt-2 rounded-sm"
                  onClick={() => openDrawer("2")}
                >
                  <HiShoppingCart className="mr-2 h-3 w-3" />
                  Shipping
                </Button>
              </div>
              <Cart cart={cart} needFocus={needFocus} />
            </div>

            <div className="mb-3">
              <div className="bg-gray-100 dark:bg-gray-900 p-2">
                <div className="flex justify-between items-center mb-1">
                  <span className="text-xs text-gray-700 dark:text-white">
                    Sub Total
                  </span>
                  <span className="text-xs text-gray-700 dark:text-white font-semibold">
                    {getTotalPrice("sub")}
                  </span>
                </div>

                <div className="flex justify-between items-center mb-1">
                  <span className="text-xs text-gray-700 dark:text-white">
                    Discount
                  </span>
                  <span className="text-xs text-gray-700 dark:text-white font-semibold">
                    {utills._currency_format(Number(discount), "NGN")}
                  </span>
                </div>

                <div className="flex justify-between items-center mb-1">
                  <span className="text-xs text-gray-700 dark:text-white">
                    Tax
                  </span>
                  <span className="text-xs text-gray-700 dark:text-white font-semibold">
                    {utills._currency_format(0, "NGN")}
                  </span>
                </div>

                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-700 dark:text-white">
                    Shipping Fee
                  </span>
                  <span className="text-xs text-gray-700 dark:text-white font-semibold">
                    {utills._currency_format(Number(deliveryFee ?? 0), "NGN")}
                  </span>
                </div>
              </div>
              <div className="bg-black p-2 flex justify-between items-center px-2 rounded-sm mb-2">
                <span className="text-gray-200 text-xs">
                  Total &#40;{cart.length}{" "}
                  {cart.length === 1 ? "product" : "products"}&#41;
                </span>
                <span className="text-gray-200 text-xs font-bold">
                  {getTotalPrice()}
                </span>
              </div>
              <div className="flex items-center gap-2 flex-wrap">
                {/* <Button
                  isProcessing={loading && action === "quotation"}
                  disabled={loading && action === "quotation"}
                  size="xs"
                  className="rounded-sm"
                  onClick={() => placeInvoiceAndQuotation(10, "quotation")}
                >
                  <BsBlockquoteLeft className="mr-2 h-3 w-3" />
                  Quotation
                </Button> */}

                <Button
                  isProcessing={loading && action === "invoice"}
                  disabled={loading && action === "invoice"}
                  size="xs"
                  className="rounded-sm"
                  onClick={() => placeInvoiceAndQuotation(11, "invoice")}
                >
                  <FaFileInvoice className="mr-2 h-3 w-3" />
                  Invoice
                </Button>
                <Button
                  onClick={() => {
                    if (cart.length === 0) {
                      utills._play_error_sound();
                      toast.error("Please add item to the cart!!!");
                    } else if (!selectedCustomer) {
                      utills._play_error_sound();
                      toast.error("Please select a customer!!!");
                    } else openDrawer("4");
                  }}
                  size="xs"
                  color="warning"
                  className="flex-1 rounded-sm"
                >
                  PLACE ORDER
                </Button>
                <Tooltip content="Discount">
                  <Button
                    size="xs"
                    color="dark"
                    className="rounded-sm"
                    onClick={() => openDrawer("3")}
                    // onClick={() => setInvoiceModal(true)}
                  >
                    <CiShoppingTag />
                  </Button>
                </Tooltip>
                <Tooltip content="Reset cart">
                  <Button className="rounded-sm" size="xs" color="failure">
                    <TiTimes />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen((prev) => !prev)}
        anchor="right"
        ModalProps={{
          keepMounted: false,
        }}
        sx={{ zIndex: 10 }}
      >
        {screen === "1" && (
          <NewCustomer open={isOpen} close={() => setIsOpen(false)} />
        )}
        {screen === "2" && (
          <Shipping
            deleveryFee={deliveryFee}
            customer={selectedCustomer}
            open={isOpen}
            close={() => setIsOpen(false)}
            updateFee={(val: string) => setDeliveryFee(val)}
          />
        )}
        {screen === "3" && (
          <Discount
            discount={discount}
            open={isOpen}
            close={() => setIsOpen(false)}
            updateDiscount={(val: string) => setDiscount(val)}
          />
        )}
        {screen === "4" && (
          <PlaceOrder
            open={isOpen}
            customer={selectedCustomer}
            close={() => setIsOpen(false)}
            deliveryFee={deliveryFee}
            discount={discount}
            resetShipping={resetDiscountAndShipping}
          />
        )}
        {screen === "5" && (
          <Settings open={isOpen} close={() => setIsOpen(false)} />
        )}
      </Drawer>

      {/* <Modal open={invoiceModal} onClose={() => setInvoiceModal(false)} center>
        <div>
          <span
            onClick={(e) => {
              handlePrint(e);
              setInvoiceModal(false);
            }}
            className="text-[#167490] text-xs font-bold hover:underline cursor-pointer"
          >
            Print document
          </span>
        </div>
        <InvoicePdf order={postedOrder} ref={invoicePdfRef} />
      </Modal> */}

      <InvoicePdf
        isOpen={invoiceModal}
        close={() => setInvoiceModal(false)}
        order={postedOrder}
      />
    </div>
  );
};

export default POS;
