import { Button, Label, Select, TextInput, useThemeMode } from "flowbite-react";
import { FaPlus } from "react-icons/fa";
import Modal from "react-responsive-modal";
import AddressSearch from "../../components/AddressSearch";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetCountriesQuery } from "../../redux/queries/settings";
import {
  useCreateCompanyMutation,
  useGetCompaniesQuery,
} from "../../redux/queries/company";
import {
  useGetCitiesQuery,
  useGetStatesQuery,
} from "../../redux/queries/location";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { lowerCase } from "lodash";
import CustomSelect from "../../components/CustomSelect";

interface CustomerBranchProps {
  staff: string;
  handleId: (id: string) => void;
}

const CustomerBranch = ({ staff, handleId }: CustomerBranchProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState({
    limit: "0,10",
    name: "",
    type: "branch",
    parent_uuid: staff,
  });
  const { data: branches, isLoading } = useGetCompaniesQuery(filters);
  const close = () => {
    setIsOpen(false);
  };
  return (
    <div className="flex items-center gap-2">
      <div className="w-[200px]">
        <CustomSelect
          isClearable={false}
          disabled={isLoading}
          loading={isLoading}
          placeholder="Select Branch..."
          inputClasses="h-9"
          options={
            branches?.data.map((branch) => ({
              label: branch.name,
              value: branch.uuid,
            })) ?? []
          }
          onSelect={(val) => {
            handleId(val.value)
          }}
          onSearch={(text) => {
            setFilters((prev) => ({ ...prev, name: text }));
          }}
        />
      </div>
      <Button size="xs" className="rounded-sm " onClick={() => setIsOpen(true)}>
        <FaPlus className="mr-2" />
        Add Branch
      </Button>
      <Modal
        classNames={{
          modalContainer: "__remove_modal_bg",
          closeButton: "dark:bg-gray-400",
        }}
        center
        open={isOpen}
        onClose={close}
      >
        <CreateBranch close={close} staff={staff} />
      </Modal>
    </div>
  );
};

export default CustomerBranch;

interface BranchModal {
  close: () => void;
  staff: string;
}

interface IFormInput {
  name: string;
  phone: string;
  email: string;
  status?: string;
  countryName: string;
  city: string;
  state_id: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Branch name is a required field"),
  phone: yup.string().required("Phone is a required field"),
  status: yup.string(),
  email: yup.string().required("Email is a required field"),
  countryName: yup.string().required("Country is a required field"),
  city: yup.string().required("City is a required field"),
  state_id: yup.string().required("State is a required field"),
});

interface Payload {
  uuid?: string;
  name: string;
  phone: number;
  email: string;
  city_id: any;
  status?: string;
  parent_uuid: string;
  type: string;
  address: {
    name: string;
    lat: number;
    lng: number;
  };
}

const CreateBranch = (props: BranchModal) => {
  const { close, staff } = props;
  const [createCompany, { isLoading }] = useCreateCompanyMutation();
  const { data: countries } = useGetCountriesQuery();
  const [countryArr, setCountryArr] = useState<any>([]);
  const [countryName, setCountryName] = useState<any>("");
  const [cityName, setCityName] = useState<any>("");
  const [stateId, setStateId] = useState<any>("");
  const { mode } = useThemeMode();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });
  const [address, setAddress] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });
  const onSubmit = async (data: IFormInput) => {
    if (!address.name) {
      toast.error("Please enter address!!!");
      return;
    }

    const getCity = cities?.data?.filter(
      (cityinn) => cityinn?.name === data.city
    );

    const payload: Payload = {
      name: data.name,
      email: data.email,
      city_id: getCity ? getCity[0]?.id : null,
      phone: Number(data.phone),
      status: data.status,
      parent_uuid: staff,
      type: "branch",
      address,
    };

    const res = await createCompany(payload);
    console.log("res", res, payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Branch Created");
        close();
      } else {
        toast.error(res.data.message);
      }
    } else toast.error("Unable to create branch");
  };

  useEffect(() => {
    if (countries?.data && countryName) {
      const getCountry = countries?.data?.filter(
        (country) => country?.name === countryName
      );
      setCountryArr(getCountry);
    }
  }, [countries?.data, countryName]);

  const countryId = countryArr?.[0]?.id || null;
  const { data: states } = useGetStatesQuery({
    country_id: countryId,
  });

  const { data: cities } = useGetCitiesQuery({
    country_id: countryId,
    state_id: stateId,
  });

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white dark:bg-gray-800 rounded-lg">
          <div className="dark:bg-gray-800 rounded-t-lg p-2">
            <span className="dark:text-white">Enter Branch Information</span>
          </div>

          <div className="grid grid-cols-2 gap-4 p-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Branch Name" />
              </div>
              <TextInput
                disabled={isLoading}
                {...register("name")}
                id="name"
                type="text"
                className="min-w-[200px]"
                sizing="sm"
                placeholder="Branch Name"
                style={{ borderRadius: 3 }}
                color={errors?.name ? "failure" : "gray"}
                helperText={
                  errors.name && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.name?.message}!
                    </span>
                  )
                }
              />
            </div>

            <div className="">
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                disabled={isLoading}
                {...register("email")}
                id="email"
                type="email"
                sizing="sm"
                className="min-w-[200px]"
                style={{ borderRadius: 3 }}
                placeholder="Email"
                color={errors?.email ? "failure" : "gray"}
                helperText={
                  errors.name && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.email?.message}!
                    </span>
                  )
                }
              />
            </div>

            <div className="">
              <div className="mb-2 block">
                <Label htmlFor="phone" value="Phone" />
              </div>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div>
                    <PhoneInput
                      {...field}
                      country={lowerCase("ng")}
                      disabled={isLoading}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      buttonStyle={{
                        backgroundColor: errors?.phone
                          ? "rgb(254 242 242)"
                          : mode === "light"
                          ? "rgb(249 250 251)"
                          : "rgb(75 85 99)",
                        border: `1px solid ${
                          errors?.phone
                            ? "rgb(239 68 68)"
                            : mode === "light"
                            ? "rgb(209 213 219)"
                            : "rgb(75 85 99)"
                        }`,
                      }}
                      inputStyle={{
                        width: "100%",
                        paddingRight: "5px",
                        backgroundColor: errors?.phone
                          ? "rgb(254 242 242)"
                          : mode === "light"
                          ? "rgb(249 250 251)"
                          : "rgb(75 85 99)",
                        border: `1px solid ${
                          errors?.phone
                            ? "rgb(239 68 68)"
                            : mode === "light"
                            ? "rgb(209 213 219)"
                            : "rgb(75 85 99)"
                        }`,
                        color: mode === "light" ? "black" : "white",
                      }}
                      dropdownStyle={{
                        position: "absolute",
                        top: -20,
                        left: 0,
                      }}
                    />
                    {errors.phone && (
                      <span className="font-medium text-[10px] text-red-500">
                        {" "}
                        {errors.phone?.message}!
                      </span>
                    )}
                  </div>
                )}
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label htmlFor="stu" value="Status" />
              </div>

              <Select
                disabled={isLoading}
                style={{ borderRadius: 3 }}
                sizing="sm"
                id="stu"
                {...register("status")}
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </Select>
            </div>
            <div>
              <Label
                value={
                  countryName.length ? `Country: ${countryName}` : "Country"
                }
              />
              <Select
                sizing="sm"
                {...register("countryName", {
                  onChange: (e) => {
                    setCountryName(e.target.value);
                  },
                })}
                disabled={isLoading}
                color={errors?.countryName ? "failure" : "gray"}
                helperText={
                  errors.countryName && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.countryName?.message}!
                    </span>
                  )
                }
              >
                <option value="" hidden>
                  Select Country
                </option>
                {countries ? (
                  countries.data.map((country) => (
                    <option key={country?.id} value={country?.name}>
                      {country.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No countries available</option>
                )}
              </Select>
            </div>

            <div>
              <Label value="State" />
              <Select
                sizing="sm"
                {...register("state_id", {
                  onChange: (e) => {
                    setStateId(e.target.value);
                  },
                })}
                disabled={isLoading}
                color={errors?.state_id ? "failure" : "gray"}
                helperText={
                  errors.state_id && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.state_id?.message}!
                    </span>
                  )
                }
              >
                <option value="" hidden>
                  Select State
                </option>

                {states?.data?.map((state) => (
                  <option key={state?.id} value={state?.id}>
                    {state.name}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <Label value={cityName?.length ? `City: ${cityName}` : "City"} />
              <Select
                sizing="sm"
                {...register("city", {
                  onChange: (e) => {
                    setCityName(e.target.value);
                  },
                })}
                color={errors?.city ? "failure" : "gray"}
                helperText={
                  errors.city && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.city?.message}!
                    </span>
                  )
                }
                disabled={isLoading}
              >
                <option value="" hidden>
                  Select City
                </option>

                {cities?.data?.map((cityinn: any) => (
                  <option key={cityinn?.id} value={cityinn?.name}>
                    {cityinn.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="px-4">
            <div className="mb-2 block">
              <Label htmlFor="address" value="Address" />
            </div>
            <AddressSearch
              onSelect={(val) => setAddress(val)}
              sizing="sm"
              style={{ borderRadius: 3 }}
            />
          </div>

          <div className="p-4">
            <Button
              disabled={isLoading}
              isProcessing={isLoading}
              type="submit"
              className="rounded-sm w-full"
              size="xs"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
