import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import CustomPagination from "../../components/CustomPagination";
import { Button, Label, Select } from "flowbite-react";
import { FaPlus } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import Modal from "react-responsive-modal";
import CustomSelect from "../../components/CustomSelect";
import { useAppSelector } from "../../lib/hook";
import {
  useAddWishlistMutation,
  useGetProductsQuery,
  useGetWishListQuery,
} from "../../redux/queries/products";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import toast from "react-hot-toast";

const WishList = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: defaultBranchUUid,
    buyer_company_uuid: "",
  });
  const [open, setOpen] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const { data: wishList, isLoading } = useGetWishListQuery(filters);
  console.log(wishList?.data);

  const [buyerfilters, setBuyerFilters] = useState({
    limit: "0,10",
    status: 1,
    name: "",
  });

  const { data: users, isLoading: isLoadingBuyers } =
    useGetCompaniesQuery(buyerfilters);

  const reset = () => {
    setBuyerFilters({
      limit: "0,10",
      status: 1,
      name: "",
    });
    setFilters({
      limit: "0,10",
      company: defaultBranchUUid,
      buyer_company_uuid: "",
    });
    setResetValue(true);
  };

  return (
    <div>
      <Breadcrumb
        title="WishList"
        rightButton={
          <Button
            size="xs"
            className="flex items-center"
            onClick={() => setOpen(!open)}
          >
            <FaPlus className="mr-2" />
            Add WishList
          </Button>
        }
      />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex gap-5 items-center">
        <div className="flex justify-between items-center flex-wrap gap-3 w-full">
          <div className="flex flex-wrap items-center gap-3">
            <div className="w-[200px]">
              <CustomSelect
                isClearable={false}
                disabled={isLoading || isLoadingBuyers}
                loading={isLoading || isLoadingBuyers}
                placeholder="Select Customer..."
                inputClasses="h-9"
                options={
                  users?.data.map((customer) => ({
                    label: customer.name,
                    value: customer.uuid,
                  })) ?? []
                }
                onSelect={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    buyer_company_uuid: val.value,
                  }));
                  setResetValue(false);
                }}
                onSearch={(text) => {
                  setBuyerFilters((prev) => ({ ...prev, name: text }));
                  setResetValue(false);
                }}
                reset={resetValue}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <Button outline size="xs" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={wishList?.data}
          total={wishList?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Item
                </th>
                <th scope="col" className="px-6 py-3">
                  Buyer
                </th>
              </tr>
            </thead>
            <tbody>
              {wishList?.data?.map((item) => (
                <tr
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">{item.item.name}</td>
                  <td className="px-6 py-4">{item.user.name ?? "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={open}
        onClose={() => setOpen(false)}
      >
        <AddWishList close={() => setOpen(false)} />
      </Modal>
    </div>
  );
};

export default WishList;

interface AddWishListProps {
  close: () => void;
}

const AddWishList = ({ close }: AddWishListProps) => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);

  const [payload, setPayload] = useState({
    item_uuid: "",
    buyer_company_uuid: "",
  });

  const [buyerfilters, setBuyerFilters] = useState({
    limit: "0,10",
    type: "pharmacy",
    status: 1,
    name: "",
  });
  const [addWishlist, { isLoading }] = useAddWishlistMutation();

  const [productfilters, setProductFilters] = useState({
    limit: "0,10",
    name: "",
    company: defaultBranchUUid,
  });
  const { data: products, isLoading: isLoadingProducts } =
    useGetProductsQuery(productfilters);
  const { data: users, isLoading: isLoadingBuyers } =
    useGetCompaniesQuery(buyerfilters);

  const onSubmit = async () => {
    const res = await addWishlist(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        close();
      } else {
        toast.error(res.data.message);
      }
    } else toast.error("Error- Adding WishList Failed!!!");
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-semibold">Add WishList</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <div className="bg-white h-[500px] p-3 pb-5 flex flex-col justify-between">
        <div className="grid grid-cols-2 gap-3 mt-2">
          <div className="">
            <Label value="Buyer" className="text-sm dark:text-gray-800" />
            <CustomSelect
              isClearable={false}
              disabled={isLoading || isLoadingBuyers}
              loading={isLoading || isLoadingBuyers}
              placeholder="Select Product..."
              inputClasses="h-9"
              options={
                users?.data.map((customer) => ({
                  label: customer.name,
                  value: customer.uuid,
                })) ?? []
              }
              onSelect={(val) => {
                setPayload((prev) => ({
                  ...prev,
                  buyer_company_uuid: val.value,
                }));
              }}
              onSearch={(text) => {
                setBuyerFilters((prev) => ({ ...prev, name: text }));
              }}
            />
          </div>

          <div className="">
            <Label value="Item" className="text-sm dark:text-gray-800" />

            <CustomSelect
              isClearable={false}
              disabled={isLoading || isLoadingProducts}
              loading={isLoading || isLoadingProducts}
              placeholder="Select Product..."
              inputClasses="h-9"
              options={
                products?.data.map((customer) => ({
                  label: customer.name,
                  value: customer.uuid,
                })) ?? []
              }
              onSelect={(val) => {
                setPayload((prev) => ({
                  ...prev,
                  item_uuid: val.value,
                }));
              }}
              onSearch={(text) => {
                setProductFilters((prev) => ({ ...prev, name: text }));
              }}
            />
          </div>

          <div className="">
            <Label value="Type" className="text-sm dark:text-gray-800" />
            <Select
              disabled={isLoading}
              name="type"
              required
              sizing="md"
              style={{ borderRadius: "5px" }}
              onChange={(e) =>
                setBuyerFilters((prev) => ({ ...prev, type: e.target.value }))
              }
            >
              <option value="pharmacy">Pharmacy</option>
              <option value="hospital">Hospital</option>
            </Select>
          </div>
        </div>

        <div className="mt-7 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="sm"
            className="rounded-sm"
            onClick={onSubmit}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};
