import { api } from "../baseConfig";

interface Payload {
  username: string;
  password: string;
  type: "manufacturer" | "pharmacy";
}
export const authenticationSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    auth: builder.mutation<AppUserResponse, Payload>({
      query: (data) => ({
        url: "/authenticate",
        method: "POST",
        body: { ...data },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/authenticate/logout",
        method: "POST",
      }),
    }),
  }),
});

export const { useAuthMutation, useLogoutMutation } = authenticationSlice;
