import { FaTimes } from "react-icons/fa";
import Modal from "react-responsive-modal";
import utills from "../../lib/functions";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "flowbite-react";
import { IoPrint } from "react-icons/io5";
import { useAppSelector } from "../../lib/hook";
import { useGetUserQuery, useLazyGetUserQuery } from "../../redux/queries/users";
import { useGetCartItemsQuery } from "../../redux/queries/cart";
import { useGetCompanyQuery, useGetFooterNoteQuery } from "../../redux/queries/company";

interface Properties {
  delevery_fee: string;
  customer: {
    value: string;
    label: string;
  };
  invoice_id?: string
}

interface IPrintModal {
  isOpen: boolean;
  close: () => void;
  items: InvoiceCartWithId[];
  properties: Properties;
  cartId?: string;
  branch: string;
}

const InvoiceCart = (props: IPrintModal) => {
  const { close, isOpen, items, properties, cartId, branch } = props;
  const { data: company } = useGetCompanyQuery(branch)
  const { data: footer } = useGetFooterNoteQuery({ company_uuid: branch}, { skip: !branch})
  const totalPrice = () => {
    let total = 0;
    for (const item of items) {
      total += item.price * item.quantity - Number(item.discount ?? 0);
    }
    return total;
  };

  const reversDate = structuredClone(items).reverse()

  return (
    <Modal
      open={isOpen}
      onClose={close}
      classNames={{
        modalContainer: "__remove_modal_bg",
      }}
      showCloseIcon={false}
    >
      <div className="bg-white p-5 w-full ">
        <div className="flex justify-between items-center">
          <div></div>
          <button onClick={close}>
            <FaTimes />
          </button>
        </div>

        <PagePrint footer={footer?.data?.footer} branch={company?.data} invoiceId={cartId} properties={properties} items={reversDate?.slice(0, 36)} grandTotal={totalPrice()} totalPage={Math.ceil((reversDate?.length ?? 0) / 36)} />

        {reversDate?.length > 36 &&
          Array(Math.ceil((items.length - 36) / 40))
            .fill(0)
            .map((_, index) => {
              const start = 36 + index * 40;
              const end = start + 40;
              return <PagePrint2 footer={footer?.data?.footer} branch={company?.data} invoiceId={cartId}  page={index + 2} key={index} properties={properties} items={reversDate.slice(start, end)} grandTotal={totalPrice()} totalPage={Math.ceil((reversDate?.length ?? 0) / 36)} />;
            })}
      </div>
    </Modal>
  );
};

export default InvoiceCart;

const PagePrint = ({ footer, properties, items, grandTotal, totalPage, invoiceId , branch }: {footer?: string,  branch?: Branch; invoiceId?: string; properties: Properties; items: InvoiceCartWithId[]; grandTotal: number, totalPage?: number }) => {
  const elRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => elRef.current,
  });
  const { user } = useAppSelector((state) => state.appUserConfig);

  const { data: userData } = useGetUserQuery(user?.uuid as string);
  const [getUserRecord] = useLazyGetUserQuery();
  const [buyer, setBuyer] = useState<User | undefined>();

  const totalPrice = () => {
    let total = 0;
    for (const item of items) {
      total += item.price * item.quantity - Number(item.discount ?? 0);
    }
    return total;
  };

  const buyerRecord = async () => {
    const res = await getUserRecord(properties.customer.value);
    if (res.data?.data) {
      setBuyer(res.data.data);
    }
  };

  useEffect(() => {
    buyerRecord();
  }, []);

  return (
    <>
      <Button className="" size="xs" onClick={(e: unknown) => handlePrint(e)}>
        <IoPrint className="mr-2 h-4 w-4" />
        Print page 1
      </Button>

      <div className=" px-5" ref={elRef}>
        <div className="text-center border-b border-black pb-2">
          <div className="text-[21px] font-bold">{branch?.name}</div>
          <div className="text-xs">{branch?.address.name}</div>
          <div className="text-xs">
            <span>Tel: {branch?.phone}</span> - <span>Whatsapp: {branch?.whatsapp}</span> -{" "}
            <span>Email: {branch?.email}</span>{" "}
          </div>
        </div>

        <div className="flex justify-between mt-3">
          <div className="flex flex-col gap-0 text-xs">
            <div>
              <strong>TO: </strong> <span className="font-bold uppercase">{buyer?.company.name}</span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold">EMAIL:</span>

              <div>{buyer?.company.email}</div>

              <div className="flex gap-2">
                <span className="font-bold">STAFF:</span>

                <div className="capitalize">{buyer?.name}</div>
              </div>
            </div>
            <div className="flex gap-2">
              <span className="font-bold">ADDRESS:</span>
              <div>{buyer?.company.address2 ?? buyer?.company?.address?.name}</div>
            </div>
            <div className="flex gap-2">
              <span className="font-bold">Total items on page: </span>
              <div>{items?.length}</div>
            </div>
          </div>
          <div className="text-[10px] font-bold">
            <div className="flex">
              <span className="w-24">DATE:</span> <span>{moment().format("LLL")}</span>
            </div>
            <div className="flex">
              <span className="w-24">SERVED BY:</span> <span className="capitalize">{user?.name}</span>
            </div>
            <div className="flex">
              <span className="w-24">BRANCH:</span> <span className="capitalize">{userData?.data.company.name}</span>
            </div>
            <div className="flex">
              <span className="w-24">INVOICE ID:</span> <span className="capitalize">{invoiceId}</span>
            </div>
          </div>
        </div>

        {/* Body of the Invoice */}

        <div className="mt-0">
          <table className="w-full text-[13px]">
            <thead className="w-full bg-black/80 text-white text-xs">
              <tr>
                <th className="px-2 text-start">QTY</th>
                <th className="text-start">NAME</th>
                <th className="text-start">RATE</th>
                <th className="text-start">VALUE</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={crypto.randomUUID()} className="border-b border-black">
                  <td className="px-2 py-0">{item.quantity + " " + item.base_unit}</td>
                  <td className="font-semibold">{item.name}</td>
                  <td>{utills._currency_format(Number(item.price), "NGN")}</td>
                  <td>{utills._currency_format(Number(item.price) * item.quantity - Number(item.discount ?? 0), "NGN")}</td>
                </tr>
              ))}
            </tbody>
          </table>
         <div className="flex items-center justify-between">
         <div className="text-xs flex gap-2 font-semibold mt-1">
            <span>Delivery Fee: {utills._currency_format(Number(properties.delevery_fee) ?? 0, "NGN")}</span>
            <span>Grand Total: {utills._currency_format(grandTotal + Number(properties.delevery_fee), "NGN")}</span>
            <span>Total Amount On Page: {utills._currency_format(totalPrice() + Number(properties.delevery_fee ?? "0"), "NGN")}</span>
            <span>Balance: {utills._currency_format(totalPrice() + Number(properties.delevery_fee ?? "0"), "NGN")}</span>
          </div>
          <div className="flex items-center justify-between">Page 1/{totalPage}</div>
         </div>
        </div>

        <div className="mt-5 mb-5">
          <div className="text-xs italic border-t text-center">
              {footer}
          </div>
        </div>
      </div>
    </>
  );
};

const PagePrint2 = ({
  properties,
  items,
  grandTotal,
  page,
  totalPage,
  invoiceId,
  branch,
  footer
}: {
  properties: Properties;
  items: InvoiceCartWithId[];
  grandTotal: number;
  page: number;
  totalPage?: number
  invoiceId?: string
  branch?: Branch
  footer?: string
}) => {
  const page2elRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => page2elRef.current,
  });
  const { user } = useAppSelector((state) => state.appUserConfig);

  // const {data: userData } = useGetUserQuery(user?.uuid as string)
  const [getUserRecord] = useLazyGetUserQuery();
  const [buyer, setBuyer] = useState<User | undefined>();

  // useEffect(()=> {
  //   handlePrint()
  // },[])

  const totalPrice = () => {
    let total = 0;
    for (const item of items) {
      total += item.price * item.quantity - Number(item.discount ?? 0);
    }
    return total;
  };

  const buyerRecord = async () => {
    const res = await getUserRecord(properties.customer.value);
    if (res.data?.data) {
      setBuyer(res.data.data);
    }
  };

  useEffect(() => {
    buyerRecord();
  }, []);

  return (
    <>
      <Button className="" size="xs" onClick={(e: unknown) => handlePrint(e)}>
        <IoPrint className="mr-2 h-4 w-4" />
        Print page {page}
      </Button>
      <div className="px-10" ref={page2elRef}>
        <div className="flex justify-between mt-3">
          <div className="flex flex-col gap-2 text-xs">
            <div className="flex gap-2">
              <span className="font-bold">TO:</span>
              <div className="font-bold uppercase">{buyer?.company.name}</div>
            </div>
            <div className="flex gap-2">
              <span className="font-bold">EMAIL:</span>

              <div>{buyer?.company.email}</div>

              <div className="flex gap-2">
                <span className="font-bold">STAFF:</span>

                <div>{buyer?.name}</div>
              </div>
            </div>
            <div className="flex gap-2">
              <span className="font-bold">ADDRESS:</span>
              <div>{buyer?.company.address.name}</div>
            </div>
          </div>
          <div className="text-[10px] font-bold">
            <div className="flex">
              <span className="w-24">DATE:</span> <span>{moment().format("LLL")}</span>
            </div>
            <div className="flex">
              <span className="w-24">SERVED BY:</span> <span>{user?.name}</span>
            </div>
          </div>
        </div>

        {/* Body of the Invoice */}

        <div className="mt-4">
          <table className="w-full text-xs">
            <thead className="w-full bg-black/80 text-white text-xs">
              <tr>
                <th className="px-2 text-start">QTY</th>
                <th className="text-start">NAME</th>
                <th className="text-start">RATE</th>
                <th className="text-start">VALUE</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={item.cart_uuid} className="border-b border-black">
                  <td className="px-2 py-1">{item.quantity + " " + item.base_unit}</td>
                  <td className="font-semibold">{item.name}</td>
                  <td>{utills._currency_format(Number(item.price), "NGN")}</td>
                  <td>{utills._currency_format(Number(item.price) * item.quantity - Number(item.discount ?? 0), "NGN")}</td>
                </tr>
              ))}
            </tbody>
          </table>
      <div className="flex items-center justify-between">
      <div className="text-xs flex gap-2 font-semibold mt-3">
            <span>Delivery Fee: {utills._currency_format(Number(properties.delevery_fee) ?? 0, "NGN")}</span>
            <span>Grand Total: {utills._currency_format(grandTotal + Number(properties.delevery_fee), "NGN")}</span>
            <span>Total Amount On Page: {utills._currency_format(totalPrice() + Number(properties.delevery_fee ?? "0"), "NGN")}</span>
            <span>Balance: {utills._currency_format(totalPrice() + Number(properties.delevery_fee ?? "0"), "NGN")}</span>
          </div>
          <div className="font-semibold text-xs">Page {page}/{totalPage}</div>
      </div>
        </div>

        <div className="mt-10 mb-5">
          <div className="text-xs italic border-t text-center">
            {footer}
          </div>
        </div>
      </div>
    </>
  );
};
