import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { useResetPasswordMutation } from "../../redux/queries/users";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";
import { useState } from "react";

interface IFormInput {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,12}$/,
      "New password must be 8-12 characters long and include both letters and numbers"
    )
    .required("New pin is required"),
  oldPassword: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Pin do not match")
    .test("match", "Pin do not match", function (value) {
      return value === this.parent.newPassword;
    }),
});

const ResetPassword = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const onSubmit = async (data: IFormInput) => {
    const res = await resetPassword({
      id: user?.uuid,
      ...data,
    });
    //console.log(res);
    if ("data" in res) {
      if (res.data.status !== 200) {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
    } else toast.error("Error- Action Failed!!!");
  };

  return (
    <form className="mt-7 rounded-t-lg" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-3 gap-3 p-4">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="new2" value="Old Password" />
          </div>
          <TextInput
            id="new2"
            className="hide-spin-buttons"
            type={isPasswordVisible ? "text" : "password"}
            disabled={isLoading}
            autoComplete="off"
            {...register("oldPassword")}
            color={errors?.oldPassword ? "failure" : "gray"}
            helperText={
              errors.oldPassword && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.oldPassword?.message}!
                </span>
              )
            }
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="old" value="New Password" />
          </div>
          <TextInput
            id="old"
            type={isPasswordVisible ? "text" : "password"}
            disabled={isLoading}
            autoComplete="off"
            className="hide-spin-buttons"
            {...register("newPassword")}
            color={errors?.newPassword ? "failure" : "gray"}
            helperText={
              errors.newPassword && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.newPassword?.message}!
                </span>
              )
            }
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="new1" value="Confirm Password" />
          </div>
          <TextInput
            id="new1"
            className="hide-spin-buttons"
            type={isPasswordVisible ? "text" : "password"}
            disabled={isLoading}
            autoComplete="off"
            {...register("confirmPassword")}
            color={errors?.confirmPassword ? "failure" : "gray"}
            helperText={
              errors.confirmPassword && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.confirmPassword?.message}!
                </span>
              )
            }
          />
        </div>
      </div>
      <div className="flex items-center gap-2 mt-3 ml-4">
        <Checkbox
          id="remember"
          onChange={() => setIsPasswordVisible((prev) => !prev)}
        />
        <Label className="text-xs" htmlFor="remember">
          Show Password
        </Label>
      </div>
      <div className="flex justify-end mb-3 p-3">
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          type="submit"
          className="rounded-md"
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default ResetPassword;
