import { useEffect, useRef, useState } from "react";
import {
  useDeleteProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
} from "../../redux/queries/products";
import { Select, TextInput, Button, Spinner } from "flowbite-react";
import { IoCheckmarkSharp } from "react-icons/io5";
import { FaXmark } from "react-icons/fa6";
import { IoTrashOutline } from "react-icons/io5";
import Breadcrumb from "../../components/Breadcrumb";
import { MdSearch } from "react-icons/md";
import utills from "../../lib/functions";
import { useAppSelector } from "../../lib/hook";
import { useGetCategoriesQuery } from "../../redux/queries/settings";
import toast from "react-hot-toast";
import BranchSwitcher from "../../components/BranchSwitcher";
import ImageView from "../../components/ImageView";
import PaginationControl from "../../components/PaginationControl";
import CustomPagination from "../../components/CustomPagination";

const ProductManagement = () => {
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const [filters, setFilters] = useState({
    limit: "0,10",
    name: "",
    company: defaultBranchUUid,
  });

  const [filterFields, setFilterFields] = useState({
    name: "",
    category: "",
    orderby: "",
    company: defaultBranchUUid,
    base_unit: "",
  });

  const [deleteProduct] = useDeleteProductMutation();
  const { data: products, isLoading } = useGetProductsQuery(filters);
  const { data: categories } = useGetCategoriesQuery({
    type: user?.company.type,
    status: 1,
  });
  console.log(products)

  const applyFilter = () => {
    setFilters((prev) => ({ ...prev, ...filterFields, limit: "0,10" }));
  };

  const reset = () => {
    setFilterFields({
      name: "",
      category: "",
      orderby: "",
      company: "",
      base_unit: "",
    });
    setFilters({
      limit: "0,10",
      name: "",
      company: defaultBranchUUid,
    });
  };

  const handleDelete = async (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action) {
      const res = await deleteProduct(id);
      if ("data" in res) {
        toast.success("Item Deleted");
      } else toast.error("Unable to delete product");
    }
  };

  useEffect(() => {
    if (filterFields.name)
      setFilters((prev) => ({ ...prev, name: filterFields.name }));
  }, [filterFields.name]);

  return (
    <div>
      <Breadcrumb title="Product Management" />
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Cost Price
            </span>
          </div>
          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._currency_format(Number(0) ?? 0, "NGN")}</span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Profit
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._currency_format(Number(0) ?? 0, "NGN")}</span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Selling Profit
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._currency_format(Number(0) ?? 0, "NGN")}</span>
          </div>
        </div>
      </div>
      <form
        className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm"
        onSubmit={(e) => {
          e.preventDefault();
          applyFilter();
        }}
      >
        <div className="grid grid-cols-4 gap-3">
          <div>
            <TextInput
              sizing="sm"
              type="text"
              value={filterFields.name}
              icon={MdSearch}
              placeholder="search..."
              onChange={(e) =>
                setFilterFields((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div>
            <Select
              sizing="sm"
              id="countries"
              value={filterFields.category}
              onChange={(e) =>
                setFilterFields((prev) => ({
                  ...prev,
                  category: e.target.value,
                }))
              }
            >
              <option value="">All categories</option>
              {categories?.data.map((cat) => (
                <option value={cat.uuid} key={cat.id}>
                  {cat.name}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Select
              value={filterFields.orderby}
              onChange={(e) =>
                setFilterFields((prev) => ({
                  ...prev,
                  orderby: e.target.value,
                }))
              }
              id="sort"
              sizing="sm"
            >
              <option value="">Sort by default</option>
              <option value="quantity_DESC">Quantity (highest)</option>
              <option value="quantity_ASC">Quantity (Lowest)</option>
              <option value="price_DESC">Price (highest)</option>
              <option value="price_ASC">Price (Lowest)</option>
              <option value="<=">Low/Out of stock</option>
            </Select>
          </div>

          <div>
            <BranchSwitcher
              sizing="sm"
              defaultCompany={filterFields.company}
              onChange={(value) =>
                setFilterFields((prev) => ({ ...prev, company: value }))
              }
            />
          </div>

          <div>
            <TextInput
              sizing="sm"
              type="text"
              value={filterFields.base_unit}
              placeholder="Base Unit"
              onChange={(e) =>
                setFilterFields((prev) => ({
                  ...prev,
                  base_unit: e.target.value,
                }))
              }
            />
          </div>

          <div className="flex gap-2">
            <Button size="xs" type="submit">
              Apply
            </Button>
            <Button outline size="xs" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </form>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={products?.data}
            total={products?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    #
                  </th>
                  <th scope="col" className="py-3">
                    Product name
                  </th>
                  <th scope="col" className="py-3">
                    Base Unit
                  </th>
                  <th scope="col" className="py-3">
                    Barcode
                  </th>
                  <th scope="col" className="py-3">
                    Unique Markup
                  </th>
                  <th scope="col" className="py-3">
                    Cost Price&#40;&#8358;&#41;
                  </th>
                  <th scope="col" className="py-3">
                    Selling Price&#40;&#8358;&#41;
                  </th>
                  <th scope="col" className="py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.data?.map((item) => {
                  const imgurl =
                    item.images_links[0] ===
                    "https://dev-assets.gohealthy.ng/items/"
                      ? utills._default_img
                      : item.images_links[0];
                  return (
                    <tr
                      key={item.id}
                      className="bg-white border-b h-14 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td onClick={(e) => e.stopPropagation()} className="px-2">
                        <ImageView url={imgurl} className="w-8 h-8" />
                      </td>
                      <th
                        scope="row"
                        className="h-8 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <ItemEdit
                          title="name"
                          width={215}
                          content={item.name}
                          data={item}
                        />
                      </th>
                      <td className="">
                        <ItemEdit
                          title="base_uint"
                          width={50}
                          content={item.base_unit}
                          data={item}
                        />
                      </td>
                      <td className="">
                        <ItemEdit
                          title="barcode"
                          width={150}
                          content={item.barcode}
                          data={item}
                        />
                      </td>
                      <td className="">
                        <ItemEdit
                          title="markup"
                          width={150}
                          content={item.flat === 1 ? 0 : item.markup}
                          data={item}
                        />
                      </td>
                      <td className="">
                        <ItemEdit
                          title="price"
                          width={150}
                          content={item.price}
                          data={item}
                        />
                      </td>
                      <td className="">
                        {/* <ItemEdit
                        title="selling_price"
                        width={150}
                        content={item.selling_price.toString()}
                        data={item}
                      /> */}
                        {utills._currency_format(item.selling_price, "NGN")}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleDelete(item.uuid)}
                        >
                          <IoTrashOutline />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CustomPagination>
          {/* {isLoading && (
            <div className="flex items-center justify-center my-5">
              <Spinner aria-label="Default status example" />
            </div>
          )}
          {isSuccess && products.data.length === 0 && (
            <div className="flex items-center justify-center my-5">
              <div className="flex flex-col items-center">
                <img src={emptyIcon} alt="empty record" className="w-20" />
                <span className="dark:text-gray-400 mt-2 text-xs">
                  No record found
                </span>
              </div>
            </div>
          )} */}
          {/* {isSuccess && (
            <div className="flex mt-3 mb-5 mr-2 px-2 justify-end">
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(products?.count ?? 0 / 10)}
                onPageChange={onPageChange}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ProductManagement;

interface IItemEdit {
  data: Product;
  content: string | number;
  width: number;
  title: string;
}
const ItemEdit = (props: IItemEdit) => {
  const inputref = useRef<HTMLInputElement>(null);
  const [updatePoduct, { isLoading }] = useUpdateProductMutation();
  const { content, data, width, title } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [entry, setEntry] = useState("");

  const handleSaveData = () => {
    submit();
  };

  const submit = async () => {
    const {
      name,
      discount,
      price,
      company,
      pricein_dollar,
      batch_no,
      quantity,
      status,
      min,
      max,
      description,
      cashback,
      stock,
    } = data;
    const payload = {
      name,
      discount,
      price,
      company,
      pricein_dollar,
      batch_no,
      quantity,
      status,
      min,
      max,
      description,
      cashback,
      stock,
      category: data.category.uuid,
    };
    utills._asynchronous_toast(
      updatePoduct,
      "Item Record Updated",
      "Unable to update item",
      {
        data: { ...payload, [title]: entry, company: data.company.uuid },
        id: data.uuid,
      }
    );
    setIsEdit(false);
  };

  const currency = ["price", "selling_price"];

  useEffect(() => {
    if (isEdit && inputref.current) {
      inputref.current.focus();
    }
    setEntry(content.toString());
  }, [isEdit]);

  if (!isEdit)
    return (
      <span onClick={() => setIsEdit(true)}>
        {currency.includes(title)
          ? utills._currency_format(Number(content), "NGN")
          : content || "-"}
      </span>
    );
  return (
    <div className="flex items-center gap-2 w-max">
      <input
        ref={inputref}
        value={entry}
        onChange={(e) => setEntry(e.target.value)}
        style={{ width }}
        className="bg-gray-50h-5 hide-spin-buttons border-0 text-gray-900 text-xs rounded-lg focus:ring-[#167490] focus:border-[#167490] block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex items-center gap-2">
          <button onClick={handleSaveData}>
            <IoCheckmarkSharp />
          </button>
          <button
            onClick={() => {
              setIsEdit(false);
            }}
          >
            <FaXmark />
          </button>
        </div>
      )}
    </div>
  );
};
