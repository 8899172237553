import { useState } from "react";
import CustomPagination from "../../components/CustomPagination";
import { useGetAttendanceQuery } from "../../redux/queries/attendance";

interface AttendantProps {
  staff: string;
}

const Attendant = ({ staff }: AttendantProps) => {
  const [filters, setFilters] = useState({
    limit: "0,10",
    staff: staff,
  });
  const { data: attendance, isLoading } = useGetAttendanceQuery(filters);
  console.log(attendance);

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={attendance?.data}
          total={attendance?.count}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Day
                </th>
                <th scope="col" className="px-6 py-3">
                  Started Work
                </th>
                <th scope="col" className="px-6 py-3">
                  Taking a break
                </th>
                <th scope="col" className="px-6 py-3">
                  Closed for the day
                </th>
              </tr>
            </thead>
            <tbody>
              {attendance?.data?.map((item) => (
                <tr
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>
    </div>
  );
};

export default Attendant;
