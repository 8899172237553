import { useState, useEffect, useRef, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Alert,
  Button,
  Label,
  Radio,
  Select,
  Spinner,
  TextInput,
  Tooltip,
  useThemeMode,
} from "flowbite-react";
import { HiInformationCircle, HiOutlineDownload } from "react-icons/hi";
import ImageView from "../../components/ImageView";
import moment from "moment";
import { FaEdit, FaMap, FaPlus, FaUsers, FaWallet } from "react-icons/fa";
import { MdCloudDownload, MdPedalBike } from "react-icons/md";
import { LuPackage } from "react-icons/lu";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import CustomerUsers from "./CustomerUsers";
import {
  useGetCompanyQuery,
  useGetUserCompaniesQuery,
  useUpdateCompanyMutation,
} from "../../redux/queries/company";
import CustomersOrder from "./CustomersOrder";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GiMedicines } from "react-icons/gi";
import CustomerDrugs from "./CustomerDrugs";
import CustomerWallet from "./CustomerWallet";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from "../../redux/queries/users";
import { BiSolidPlaneTakeOff } from "react-icons/bi";
import toast from "react-hot-toast";
import AddressSearch from "../../components/AddressSearch";
import {
  useAssignSellingGroupsMutation,
  useGetSellingGroupsQuery,
} from "../../redux/queries/sellingGroup";
import { useAppSelector } from "../../lib/hook";
import { FaPeopleGroup } from "react-icons/fa6";
import { useGetCountriesQuery } from "../../redux/queries/settings";
import {
  useGetCitiesQuery,
  useGetStatesQuery,
} from "../../redux/queries/location";
import PhoneInput from "react-phone-input-2";
import { lowerCase } from "lodash";
import { GrTransaction } from "react-icons/gr";
import CustomerTransactions from "./CustomerTransactions";
import { GiCardDiscard } from "react-icons/gi";
import CustomerDiscards from "./CustomerDiscards";
import CustomLoan from "./CustomLoan";
import { GiDuration } from "react-icons/gi";
import {
  useImportItemsMutation,
  useItemDuplicateMutation,
  useLazyExportItemsQuery,
} from "../../redux/queries/products";
import { useUploadFileMutation } from "../../redux/queries/upload";
import CustomerBranch from "./CustomerBranch";
import { TbFileImport } from "react-icons/tb";
import { PiUploadSimple } from "react-icons/pi";

const cards = [
  { title: "Edit", icon: FaEdit, route: "edit", modal: true },
  { title: "Orders", icon: LuPackage, route: "orders", modal: false },
  { title: "Users", icon: FaUsers, route: "users", modal: false },
  { title: "Riders", icon: MdPedalBike, route: "riders", modal: false },
  { title: "Address", icon: FaMap, route: "address", modal: true },
  { title: "Drugs", icon: GiMedicines, route: "drugs", modal: false },
  {
    title: "Assign Selling Group",
    icon: FaPeopleGroup,
    route: "assign_group",
    modal: true,
  },
  { title: "Wallet", icon: FaWallet, route: "wallet", modal: false },
  {
    title: "Transaction",
    icon: GrTransaction,
    route: "transaction",
    modal: false,
  },
  { title: "Discards", icon: GiCardDiscard, route: "discards", modal: false },
  {
    title: "Loan Repayment",
    icon: BiSolidPlaneTakeOff,
    route: "loan",
    modal: false,
  },
  { title: "Loan Setting", icon: GiDuration, route: "loan-period", modal: true },
];
const ManageCustomer = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { data: customer, isLoading } = useGetCompanyQuery(id ?? "");
  const [page, setPage] = useState("index");
  const [screen, setScreen] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [itemDuplicate] = useItemDuplicateMutation();
  const [genId, setGenId] = useState(customer?.data?.uuid);
  const [exportItems, { isLoading: isExporting }] = useLazyExportItemsQuery();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { data: userBranches } = useGetUserCompaniesQuery({ user: user?.uuid });
  const [loaner, setLoaner] = useState<null | string>(null);

  const [url, setUrl] = useState("");

  useEffect(() => {
    if (type) {
      setPage(type);
    }
  }, [type]);

  if (isLoading) {
    return (
      <div className="flex justify-center pt-7">
        <Spinner />
      </div>
    );
  }

  const handleItemDuplicate = async () => {
    const res = await itemDuplicate({ company_uuid: customer?.data?.uuid });
    if ("data" in res) {
      if (res?.data.status === 200) {
        toast.success(res.data.message);
      } else
        toast.error(res.data?.data?.join("-") ?? "Unable to perform action");
    } else toast.error("Unable to perform action");
  };

  const handleExport = async () => {
    const payload = {
      company: genId ?? customer?.data?.uuid,
    };
    const res = await exportItems(payload);

    if (res.data.status === 200) {
      setUrl(res.data.data);
      setScreen("download");
      setIsOpen(true);
    } else {
      toast.error("Download Failed");
    }
  };

  // const handleImport = async () => {
  //   const payload = {
  //     company: genId ?? customer?.data?.uuid,
  //   };
  //   const res = await importItems(payload);

  //   console.log(res);

  //   // if (res.data.status === 200) {
  //   //   setUrl(res.data.data);
  //   //   setScreen("download");
  //   //   setIsOpen(true);
  //   // } else {
  //   //   toast.error("Download Failed");
  //   // }
  // };

  return (
    <div>
      <Breadcrumb title={`Customer - ${customer?.data?.name}`} />

      {customer?.status !== 200 ? (
        <div>
          <Alert color="failure" icon={HiInformationCircle}>
            <span className="font-medium">Error!</span> Customer not found.
          </Alert>
        </div>
      ) : (
        <div className="flex items-start gap-4 mt-5">
          {!type && (
            <div className="w-[340px] bg-white dark:bg-gray-800 rounded-t-lg">
              <div className="bg-gray-200 dark:bg-gray-700 h-12 flex justify-center items-center rounded-t-lg">
                <span className="text-[14px] font-semibold text-[#167490]">
                  {customer?.data?.name}
                </span>
              </div>
              <div className="p-3">
                <div className="flex justify-center py-4">
                  <ImageView
                    url={customer?.data?.avatar_link}
                    className="w-28 h-28"
                  />
                </div>
                <div>
                  <div className="relative overflow-x-auto shadow-sm">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <tbody>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Code</b>
                          </td>
                          <td className="px-5 py-3">
                            {customer?.data?.code ?? "-"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Wallet</b>
                          </td>
                          <td className="px-5 py-3">
                            {customer?.data?.wallet ?? "-"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Loan</b>
                          </td>
                          <td className="px-5 py-3">
                            {customer?.data?.loan ?? "-"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Name</b>
                          </td>
                          <td className="px-5 py-3">
                            {customer?.data?.name ?? "-"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Email</b>
                          </td>
                          <td className="px-5 py-3 truncate ... max-w-8">
                            <Tooltip content={customer.data.email ?? "-"}>
                              {customer?.data?.email ?? "-"}
                            </Tooltip>
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Phone No.</b>
                          </td>
                          <td className="px-5 py-3">
                            {customer?.data?.phone ?? "-"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Country</b>
                          </td>
                          <td className="px-5 py-3">
                            {customer?.data?.country?.name ?? "-"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Status</b>
                          </td>
                          <td className="px-5 py-3">
                            {customer?.data?.status === 1
                              ? "Active"
                              : "Inactive"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Date</b>
                          </td>
                          <td className="px-5 py-3">
                            {moment(customer?.data?.crdate).format("LLL")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex-1">
            {!type && (
              <div className="bg-white flex justify-between items-center dark:bg-gray-800 p-2 rounded-t-lg">
                <Button
                  size="xs"
                  className="rounded-sm"
                  color="gray"
                  onClick={() => {
                    if (page === "index")
                      navigate(
                        customer.data.type === "manufacturer"
                          ? "/manufacturer"
                          : customer.data.type === "hospital"
                          ? "/hospital"
                          : "/customers"
                      );
                    else setPage("index");
                  }}
                >
                  Go Back
                </Button>

                <div className="flex gap-2 items-end justify-end ml-auto">
                  {page === "drugs" && (
                    <Button
                      size="xs"
                      className="rounded-sm"
                      onClick={() => {
                        setScreen("import");
                        setIsOpen(true);
                      }}
                    >
                      <HiOutlineDownload className="text-base font-bold mr-1" />
                      Import
                    </Button>
                  )}
                  {page === "drugs" &&
                    customer?.data?.type === "manufacturer" && (
                      <Button
                        size="xs"
                        className="rounded-sm"
                        onClick={handleExport}
                        isProcessing={isExporting}
                        disabled={isExporting}
                      >
                        <PiUploadSimple className="text-base font-bold mr-1" />
                        Export
                      </Button>
                    )}

                  {page === "drugs" ? (
                    <Button
                      size="xs"
                      className="rounded-sm "
                      onClick={handleItemDuplicate}
                    >
                      <FaPlus className="mr-2" />
                      Auto Add Item
                    </Button>
                  ) : page === "users" ? (
                    <Button
                      size="xs"
                      className="rounded-sm "
                      onClick={() => setAddUser(true)}
                    >
                      <FaPlus className="mr-2" />
                      Add User
                    </Button>
                  ) : page === "loan" ? (
                    <div className="flex items-center gap-2">
                      <Label value="Loaner :" />
                      <Select
                        value={loaner ?? ""}
                        sizing="sm"
                        onChange={(e) => setLoaner(e.target.value)}
                      >
                        <option value="" hidden>
                          Select Loaner
                        </option>
                        {userBranches?.data.map((item) => (
                          <option key={item.id} value={item.company.uuid}>
                            {item.company.name}
                            {` ${
                              item.company.type !== "branch"
                                ? " (Main branch)"
                                : ""
                            }`}
                          </option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <CustomerBranch
                      staff={customer?.data?.uuid}
                      handleId={(id) => setGenId(id)}
                    />
                  )}
                </div>
              </div>
            )}

            {page === "index" && (
              <div className="mt-5 grid grid-cols-4 gap-5 px-3">
                {cards.map((card) => {
                  if (
                    card.route === "assign_group" &&
                    customer.data.type === "manufacturer"
                  )
                    return null;
                  return (
                    <div
                      onClick={() => {
                        if (card.modal) {
                          setScreen(card.route);
                          setIsOpen(true);
                        } else setPage(card.route);
                      }}
                      key={card.route}
                      className="cursor-pointer bg-white dark:bg-gray-800 hover:scale-[1.1] transition-all w-32 h-32 flex justify-center items-center rounded-lg"
                    >
                      <div className="flex flex-col justify-center items-center">
                        <card.icon className="text-3xl text-[#167490]" />
                        <span className="text-xs text-gray-700 font-semibold dark:text-gray-200">
                          {card.title}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {page === "users" && (
              <CustomerUsers
                id={genId ?? customer?.data?.uuid}
                country={customer?.data?.country}
                type={customer?.data?.type}
              />
            )}
            {page === "riders" && (
              <CustomerUsers
                id={genId ?? customer?.data?.uuid}
                country={customer?.data?.country}
                type="rider"
              />
            )}
            {page === "orders" && (
              <CustomersOrder
                back={() => setPage("index")}
                id={genId ?? customer?.data?.uuid}
                type="rider"
              />
            )}
            {page === "drugs" && (
              <CustomerDrugs id={genId ?? customer?.data?.uuid} />
            )}
            {page === "wallet" && (
              <CustomerWallet id={genId ?? customer?.data?.uuid} />
            )}
            {page === "transaction" && (
              <CustomerTransactions id={genId ?? ""} />
            )}
            {page === "discards" && (
              <CustomerDiscards id={genId ?? customer?.data?.uuid} />
            )}
            {page === "loan" && (
              <CustomLoan
                id={genId ?? customer?.data?.uuid}
                type={customer?.data?.type}
                loaner={loaner}
              />
            )}
          </div>
        </div>
      )}

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {screen === "address" && (
          <Address user={customer?.data} close={() => setIsOpen(false)} />
        )}
        {screen === "edit" && (
          <EditUser user={customer?.data} close={() => setIsOpen(false)} />
        )}
        {screen === "assign_group" && (
          <AssignSellingGroup
            user={customer?.data}
            close={() => setIsOpen(false)}
          />
        )}
        {screen === "loan-period" && (
          <LoanPeriod user={customer?.data} close={() => setIsOpen(false)} />
        )}

        {screen === "download" && (
          <DownloadModel close={() => setIsOpen(false)} url={url} />
        )}

        {screen === "import" && (
          <ImportModal
            close={() => setIsOpen(false)}
            company={genId ?? customer?.data?.uuid ?? ""}
          />
        )}
      </Modal>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={addUser}
        onClose={() => setAddUser(false)}
      >
        <CompanyUserAdd user={customer?.data} close={() => setAddUser(false)} />
      </Modal>
    </div>
  );
};

export default ManageCustomer;

interface IFormInputAddUser {
  name: string;
  email?: string;
  phone: string;
  status: string;
}

const schemaAddUser = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup.string().required("Phone number is a required field"),
  status: yup.string().required("Status is a required field"),
});

export const CompanyUserAdd = (props: UserCard) => {
  const { user, close } = props;
  const [createUser, { isLoading: isCreatingLoading }] =
    useCreateUserMutation();
  const [updateUser, { isLoading: loading }] = useUpdateUserMutation();
  const { mode } = useThemeMode();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputAddUser>({ resolver: yupResolver(schemaAddUser) });

  const onSubmit = async (data: IFormInputAddUser) => {
    const payload = {
      name: data.name,
      email: data.email,
      phone: Number(data.phone),
      status: Number(data.status),
      type: user?.type,
      country_id: user?.country?.id,
      company: user?.uuid,
    };

    const res = (await createUser(payload)) as any;
    if (res?.data?.status === 200) {
      if ("data" in res) {
        const result = await updateUser({
          uuid: res?.data?.data.uuid,
          type: res?.data?.data.type,
          company: user?.uuid,
        });
        if ("data" in result) {
          if (result.data.status === 200) {
            toast.success("User Added");
            close();
          } else toast.error("Unable to add user");
        } else toast.error("Unable to add user");
      } else toast.error("Unable to add user");
    } else toast.error(res.data.data.join(","));
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Add User To Company
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <form
        autoComplete="off"
        className="bg-white p-3 pb-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-3 mt-2">
          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Full Name
            </span>
            <TextInput
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              type="text"
              placeholder="Enter Your Full Name"
              {...register("name")}
              color={errors?.name ? "failure" : "gray"}
              helperText={
                errors.name && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.name?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Email Address
            </span>
            <TextInput
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              type="email"
              placeholder="Enter Email Address"
              {...register("email")}
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Phone Number
            </span>

            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <PhoneInput
                    {...field}
                    country={lowerCase(user?.country?.code)}
                    disabled={loading ?? isCreatingLoading}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    buttonStyle={{
                      backgroundColor: errors?.phone
                        ? "rgb(254 242 242)"
                        : mode === "light"
                        ? "rgb(249 250 251)"
                        : "rgb(75 85 99)",
                      border: `1px solid ${
                        errors?.phone
                          ? "rgb(239 68 68)"
                          : mode === "light"
                          ? "rgb(209 213 219)"
                          : "rgb(75 85 99)"
                      }`,
                    }}
                    inputStyle={{
                      width: "auto",
                      height: "42px",
                      paddingRight: "5px",
                      backgroundColor: errors?.phone
                        ? "rgb(254 242 242)"
                        : mode === "light"
                        ? "rgb(249 250 251)"
                        : "rgb(75 85 99)",
                      border: `1px solid ${
                        errors?.phone
                          ? "rgb(239 68 68)"
                          : mode === "light"
                          ? "rgb(209 213 219)"
                          : "rgb(75 85 99)"
                      }`,
                      color: mode === "light" ? "black" : "white",
                    }}
                    dropdownStyle={{
                      position: "absolute",
                      top: -20,
                      left: 0,
                    }}
                  />
                  {errors.phone && (
                    <span className="font-medium text-[10px] text-red-500">
                      {" "}
                      {errors.phone?.message}!
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <div>
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Status
            </span>
            <Select
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              {...register("status")}
              color={errors?.status ? "failure" : "gray"}
              helperText={
                errors.status && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.status?.message}
                  </span>
                )
              }
            >
              <option value="" disabled hidden>
                Select a status
              </option>
              <option value="1">Active</option>
              <option value="0">InActive</option>
            </Select>
          </div>
        </div>

        <div className="mt-5 flex items-center justify-end">
          <Button
            disabled={isCreatingLoading ?? loading}
            isProcessing={isCreatingLoading ?? loading}
            size="xs"
            type="submit"
            className="rounded-sm"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

interface UserCard {
  user: Branch | undefined;
  close: () => void;
  type?: string;
}
const Address = (props: UserCard) => {
  const { user, close } = props;
  const [address, setAddress] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });
  const [addressDetails, setAddressDetails] = useState(user?.address2);
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();

  const updateAddress = async () => {
    const result = await updateCompany({
      uuid: user?.uuid,
      address,
      address2: addressDetails,
    });
    if ("data" in result) {
      if (result.data.status === 200) {
        toast.success("Address Updated");
        close();
      } else toast.error(result.data.message ?? "Unable to save address");
    } else toast.error("Unable to save address");
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Address</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <div className="bg-white dark:bg-gray-700 p-3 pb-5">
        <div className="">
          <div className="mb-2 block">
            <Label
              htmlFor="address"
              value={
                user?.address.name
                  ? `Current Address: ${user.address.name}`
                  : "Address"
              }
            />
          </div>

          <AddressSearch sizing="md" onSelect={(val) => setAddress(val)} />
        </div>
        <div className=" mt-3">
          <div className="mb-2 block">
            <Label htmlFor="address" value={"Address Details"} />
          </div>

          <TextInput
            id="address-details"
            value={addressDetails}
            type="text"
            className="flex-1"
            sizing="md"
            onChange={(e) => setAddressDetails(e.target.value)}
          />
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="xs"
            className="rounded-sm"
            onClick={updateAddress}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

interface IFormInput {
  name: string;
  email?: string;
  phone: string;
  status: string;
  countryName?: string;
  city?: string;
  whatsapp?: string;
  state_id?: string;
}

// interface PayloadProps {
//   avatar?: string;
//   city_id: number | undefined;
//   country: number | undefined;
//   email: string;
//   licence?: string;
//   name: string;
//   phone: string;
//   state_id: number;
//   status: string;
//   uuid: string | undefined;
//   whatsapp: string;
// }

const schema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup.string().required("Phone number is a required field"),
  status: yup.string().required("Status is a required field"),
  whatsapp: yup.string(),
  city: yup.string(),
  countryName: yup.string(),
  state_id: yup.string(),
});
const EditUser = (props: UserCard) => {
  const { close, user } = props;
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();
  const { data: countries } = useGetCountriesQuery();
  const { mode } = useThemeMode();
  const [countryArr, setCountryArr] = useState<any>([]);
  const [countryName, setCountryName] = useState<any>(user?.country?.name);
  const [stateId, setStateId] = useState<any>(user?.state_id);
  const [licenseFile, setLicenseFile] = useState({
    link: user?.licence_link,
    license: "",
  });
  const [img, setImg] = useState({ link: user?.avatar_link, avatar: "" });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phone", user.phone);
      setValue("status", user.status.toString());
      setValue("whatsapp", user.whatsapp === null ? "" : user.whatsapp);
      setValue("countryName", user?.country?.name);
      setValue(
        "state_id",
        user.state_id
          ? user.state_id?.id
            ? user.state_id.id.toString()
            : user.state_id.toString()
          : ""
      );
      setValue("city", user.city ? user?.city?.name : "");
    }
  }, [user, setValue]);

  useEffect(() => {
    const getCountry = countries?.data?.filter(
      (country) => country?.name === countryName
    );
    setCountryArr(getCountry);
  }, [countries?.data, countryName]);

  const { data: states } = useGetStatesQuery({
    country_id: countryArr ? countryArr[0]?.id : null,
  });

  const { data: cities } = useGetCitiesQuery({
    country_id: countryArr ? countryArr[0]?.id : null,
    state_id: stateId,
  });

  const intialStatesArr = states?.data?.filter(
    (item) => item.id === user?.state_id
  );

  const stateName = intialStatesArr?.length ? intialStatesArr[0]?.name : "";

  const onSubmit = async (data: IFormInput) => {
    const { countryName, state_id, city, ...rest } = data;
    const getCountry = countries?.data?.filter(
      (country) => country?.name === data?.countryName
    );
    const getCity = cities?.data?.filter((cityinn) => cityinn?.name === city);

    const payload: any = {
      ...rest,
      uuid: user?.uuid,
      state_id: Number(state_id),
      city_id: getCity ? getCity[0]?.id : undefined,
      country: getCountry ? getCountry[0]?.id : undefined,
    };
    if (img.avatar) {
      payload.logo = img.avatar;
    }
    if (licenseFile.license) {
      payload.licence = licenseFile.license;
    }
    const res = await updateCompany(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record updted");
        close();
      } else toast.error("Unable to update");
    } else toast.error("Unable to update");
  };

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Edit Customer Info
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form
        className="bg-white dark:bg-gray-800 p-3 pb-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex gap-3">
          <ProfileImageUpload imgLink={img.link} setImg={setImg} />
          <div className="flex-1">
            <div
              className="grid grid-cols-2 gap-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Name"
                  {...register("name")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Email" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Email"
                  {...register("email")}
                  color={errors?.email ? "failure" : "gray"}
                  helperText={
                    errors.email && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.email?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Phone" />
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div>
                      <PhoneInput
                        {...field}
                        country={lowerCase(user?.country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "auto",
                          paddingRight: "5px",
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          top: -20,
                          left: 0,
                        }}
                      />
                      {errors.phone && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.phone?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
              <div>
                <Label value="Status" />
                <Select
                  sizing="sm"
                  {...register("status")}
                  disabled={isLoading}
                  color={errors?.status ? "failure" : "gray"}
                  helperText={
                    errors.status && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.status?.message}!
                      </span>
                    )
                  }
                >
                  <option value={1}>Active</option>
                  <option value={3}>Semi-Active</option>
                  <option value={0}>In-Active</option>
                </Select>
              </div>

              <div>
                <Label value="WhatsApp Number" />
                <Controller
                  name="whatsapp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div>
                      <PhoneInput
                        {...field}
                        country={lowerCase(user?.country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "whatsapp",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "auto",
                          paddingRight: "5px",
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          bottom: -20,
                          left: 0,
                        }}
                      />
                      {errors.whatsapp && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.whatsapp?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>

              <div>
                <Label value="Country" />
                <Select
                  sizing="sm"
                  {...register("countryName", {
                    onChange: (e) => {
                      setCountryName(e.target.value);
                    },
                  })}
                  disabled={isLoading}
                >
                  <option value={user?.country?.name} hidden disabled>
                    {user?.country?.name}
                  </option>

                  {countries?.data?.map((country) => (
                    <option key={country?.id} value={country?.name}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <Label value="State" />
                <Select
                  sizing="sm"
                  {...register("state_id", {
                    onChange: (e) => {
                      setStateId(e.target.value);
                    },
                  })}
                  disabled={isLoading}
                >
                  <option value={stateName} hidden disabled>
                    {stateName}
                  </option>

                  {states?.data?.map((state) => (
                    <option key={state?.id} value={state?.id}>
                      {state.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label value="City" />
                <Select sizing="sm" {...register("city")} disabled={isLoading}>
                  <option value={user?.city?.name} hidden disabled>
                    {user?.city?.name}
                  </option>

                  {cities?.data?.map((cityinn: any) => (
                    <option key={cityinn?.id} value={cityinn?.name}>
                      {cityinn.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <ImageUpload
                  imgLink={licenseFile.link}
                  setImg={setLicenseFile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            size="xs"
            className="rounded-sm"
            type="submit"
            isProcessing={isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

const ProfileImageUpload = ({
  setImg,
  imgLink,
}: {
  imgLink?: string;
  setImg: any;
}) => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const res = await uploadFile({
      file,
      folder: "companies",
      name: "company",
    });
    if ("data" in res) {
      const { links, data } = res.data;
      setImg({ link: links[0], avatar: data[0] });
    } else toast.error("Unable to upload file");
  };

  return (
    <div className="flex flex-col items-center gap-1">
      <div>
        {isLoading ? (
          <div className="w-20 h-20 flex items-center justify-center">
            <Spinner />
          </div>
        ) : imgLink?.length ? (
          <ImageView url={imgLink} className="w-20 h-20" />
        ) : (
          <div className="w-20 h-20 flex items-center justify-center">
            <MdCloudDownload />
          </div>
        )}
      </div>
      <div onClick={() => inputRef.current?.click()}>
        <Button
          size="xs"
          className="text-[8px]"
          outline
          gradientDuoTone="purpleToBlue"
        >
          Change Image
        </Button>
        <input
          disabled={isLoading}
          ref={inputRef}
          onChange={handleFileUpload}
          className="hidden"
          type="file"
        />
      </div>
    </div>
  );
};

const AssignSellingGroup = (props: UserCard) => {
  const { close, user } = props;
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,40",
    company: defaultBranchUUid,
    name: "",
  });
  const [assignSellingGroups, { isLoading }] = useAssignSellingGroupsMutation();
  const [value, setValue] = useState("");

  const { data: sellingGroups } = useGetSellingGroupsQuery({ ...filters });

  const submit = async () => {
    const res = await assignSellingGroups({
      selling_group: value,
      company: user?.uuid as string,
    });
    console.log(res, value, user?.uuid);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Saved");
        close();
      } else
        toast.error(res.data.data.join(",") ?? "Unable to assign pharmarcy");
    } else toast.error("Unable to assign pharmarcy");
  };

  return (
    <div className="w-[400px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Assign Selling Group
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <div className="bg-white dark:bg-gray-800 p-3 pb-5">
        <div>
          <Label value="selling groups" />
          <Select
            disabled={isLoading}
            sizing="sm"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <option value="">Select selling group</option>
            {sellingGroups?.data.map((item) => (
              <option key={item.uuid} value={item.uuid}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="flex justify-end mt-5">
          <Button
            isProcessing={isLoading}
            disabled={isLoading}
            onClick={submit}
            size="xs"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

interface onChangeType {
  name: string;
  value: string;
}

const LoanPeriod = (props: UserCard) => {
  const { close, user } = props;
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();

  const [settings, setSettings] = useState({
    amount: "0",
    by_percentage: "0",
    by_sales: "0",
  });

  const onSubmit = async () => {
    const payload = {
      uuid: user?.uuid,
      loan_deduction_setting: {
        amount: settings.amount,
        by_percentage: settings.by_percentage,
        by_sales: settings.by_sales,
      },
    };
    const res = await updateCompany(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record updted");
        close();
      } else toast.error("Unable to update");
    } else toast.error("Unable to update");
  };

  const onChange = ({ name, value }: onChangeType) => {
    setSettings((settings) => ({ ...settings, [name]: value }));
  };

  useEffect(() => {
    const fetched = () => {
      if (Array.isArray(user?.loan_deduction_setting)) {
        setSettings({
          amount: "0",
          by_percentage: "0",
          by_sales: "0",
        });
      } else {
        setSettings(user?.loan_deduction_setting);
      }
    };

    fetched();
  }, [user?.loan_deduction_setting]);

  const showAmount =
    settings.by_percentage === "1" || settings.by_sales === "1";

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Loan Policy</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="bg-white dark:bg-gray-800 pb-5" onSubmit={onSubmit}>
        <div className="flex flex-col gap-3">
          <fieldset className="flex max-w-md flex-col gap-4 mt-2 p-3">
            <div className="flex items-center gap-2">
              <Radio
                id="per"
                name="per"
                value={1}
                checked={settings?.by_percentage === "1"}
                onChange={() => {
                  onChange({ name: "by_percentage", value: "1" });
                  onChange({ name: "by_sales", value: "0" });
                  setSettings((prev) => ({ ...prev, amount: "0" }));
                }}
              />
              <Label className="flex items-center " htmlFor="per">
                Percentage
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="sales"
                name="sales"
                checked={settings?.by_sales === "1"}
                onChange={() => {
                  onChange({ name: "by_sales", value: "1" });
                  onChange({ name: "by_percentage", value: "0" });
                  setSettings((prev) => ({ ...prev, amount: "0" }));
                }}
              />
              <Label className="flex items-center" htmlFor="sales">
                Sales
              </Label>
            </div>
          </fieldset>
          <div className="border border-gray-50" />

          {showAmount && (
            <div className="pt-1 p-3">
              <Label value="Amount" />
              <TextInput
                className="w-full mr-2"
                sizing="sm"
                type="number"
                min={1}
                max={settings.by_percentage === "1" ? 100 : "∞"}
                onChange={(e) => {
                  setSettings((prev) => ({ ...prev, amount: e.target.value }));
                }}
                value={settings.amount}
                style={{ borderRadius: 2 }}
              />
            </div>
          )}
        </div>
        <div className="mt-3 pt-1 p-3 flex items-center justify-end">
          <Button
            size="xs"
            className="rounded-sm"
            disabled={
              settings.by_percentage === "0" &&
              settings.by_sales === "0" &&
              settings.amount === "0"
            }
            isProcessing={isLoading}
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

const ImageUpload = ({
  setImg,
  imgLink,
}: {
  imgLink?: string;
  setImg: any;
}) => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const res = await uploadFile({
      file,
      folder: "licences",
      name: "licence",
    });
    if ("data" in res) {
      const { links, data } = res.data;
      setImg({ link: links[0], license: data[0] });
    } else toast.error("Unable to upload file");
  };

  return (
    <div className="flex items-center gap-1">
      <div>
        {isLoading ? (
          <div className="w-8 h-8 flex items-center justify-center">
            <Spinner />
          </div>
        ) : imgLink?.length ? (
          <ImageView url={imgLink} className="w-8 h-8 rounded-2xl" />
        ) : null}
      </div>
      <div onClick={() => inputRef.current?.click()}>
        <Button
          size="xs"
          className="text-[8px]"
          outline
          gradientDuoTone="purpleToBlue"
        >
          {imgLink?.length ? "Change License" : "Add License"}
        </Button>
        <input
          disabled={isLoading}
          ref={inputRef}
          onChange={handleFileUpload}
          className="hidden"
          type="file"
        />
      </div>
    </div>
  );
};

interface Download {
  close: () => void;
  url: string;
}

const DownloadModel = ({ close, url: urlLink }: Download) => {
  const downloadFile = async () => {
    const a = document.getElementById("urlLink");
    a?.click();
    toast.success("Downloaded Successfully!!");
  };

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Download Items</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <div className="bg-white dark:bg-gray-800 pb-5">
        <div className="flex flex-col items-center justify-center gap-10 w-full py-5">
          <h6 className="text-xl font-medium text-black dark:text-white">
            Are you willing to download?
          </h6>

          <div className="flex items-center justify-center gap-2 w-full">
            <Button
              size="sm"
              className="rounded-sm w-40"
              onClick={downloadFile}
            >
              <a id="urlLink" href={urlLink} download>
                Yes
              </a>
            </Button>
            <Button
              size="sm"
              className="rounded-sm w-40"
              color="failure"
              onClick={close}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ImportModal = ({
  close,
  company,
}: {
  close: () => void;
  company: string;
}) => {
  const [importItems, { isLoading: isImporting }] = useImportItemsMutation();
  const [upload, setUpload] = useState<any>();
  const [base64, setBase64] = useState<any>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64(reader.result);
      };
      reader.readAsDataURL(file);
      setUpload(file);
    }
  };

  const handleImport = async () => {
    const data = {
      company,
      file: base64,
      filename: upload?.name,
    };

    const res = await importItems(data);
    console.log(res);

    if ("data" in res) {
      const { data } = res;
      if (data.status === 200) {
        toast.success(data.message);
        close();
      } else {
        toast.error("Import Failed");
      }
    } else {
      toast.error("Import Failed");
    }
  };

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Import Items</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <div className="bg-white dark:bg-gray-800 p-3 pb-5 flex flex-col gap-2">
        {upload && upload?.name}
        <div onClick={() => inputRef.current?.click()}>
          <Button
            size="xs"
            className="text-[8px]"
            outline
            gradientDuoTone="purpleToBlue"
          >
            Import
          </Button>
          <input
            ref={inputRef}
            onChange={handleFileUpload}
            className="hidden"
            type="file"
          />
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            size="xs"
            className="rounded-sm"
            isProcessing={isImporting}
            onClick={handleImport}
            disabled={!base64}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
