import Login from "../screens/auth/Login";
import Customers from "../screens/customers";
import Manufacturer from "../screens/customers/Manufacturer";
import Dashboard from "../screens/dashboard";
import Order from "../screens/order";
import Products from "../screens/products";
import AddProducts from "../screens/products/AddProducts";
import Users from "../screens/users";
import ErrorPage from "../screens/error";
import ManageProduct from "../screens/products/ManageProduct";
import Barcode from "../screens/products/Barcode";
import EditProduct from "../screens/products/EditProduct";
import Branches from "../screens/branches";
import Quotation from "../screens/order/Quotation";
import StockTransfer from "../screens/stock/StockTransfer";
import Settings from "../screens/settings";
import AddPurchase from "../screens/purchase";
import AddStock from "../screens/stock/AddStock";
import ManageCustomer from "../screens/customers/ManageCustomer";
import StockList from "../screens/stock/StockList";
import Shelfs from "../screens/shelf";
import ManageShelf from "../screens/shelf/ManageShelf";
import StockCount from "../screens/stock/StockCount";
import ProductManagement from "../screens/productManagement";
import PurchaseHistory from "../screens/purchase/PurchaseHistory";
import StockTransferHistory from "../screens/stock/StockTransferHistory";
import Permissions from "../screens/permissions";
import ManageEmployee from "../screens/users/ManageEmployee";
import Returns from "../screens/returns";
import SellingGroups from "../screens/products/SellingGroups";
import StockTransferEdit from "../screens/stock/StockTranferEdit";
import ProductsStatistics from "../screens/productStatistics/ProductsStatistics";
import ManagePurchaseHistory from "../screens/purchase/ManagePurchaseHistory";
import Payout from "../screens/payout";
import Cheques from "../screens/account/Cheques";
import ContactUs from "../screens/contact";
import FAQ from "../screens/faq";
import Transactions from "../screens/account/Transactions";
import Wallet from "../screens/account/Wallet";
import IncomeStatement from "../screens/account/IncomeStatement";
import CustomerOverView from "../screens/account/CustomerOverView";
import Expenses from "../screens/account/Expenses";
import Hospital from "../screens/customers/Hospital";
import { AiOutlineBranches } from "react-icons/ai";
import { IoPricetags, IoStorefrontSharp } from "react-icons/io5";
import { PiPasswordBold } from "react-icons/pi";
import { GiFrogFoot } from "react-icons/gi";
import {
  GrDocumentPerformance,
  GrSecure,
  GrOrganization,
} from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import Loans from "../screens/account/Loans";
import StockMovement from "../screens/stockMovement";
import {
  MdCategory,
  MdOutlineFlightTakeoff,
  MdSubscriptions,
} from "react-icons/md";
import { BsDatabaseFillAdd } from "react-icons/bs";
import PurchaseItems from "../screens/purchase/PurchaseItems";
import Return from "../screens/order/Return";
import PurchaseReturns from "../screens/purchase/PurachaseReturns";
import OnlinePurchase from "../screens/purchase/OnlinePurchase";
import { TbReorder } from "react-icons/tb";
import Attendance from "../screens/attendant";
import PersonnalSettings from "../screens/personnalSettings";
import PatientsList from "../screens/patients";
import { GrVirtualStorage, GrSchedules } from "react-icons/gr";
import Departments from "../screens/users/Departments";
import AddInvoice from "../screens/order/AddInvoice";
import PharmaInvoice from "../screens/order/PharmaInvoice";
import { ReactNode } from "react";
import ManageOnlinePurchases from "../screens/purchase/ManageOnlinePurchases";
import Cash from "../screens/account/Cash";
import ManagePatients from "../screens/patients/managePatients";
import { FaMap } from "react-icons/fa";
import PharmaQuotation from "../screens/order/PharmaQuotation";
import Invoices from "../screens/account/Invoices";
import LoanHistory from "../screens/account/LoanHistory";
import ReturnHistory from "../screens/order/ReturnHistory";
import PurachaseReturnHistory from "../screens/purchase/ReturnHistory";
import { IoMdNotifications } from "react-icons/io";
import WishList from "../screens/purchase/WishList";
import { BiMoneyWithdraw } from "react-icons/bi";
import { GiProgression } from "react-icons/gi";
import NotificationsPage from "../screens/notifications";
import Bank from "../screens/account/Bank";
import Riders from "../screens/users/Riders";

type IRoute = {
  route: string;
  component: React.ComponentType;
  type: string[];
};
const openRoutes = [
  {
    route: "/login",
    component: Login,
  },
];

const authRoutes: IRoute[] = [
  {
    route: "/dashboard",
    component: Dashboard,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/products",
    component: Products,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/products/:id",
    component: ManageProduct,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/products/edit/:id",
    component: EditProduct,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/orders",
    component: Order,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/customers",
    component: Customers,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/hospital",
    component: Hospital,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/manufacturer",
    component: Manufacturer,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/customer/:id/:type?",
    component: ManageCustomer,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/add-product",
    component: AddProducts,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/employees",
    component: Users,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/departments",
    component: Departments,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/riders",
    component: Riders,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/attendance",
    component: Attendance,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/employees/:id",
    component: ManageEmployee,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/payout",
    component: Payout,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/barcode",
    component: Barcode,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/stock-count",
    component: StockList,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/patients",
    component: PatientsList,
    type: ["pharmacy"],
  },
  {
    route: "/patients/:id",
    component: ManagePatients,
    type: ["pharmacy"],
  },
  {
    route: "/stock-count/:id",
    component: StockCount,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/purchase-history",
    component: PurchaseHistory,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/purchase-return",
    component: PurchaseReturns,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/return-purchase-history",
    component: PurachaseReturnHistory,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/purchase-history/items/:id",
    component: PurchaseItems,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/purchase-history/:id",
    component: ManagePurchaseHistory,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/stock-history",
    component: StockTransferHistory,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/stock-history/:id",
    component: StockTransferEdit,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/wishlist",
    component: WishList,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/product-management",
    component: ProductManagement,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/product-statistics",
    component: ProductsStatistics,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/stock-movement",
    component: StockMovement,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/invoice",
    // component: Invoice,
    component: AddInvoice,
    type: ["manufacturer"],
  },
  {
    route: "/invoice",
    // component: Invoice,
    component: PharmaInvoice,
    type: ["pharmacy"],
  },
  {
    route: "/shelfs",
    component: Shelfs,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/shelfs/:id",
    component: ManageShelf,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/add-stocks",
    component: AddStock,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/add-purchase",
    component: AddPurchase,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/salesreturnhistory",
    component: ReturnHistory,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/quotations",
    component: Quotation,
    type: ["manufacturer"],
  },
  {
    route: "/quotations",
    component: PharmaQuotation,
    type: ["pharmacy"],
  },
  {
    route: "/returnwithoutid",
    component: Return,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/notifications",
    component: NotificationsPage,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/stock-transfer",
    component: StockTransfer,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/settings",
    component: Settings,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/personnal-settings",
    component: PersonnalSettings,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/contact",
    component: ContactUs,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/faq",
    component: FAQ,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/permissions",
    component: Permissions,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/branches",
    component: Branches,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/selling-groups",
    component: SellingGroups,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/returns-refunds",
    component: Returns,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/transactions",
    component: Transactions,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/wallet",
    component: Wallet,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/overview",
    component: CustomerOverView,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/online-purchases",
    component: OnlinePurchase,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/online-purchases/:uuid",
    component: ManageOnlinePurchases,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/income",
    component: IncomeStatement,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/expenses",
    component: Expenses,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/cash",
    component: Cash,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/bank",
    component: Bank,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/addloans",
    component: Loans,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/loanhistory",
    component: LoanHistory,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/invoices",
    component: Invoices,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "/cheque",
    component: Cheques,
    type: ["manufacturer", "pharmacy"],
  },
  {
    route: "*",
    component: ErrorPage,
    type: ["manufacturer", "pharmacy"],
  },
];

const monthsList = [
  { month: "January", value: 1 },
  { month: "February", value: 2 },
  { month: "March", value: 3 },
  { month: "April", value: 4 },
  { month: "May", value: 5 },
  { month: "June", value: 6 },
  { month: "July", value: 7 },
  { month: "August", value: 8 },
  { month: "September", value: 9 },
  { month: "October", value: 10 },
  { month: "November", value: 11 },
  { month: "December", value: 12 },
];

const numbers = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const yearList = [
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
];

export const kpiValueTypeArr = [
  {
    value: 0,
    name: "Based on Quantity",
  },
  {
    value: 1,
    name: "Based on Percentage",
  },
  {
    value: 2,
    name: "Based on Time",
  },
];

const settingsNav = [
  // { name: "Profile", icon: CgProfile, tag: 1 },
  { name: "Company Profile", icon: GrOrganization, tag: 2 },
  { name: "Company DVA", icon: GrVirtualStorage, tag: 3 },
  { name: "Schedule", icon: GrSchedules, tag: 4 },
  { name: "Address", icon: FaMap, tag: 5 },
  { name: "Store", icon: IoStorefrontSharp, tag: 6 },
  { name: "Footer Note", icon: GiFrogFoot, tag: 18 },
  { name: "Withdrawal", icon: BiMoneyWithdraw, tag: 15 },
  { name: "Manage Pricing", icon: IoPricetags, tag: 14 },
  { name: "Credit Score", icon: GiProgression, tag: 16 },
  { name: "Subscription", icon: MdSubscriptions, tag: 17 },
  { name: "Reset Pin", icon: PiPasswordBold, tag: 7 },
  { name: "Kpi Task", icon: GrDocumentPerformance, tag: 8 },
  // { name: "Permission", icon: GrSecure, tag: 9 },
  { name: "Loan", icon: MdOutlineFlightTakeoff, tag: 10 },
  { name: "Categories", icon: MdCategory, tag: 11 },
  { name: "Notifications", icon: IoMdNotifications, tag: 12 },
  { name: "Sync Data", icon: BsDatabaseFillAdd, tag: 13 },
];

const features = [
  { name: "Point of Sale", standard: true, premium: true },
  { name: "Manage Multiple Stores", standard: true, premium: true },
  { name: "Printed or electronic receipts", standard: true, premium: true },
  { name: "Multiple payment methods", standard: true, premium: true },
  { name: "Create, save and edit orders", standard: true, premium: true },
  { name: "Apply Discounts", standard: true, premium: true },
  { name: "Patient/Doctor Video calls", standard: false, premium: true },
  { name: "Instant Website Builder", standard: false, premium: true },
  { name: "Receive Online Orders", standard: false, premium: true },
  { name: "Refunds", standard: true, premium: true },
  { name: "Item variants", standard: true, premium: true },
  { name: "Cash management", standard: true, premium: true },
  { name: "Work Offline", standard: true, premium: true },
  { name: "Easy Scanning", standard: true, premium: true },
  { name: "Dark Theme", standard: true, premium: true },
  { name: "Process Online Orders", standard: false, premium: true },
  { name: "Search & Find Employees", standard: false, premium: true },
  { name: "Auto Payout Staff", standard: false, premium: true },
  { name: "Add, Import & Auto Add New Items", standard: true, premium: true },
  { name: "Low stock notifications", standard: true, premium: true },
  { name: "Purchase Orders", standard: true, premium: true },
  { name: "Stock Transfer", standard: true, premium: true },
  { name: "Print Barcode", standard: true, premium: true },
  { name: "Stock Count", standard: true, premium: true },
  { name: "Manage staff tasks", standard: false, premium: true },
  { name: "Sign Up SMS Message to Customers", standard: false, premium: true },
  {
    name: "Confirmation Code SMS to customers",
    standard: false,
    premium: true,
  },
  { name: "Print Barcode", standard: true, premium: true },
  { name: "Stock Movement History", standard: true, premium: true },
  { name: "Track Staff Performance", standard: true, premium: true },
  { name: "Manage Staff Permissions", standard: true, premium: true },
  { name: "Manage Attendance", standard: true, premium: true },
  { name: "Track Sales Trend", standard: true, premium: true },
  { name: "Customized Yearly Business Report", standard: false, premium: true },
  { name: "Staff Training", standard: false, premium: true },
  {
    name: "Business Consultation & Advisory",
    standard: false,
    premium: true,
  },
  { name: "Manage Sales Performance", standard: true, premium: true },
  { name: "Manage Reward and Loyalty Programs", standard: true, premium: true },
  { name: "Manage Bank Account", standard: true, premium: true },
  { name: "Add Customer Notes", standard: true, premium: true },
  { name: "Manage Wallet Transactions", standard: true, premium: true },
  { name: "Auto Add Low/Out of Stock to Cart", standard: true, premium: true },
  { name: "Auto Fine Staffs", standard: false, premium: true },
  { name: "Third Party Integrations", standard: false, premium: true },
  { name: "Auto Add Purchases to Stock", standard: true, premium: true },
  { name: "Manage Patient Medical History", standard: true, premium: true },
  { name: "Online Drug Purchase", standard: true, premium: true },
  {
    name: "Auto Manage Low/Out Of Stock Quantity",
    standard: true,
    premium: true,
  },
];

const personnalSettingsNav = [
  { name: "Job Profile", tag: 1 },
  { name: "My Branches", tag: 2 },
  { name: "Task", tag: 3 },
  { name: "Leave", tag: 4 },
  { name: "Payouts", tag: 5 },
  { name: "My DVA", tag: 6 },
  { name: "My Wallet", tag: 7 },
  { name: "Reset Password", tag: 8 },
];

const statusData = [
  { name: "Active", value: 1 },
  { name: "SemiActive", value: 3 },
  { name: "InActive", value: 0 },
];

export {
  openRoutes,
  authRoutes,
  monthsList,
  yearList,
  numbers,
  settingsNav,
  personnalSettingsNav,
  statusData,
  features,
};

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
