import { Button, Label, Select, Textarea, TextInput } from "flowbite-react";
import { FormEvent, useState } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import { useReturnOrderMutation } from "../../redux/queries/order";
import toast from "react-hot-toast";

interface IRefunds {
  close: () => void;
  returnId: string;
}

const ReturnAndRefund = (props: IRefunds) => {
  const { close, returnId } = props;
  const [itemUuid, orderUuid] = returnId.split("/");
  const [fields, setFields] = useState({
    refund_type: "cash",
    reason: "",
    quantity: ""
  });

  const [returnOrder, { isLoading }] = useReturnOrderMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      uuid: orderUuid,
      items: [{
        uuid: itemUuid,
        reason: fields.reason,
        quantity: fields.quantity
      }],
      refund_type: fields.refund_type,
    };

    const res = await returnOrder(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        close();
      } else toast.error(res.data.message ?? res.data.data.error);
    } else toast.error("Unable to return item!!!");
  };
  return (
    <div className="w-[400px] bg-white dark:bg-gray-800">
      <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
        <span className="text-white font-semibold text-sm">Return Item</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="p-5" onSubmit={handleSubmit}>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="stu" value="Return Type" />
          </div>

          <Select
            disabled={isLoading}
            id="stu"
            onChange={(e) =>
              setFields((prev) => ({ ...prev, refund_type: e.target.value }))
            }
          >
            <option value={"cash"}>Cash</option>
            <option value={"wallet"}>Wallet</option>
          </Select>
        </div>

        <div className="mt-3">
          <div className="mb-2 block">
            <Label htmlFor="stu" value="Quantity" />
          </div>

          <TextInput value={fields.quantity} type="number" step="any" onChange={(e)=> setFields((prev)=> ({ ...prev, quantity: e.target.value}))} />
        </div>
        <div className="mt-5">
          <div className="mb-2 block">
            <Label htmlFor="comment" value="Return note" />
          </div>
          <Textarea
            disabled={isLoading}
            onChange={(e) =>
              setFields((prev) => ({ ...prev, reason: e.target.value }))
            }
            id="comment"
            placeholder="Return note..."
            rows={3}
          />
        </div>
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          type="submit"
          className="rounded-sm w-full mt-5"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ReturnAndRefund;
