import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Button,
  TextInput,
  Select,
  Pagination,
  Spinner,
  useThemeMode,
} from "flowbite-react";
import { IoIosAdd } from "react-icons/io";
import { MdSearch } from "react-icons/md";
import {
  useCreateUserMutation,
  useGetUsersQuery,
} from "../../redux/queries/users";
import { CiCircleMore } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import moment from "moment";
import { useAppSelector } from "../../lib/hook";
import CustomDropdown from "../../components/CustomDropdown";
import { useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LiaTimesSolid } from "react-icons/lia";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import { lowerCase } from "lodash";
import BranchSwitcher from "../../components/BranchSwitcher";

const Riders = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company.uuid,
    orderBy: "name_desc",
    type: "rider",
  });

  const [isOpen, setIsOpen] = useState("");
  const [userId, setUserId] = useState("");
  const { data: users, isSuccess, isLoading } = useGetUsersQuery(filters);
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    setFilters((prev) => ({ ...prev, limit: `${(page - 1) * 10},10` }));
  };
  console.log(users)

  return (
    <div>
      <Breadcrumb
        title="Riders List"
        rightButton={
          <Button size="xs" onClick={() => setIsOpen("ADD")}>
            <IoIosAdd className="mr-2 h-5 w-5" /> New Rider
          </Button>
        }
      />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <TextInput
              sizing="sm"
              type="text"
              icon={MdSearch}
              placeholder="search..."
            />
          </div>
          <div>
            <BranchSwitcher
              sizing="sm"
              defaultCompany={filters.company}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company: value }))
              }
            />
          </div>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Gender
                </th>
                <th scope="col" className="px-6 py-3">
                  Last Active
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {users?.data?.map((item: any) => (
                <tr
                  onClick={() => navigate(`/employees/${item.uuid}`)}
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-table-search-1"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="checkbox-table-search-1"
                        className="sr-only"
                      >
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td>
                    <img
                      className="w-10"
                      src={item.avatar_link}
                      alt={item.name}
                    />
                  </td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {item.name}
                  </th>
                  <td className="px-6 py-4">{item.email}</td>
                  <td
                    className="px-6 py-4"
                    style={{ textTransform: "uppercase" }}
                  >
                    {item.gender || "-"}
                  </td>
                  <td className="px-6 py-4">
                    {moment(item.lastactive).format("LLL")}
                  </td>
                  <td className="pl-5" onClick={(e) => e.stopPropagation()}>
                    <CustomDropdown
                      trigger={
                        <div className="">
                          <CiCircleMore className="text-2xl" />
                        </div>
                      }
                      menu={[
                        {
                          icon: FaRegEdit,
                          title: "Edit",
                          action: () => {
                            setUserId(item.uuid);
                            setIsOpen("EDIT");
                          },
                        },

                        {
                          icon: IoTrashOutline,
                          title: "Delete",
                          action: () => console.log(""),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="flex items-center justify-center my-5">
              <Spinner aria-label="Default status example" />
            </div>
          )}
          {isSuccess && (
            <div className="flex overflow-x-auto sm:justify-end mt-3 mb-5 mr-2">
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil((users?.count ?? 0) / 10)}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        open={isOpen.length > 0}
        onClose={() => {
          setIsOpen("");
          setUserId("");
        }}
      >
        {isOpen === "ADD" && (
          <AddRider
            user={user}
            close={() => {
              setIsOpen("");
              setUserId("");
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Riders;

interface IFormInputAddUser {
  name: string;
  email?: string;
  phone: string;
  status: string;
}

const schemaAddUser = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup.string().required("Phone number is a required field"),
  status: yup.string().required("Status is a required field"),
});

interface UserCard {
  user?: AppUser | null | User | undefined;
  close: () => void;
  id?: string;
}

const AddRider = (props: UserCard) => {
  const { user, close } = props;
  const [createUser, { isLoading }] = useCreateUserMutation();
  const { mode } = useThemeMode();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputAddUser>({ resolver: yupResolver(schemaAddUser) });

  const onSubmit = async (data: IFormInputAddUser) => {
    const payload = {
      name: data.name,
      email: data.email,
      phone: Number(data.phone),
      status: Number(data.status),
      company: user?.company?.uuid,
      type: "rider",
    };

    const res = (await createUser(payload)) as any;
    console.log(res)
    if (res?.data?.status === 200) {
      toast.success("Rider Added");
      close();
    } else toast.error("Unable to add rider");
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Add New Employee
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <form className="bg-white p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-3 mt-2">
          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Full Name
            </span>
            <TextInput
              disabled={isLoading}
              sizing="md"
              type="text"
              placeholder="Enter Your Full Name"
              {...register("name")}
              color={errors?.name ? "failure" : "gray"}
              helperText={
                errors.name && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.name?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Email Address
            </span>
            <TextInput
              disabled={isLoading}
              sizing="md"
              type="email"
              placeholder="Enter Email Address"
              {...register("email")}
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Phone Number
            </span>

            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <PhoneInput
                    {...field}
                    disabled={isLoading}
                    country={lowerCase(user?.country?.code)}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    buttonStyle={{
                      backgroundColor: errors?.phone
                        ? "rgb(254 242 242)"
                        : mode === "light"
                        ? "rgb(249 250 251)"
                        : "rgb(75 85 99)",
                      border: `1px solid ${
                        errors?.phone
                          ? "rgb(239 68 68)"
                          : mode === "light"
                          ? "rgb(209 213 219)"
                          : "rgb(75 85 99)"
                      }`,
                    }}
                    inputStyle={{
                      width: "auto",
                      height: "42px",
                      paddingRight: "5px",
                      backgroundColor: errors?.phone
                        ? "rgb(254 242 242)"
                        : mode === "light"
                        ? "rgb(249 250 251)"
                        : "rgb(75 85 99)",
                      border: `1px solid ${
                        errors?.phone
                          ? "rgb(239 68 68)"
                          : mode === "light"
                          ? "rgb(209 213 219)"
                          : "rgb(75 85 99)"
                      }`,
                      color: mode === "light" ? "black" : "white",
                    }}
                    dropdownStyle={{
                      position: "absolute",
                      top: -20,
                      left: 0,
                    }}
                  />
                  {errors.phone && (
                    <span className="font-medium text-[10px] text-red-500">
                      {" "}
                      {errors.phone?.message}!
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <div>
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Status
            </span>
            <Select
              disabled={isLoading}
              sizing="md"
              {...register("status")}
              color={errors?.status ? "failure" : "gray"}
              helperText={
                errors.status && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.status?.message}
                  </span>
                )
              }
            >
              <option value="" disabled hidden>
                Select a status
              </option>
              <option value="1">Active</option>
              <option value="0">InActive</option>
            </Select>
          </div>
        </div>

        <div className="mt-6">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="sm"
            type="submit"
            className="rounded-sm w-full"
          >
            ADD
          </Button>
        </div>
      </form>
    </div>
  );
};
