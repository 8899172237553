import { Button, Tooltip } from "flowbite-react";
import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { LuPrinter } from "react-icons/lu";
import utills from "../../lib/functions";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { useGetUserQuery, useLazyGetUserQuery } from "../../redux/queries/users";
import ImageView from "../../components/ImageView";
import { GoTrash } from "react-icons/go";
import {
  addDiscountToInvoice,
  chnageInvoicePrice,
  cleanRecord,
  decreaseInvoiceCartQty,
  increaseInvoiceCartQty,
  removeItemFromInvoiceCart,
  setInvoiceCartQuantity,
} from "../../redux/slices/cart";
import { FaCheckCircle, FaRegEdit, FaTimesCircle } from "react-icons/fa";
import InvoiceCart from "../../components/Documents/InvoiceCartPrint";
import { useAddItemToCartMutation } from "../../redux/queries/cart";
import { useCreateOrderMutation } from "../../redux/queries/order";
import Modal from "react-responsive-modal";
import PrintInvoiceAfterCreating from "../../components/Documents/PrintInvoiceAfterCreating";
import { RiDraftLine } from "react-icons/ri";

const InvoiceTable = ({ editVariant, openDraft, branch, refetchDraft }: { editVariant: (uuid: string )=> void; openDraft: () => void; branch: string; refetchDraft: () => void }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [createOnlineCart, { isLoading }] = useAddItemToCartMutation();
  const [getUserData] = useLazyGetUserQuery();
  const [createOrder, { isLoading: createLoading }] = useCreateOrderMutation();
  const { invoice, invoiceMeta, isInvoiceCurrent } = useAppSelector((state) => state.cartAndDraft);
  const { data: loggedInUser } = useGetUserQuery(user!.uuid, { skip: !user?.uuid });

  const [enablePrint, setEnablePrint] = useState<boolean>(false);
  const [isPrintOpen, setIsPrintOpen] = useState(false);
  const [orderId, setOrderId] = useState<string | undefined>();
  const [cartId, setCartId] = useState<string | undefined>();

  const totalPrice = useMemo(() => {
    let total = 0;
    for (const item of invoice) {
      total += item.price * item.quantity;
    }
    return total;
  }, [invoice]);

  const invoiceAccess = useMemo(
    () =>
      loggedInUser && !Array.isArray(loggedInUser.data.access) ? loggedInUser?.data.access.permissions.split(",").includes("inv-dsc") : undefined,
    [loggedInUser]
  );

  const saveDataToDraft = useCallback(async (print?: boolean) => {
    const payload = {
      items: invoice.map((item) => ({
        uuid: item.uuid,
        price: item.price,
        name: item.name,
        quantity: item.quantity,
        company: item.company,
        verified: item.verified,
        variant_id: item.variant_id,
        base_unit: item.base_unit,
      })),
      type: "11",
      buyer: invoiceMeta.customer.value ?? "",
      delivery_fee: Number(invoiceMeta.delevery_fee ?? "0"),
      payment_mode: "0",
      company: invoiceMeta.company.value
    };
    if(!payload.company){
      return toast.error("Please select company");
    }
    if(!payload.buyer){
      return toast.error("Please select customer");
    }
    const res = await createOnlineCart(payload);
    if ("data" in res) {
      setCartId(res.data.data.invoice_id);
      toast.success("Invoice saved to draft");
      setTimeout(()=> {
        refetchDraft()
       if(!print) dispatch(cleanRecord());
       if(print) setEnablePrint(true)
      } ,2000)
    } else {
      toast.error("Failed to save to draft please try again");
    }
  }, [invoice, invoiceMeta]);

  const createInvoice = async (e: any) => {
    if(!invoiceMeta.customer.value){
      return toast.error("Please select customer");
    }
    const payload = {
      type: 11,
      user: invoiceMeta.customer.value ?? "",
      items: invoice.map((item) => ({
        uuid: item.uuid,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        company: item.company,
        verified: item.verified,
        variant_id: item.variant_id,
      })),
      cart_uuid: invoiceMeta.cart_uuid,
      payment_mode: 0,
      delivery_fee: invoiceMeta.delevery_fee ?? "0",
    };

    const toastId = toast.loading("Creating Invoice...");
    try {
      const res = await createOrder(payload);
      if ("data" in res && res.data.status === 200) {
        toast.success("Invoice created successfully", { id: toastId });
        setOrderId(res.data.data.uuid);
        setIsPrintOpen(true);
        dispatch(cleanRecord());
      }else if('data' in res && res.data.status === 100){
        //@ts-ignore
        toast.error(res.data.message, { id: toastId });

      }else if('data' in res &&  'id' in res.data){
        toast.success("Invoice created successfully", { id: toastId });
        dispatch(cleanRecord());
      }
    } catch (error) {
      //@ts-ignore
      toast.success(`${error.data.message} - ${error.data.data.join("-")}` ?? "Unable to crete invoice", { id: toastId });
    }
  };

  return (
    <div className="mt-10">
      <div className="flex justify-between dark:text-white font-semibold text-[15px] p-2">
        <div className="flex items-center gap-2">
          <Button
            size="xs"
            isProcessing={createLoading}
            disabled={createLoading}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              if (invoice.find((item) => item.verified === 0)) {
                toast.error("Please verify all item");
              } else if (invoice.length === 0) {
                toast.error("Please add item to the invoice");
              } else createInvoice(e);
            }}
            className="rounded-sm"
          >
            <LiaFileInvoiceDollarSolid className="mr-2 h-5 w-5" /> Create Invoice
          </Button>

          <Button
            color="gray"
            className="rounded-sm"
            size="xs"
            onClick={() => {
              if (invoice.find((item) => item.verified === 0)) {
                toast.error("Please verify all item");
              } else if (invoice.length === 0) {
                toast.error("Please add item to the invoice");
              } else saveDataToDraft(true);
            }}
          >
            <LuPrinter className="mr-2 h-5 w-5" />
            Print Data
          </Button>

          {isInvoiceCurrent && (
            <Button
              outline
              size="xs"
              isProcessing={isLoading}
              disabled={isLoading}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (invoice.length === 0) {
                  toast.error("Please add at least a product!!!");
                } else if (!invoiceMeta.customer.value) {
                  toast.error("Please select customer!!!");
                } else saveDataToDraft();
              }}
              className="rounded-sm"
            >
              <LiaFileInvoiceDollarSolid className="mr-2 h-5 w-5" /> Save To Draft
            </Button>
          )}
            <Button
             outline
              size="xs"
            className="rounded-sm"
            onClick={openDraft}
          >
            <RiDraftLine className="mr-2 h-5 w-5" />
            Drafts
          </Button>
        </div>
        <div className="flex items-center gap-3">
          <span>Total Row: {invoice.length}</span>

          <span>Total: {utills._currency_format(totalPrice + Number(invoiceMeta.delevery_fee ?? "0"), "NGN")}</span>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-8">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Base Unit
              </th>
              <th scope="col" className="px-6 py-3">
                Product
              </th>
              <th scope="col" className="px-6 py-3">
                Qty
              </th>
              {invoiceAccess && (
                <th scope="col" className="px-6 py-3">
                  Discount
                </th>
              )}
              <th scope="col" className="px-6 py-3">
                Rate
              </th>
              <th scope="col" className="px-6 py-3">
                Price
              </th>
              <th scope="col" className="px-6 py-3">
                Verified
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {invoice.map((item) => (
              <tr key={item.cart_uuid} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="p-4">
                  <ImageView className="w-8 h-8" url={utills._remove_invalid_link(item.image).length === 0 ? utills._default_img : item.image[0]} />
                </td>
                <td className="px-6 py-4">{item.base_unit}</td>
                <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">{item.name}</td>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <button
                      className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      type="button"
                      onClick={() => {
                        if (item.quantity === 1) {
                          utills._play_error_sound();
                          return;
                        }
                        dispatch(decreaseInvoiceCartQty(item.cart_uuid));
                      }}
                    >
                      <span className="sr-only">Quantity button</span>
                      <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
                      </svg>
                    </button>
                    <div>
                      <input
                        type="number"
                        step="any"
                        id={item.cart_uuid}
                        className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="1"
                        onFocus={(e) => e.target.select()}
                        value={item.quantity}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            const element = document.getElementById("searchFields");
                            element?.focus();
                          }
                        }}
                        onChange={(e) =>
                          dispatch(
                            setInvoiceCartQuantity({
                              cart_uuid: item.cart_uuid,
                              quantity: e.target.value,
                            })
                          )
                        }
                      />
                    </div>
                    <button
                      className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      type="button"
                      onClick={() => dispatch(increaseInvoiceCartQty(item.cart_uuid))}
                    >
                      <span className="sr-only">Quantity button</span>
                      <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                      </svg>
                    </button>
                  </div>
                </td>
                {invoiceAccess && (
                  <td className="px-6 py-4">
                    <input
                      type="number"
                      step="any"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onFocus={(e) => e.target.select()}
                      value={item.discount}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const element = document.getElementById("searchFields");
                          element?.focus();
                        }
                      }}
                      onChange={(e) => dispatch(addDiscountToInvoice({ cart_uuid: item.cart_uuid, discount: e.target.value }))}
                    />
                  </td>
                )}
                <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                  {/* {utills._currency_format(item.price, "NGN")} */}
                  <input
                    type="number"
                    step="any"
                    disabled={!invoiceAccess}
                    className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onFocus={(e) => e.target.select()}
                    value={item.price}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const element = document.getElementById("searchFields");
                        element?.focus();
                      }
                    }}
                    onChange={(e) => dispatch(chnageInvoicePrice({ cart_uuid: item.cart_uuid, price: Number(e.target.value) }))}
                  />
                </td>
                <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                  {utills._currency_format(item.price * item.quantity - Number(item.discount ?? 0), "NGN")}
                </td>
                <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                  {item.verified ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle className="text-red-500" />}
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center gap-3">
                 {item.variant_id && <button
              onClick={() => editVariant(item.uuid)}
              className="cursor-pointer mr-1"
            >
              <Tooltip content="Edit Variants">
                <FaRegEdit className="text-xs text-gray-700 hover:text-gray-400 dark:text-gray-300 dark:hover:text-gray-500" />
              </Tooltip>
            </button>}

                    <Tooltip content="Remove Item">
                      <span
                        onClick={() => dispatch(removeItemFromInvoiceCart(item.cart_uuid))}
                        className="font-medium text-red-600 dark:text-red-500"
                      >
                        <GoTrash />
                      </span>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <InvoiceCart
        isOpen={enablePrint}
        close={() => {
          setEnablePrint(false);
        }}
        items={invoice}
        properties={invoiceMeta}
        cartId={cartId}
        branch={branch}
      />

      <Modal
        open={isPrintOpen}
        onClose={() => setIsPrintOpen(false)}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <PrintInvoiceAfterCreating orderId={orderId} close={() => setIsPrintOpen(false)} />
      </Modal>
    </div>
  );
};

export default InvoiceTable;
