import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { personnalSettingsNav } from "../../route/data";
import BranchTable from "./BranchTable";
import MyWallet from "./MyWallet";
import ResetPassword from "./ResetPassword";

const PersonnalSettings = () => {
  const [tag, setTag] = useState(1);

  return (
    <div>
      <Breadcrumb title="Settings" />

      <div className="w-full flex flex-col gap-10 mt-5">
        <div className="bg-white dark:bg-gray-700 text-gray-600 dark:text-white">
          <div className="flex items-center">
            {personnalSettingsNav.map((item) => (
              <div
                onClick={() => setTag(item.tag)}
                className={`pt-5 pb-3 px-6 border-b-4 flex items-center gap-3 font-medium cursor-pointer text-sm xl:text-base ${
                  tag === item.tag
                    ? "border-[#1f91b2] text-[#1f91b2]"
                    : "border-transparent text-gray-500 dark:text-gray-300"
                }`}
              >
                <span>{item.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="min-h-[400px] bg-white dark:bg-gray-700 dark:text-white">
          {tag === 1 ? (
            <div />
          ) : tag === 2 ? (
            <BranchTable />
          ) : tag === 3 ? (
            <div />
          ) : tag === 4 ? (
            <div />
          ) : tag === 5 ? (
            <div />
          ) : tag === 6 ? (
            <div />
          ) : tag === 7 ? (
            <MyWallet />
          ) : tag === 8 ? (
            <ResetPassword />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PersonnalSettings;
