import { Button, DarkThemeToggle, Label, Select, Spinner, TextInput, useThemeMode } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import { VscAccount } from "react-icons/vsc";
import { CgChevronDown } from "react-icons/cg";
import { PiGearFineBold } from "react-icons/pi";
import { IoLogOutOutline, IoNotifications, IoSettingsSharp } from "react-icons/io5";
import { exitUser, setOffline, updateUserRecord } from "../redux/slices/appUser";
import { Link, useNavigate } from "react-router-dom";
import { LuMenuSquare } from "react-icons/lu";
import CustomDropdown from "../components/CustomDropdown";
import { CgProfile } from "react-icons/cg";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useUpdateUserMutation } from "../redux/queries/users";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { LiaTimesSolid } from "react-icons/lia";
import ImageView from "../components/ImageView";
import PhoneInput from "react-phone-input-2";
import { lowerCase } from "lodash";
import Modal from "react-responsive-modal";
import { IoMdCart, IoMdPricetags } from "react-icons/io";
import PriceChecker from "../components/PriceChecker";
import { useUploadFileMutation } from "../redux/queries/upload";
import { MdCloudDownload } from "react-icons/md";
import { useLogoutMutation } from "../redux/queries/auth";
import Notifications from "../components/Notifications";

const Navbar = ({ toggle }: { toggle: () => void }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, offline } = useAppSelector((state) => state.appUserConfig);
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [priceModal, setPriceModal] = useState(false);
  const [logout] = useLogoutMutation();

  const toggleSwitch = () => {
    dispatch(setOffline());
    window.location.reload();
  };

  const hide = window.location.hostname === "pos.gohealthy.ng";

  return (
    <div className="h-14 w-full bg-white shadow-md border-l border-gray-200 dark:border-gray-700 sticky top-0 z-10  dark:bg-gray-700 flex justify-between items-center px-3">
      <div>
        <span className="cursor-pointer lg:hidden" onClick={toggle}>
          <LuMenuSquare className="text-xl dark:text-gray-200 hover:text-gray-700 dark:hover:text-gray-300" />
        </span>
      </div>
      <div className="flex items-center">
        <div className="mr-3 flex items-center gap-2">
          {!hide && (
            <div
              onClick={toggleSwitch}
              className={`relative w-20 h-8 flex justify-between items-center rounded-full py-1 px-2 cursor-pointer ${
                !offline ? "bg-[#167490]" : "bg-gray-300"
              }`}
            >
              <span className="text-xs font-semibold text-white">ON</span>
              <div
                className={`absolute bg-white w-8 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${!offline ? "translate-x-9" : ""}`}
              />
              <span className="text-xs font-semibold">OFF</span>
            </div>
          )}
          <DarkThemeToggle />
          <Notifications />
          <button
            onClick={() => setPriceModal(true)}
            className="items-center justify-center rounded-lg border border-gray-200 bg-white p-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
          >
            <IoMdPricetags className="h-4 w-4" />
          </button>
          <Link
            to="/pos"
            className="items-center justify-center rounded-lg border border-gray-200 bg-white p-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
          >
            <IoMdCart className="h-4 w-4" />
          </Link>
          <button
            onClick={() => navigate("/personnal-settings")}
            className="items-center justify-center rounded-lg border border-gray-200 bg-white p-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
          >
            <IoSettingsSharp className="h-4 w-4" />
          </button>
        </div>
        <CustomDropdown
          trigger={
            <div className="flex items-center cursor-pointer">
              <VscAccount className="text-2xl mr-1 dark:text-white" />
              <div className="flex flex-col mx-2">
                <h4 className="dark:text-white">{user?.name}</h4>
                <span className="text-xs dark:text-white">{user?.type}</span>
              </div>
              <CgChevronDown className="dark:text-white" />
            </div>
          }
          menu={[
            {
              icon: CgProfile,
              title: "Profile",
              action: () => {
                setIsOpen(true);
                setModal("EDIT");
              },
            },
            {
              icon: PiGearFineBold,
              title: "Settings",
              action: () => {
                navigate("/personnal-settings");
              },
            },
            {
              icon: IoLogOutOutline,
              title: "Sign out",
              action: async () => {
                dispatch(exitUser());
                await logout({});
              },
            },
          ]}
        >
          <span className="text-sm truncate flex items-center">
            <VscAccount className="text-2xl mr-2" />
            {user?.name}
          </span>
        </CustomDropdown>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {modal === "EDIT" && <EditUser user={user} country={user?.country} close={() => setIsOpen(false)} />}
      </Modal>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
          modal: "max-w-[1500px] w-[1000px]",
        }}
        open={priceModal}
        onClose={() => setPriceModal(false)}
      >
        <PriceChecker close={() => setPriceModal(false)} />
      </Modal>
    </div>
  );
};

export default Navbar;

interface UserCarde {
  user: AppUser | null | User | undefined;
  close: () => void;
  country?: BranchCountry;
}

interface IFormInput {
  name: string;
  email?: string;
  phone: string;
  status: string;
  nin?: string;
  bvn?: string;
  religion?: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup.string().min(4, "Phone number is a required field").required("Phone number is a required field"),
  nin: yup.string(),
  bvn: yup.string(),
  religion: yup.string(),
  status: yup.string().required("Status is a required field"),
});

const EditUser = (props: UserCarde) => {
  const { close, user, country } = props;
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { mode } = useThemeMode();
  const [phoneData, setPhoneData] = useState<any>();
  const dispatch = useAppDispatch();
  const [img, setImg] = useState({ link: user?.avatar_link, avatar: "" });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormInput) => {
    const payload = {
      ...data,
      avatar: img.avatar,
      phone_code: phoneData?.countryCode ?? "ng",
    };
    const res = await updateUser({ ...payload, uuid: user?.uuid });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        dispatch(updateUserRecord(res.data.data));
        close();
      } else toast.error(`${res.data.data} - ${res.data.message as string}`);
    } else toast.error("Unable to update record");
  };

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phone", user.phone);
      setValue("nin", user.nin);
      setValue("religion", user.religion);
      setValue("bvn", user.bvn);
      setValue("status", user.status.toString());
    }
  }, [user, setValue]);

  const religionOption = ["christianity", "Muslim", "Traditional worshiper", "Others"];

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Edit Profile</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="bg-white p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-3">
          <ImageUpload imgLink={img.link} setImg={setImg} />
          <div className="flex-1">
            <div className="grid grid-cols-2 gap-3" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Name"
                  {...register("name")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={errors.name && <span className="font-medium text-[10px]"> {errors.name?.message}!</span>}
                />
              </div>
              <div>
                <Label value="Email" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Email"
                  {...register("email")}
                  color={errors?.email ? "failure" : "gray"}
                  helperText={errors.email && <span className="font-medium text-[10px]"> {errors.email?.message}!</span>}
                />
              </div>
              <div>
                <Label value="Phone" />
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <PhoneInput
                        value={value}
                        onChange={(phone, countryData) => {
                          onChange(phone);
                          setPhoneData(countryData);
                        }}
                        country={lowerCase(country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.phone ? "rgb(254 242 242)" : mode === "light" ? "rgb(249 250 251)" : "rgb(75 85 99)",
                          border: `1px solid ${errors?.phone ? "rgb(239 68 68)" : mode === "light" ? "rgb(209 213 219)" : "rgb(75 85 99)"}`,
                        }}
                        inputStyle={{
                          width: "auto",
                          paddingRight: "5px",
                          backgroundColor: errors?.phone ? "rgb(254 242 242)" : mode === "light" ? "rgb(249 250 251)" : "rgb(75 85 99)",
                          border: `1px solid ${errors?.phone ? "rgb(239 68 68)" : mode === "light" ? "rgb(209 213 219)" : "rgb(75 85 99)"}`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          top: -20,
                          left: 0,
                        }}
                      />
                      {errors.phone && <span className="font-medium text-[10px] text-red-500"> {errors.phone?.message}!</span>}
                    </div>
                  )}
                />
              </div>
              <div>
                <Label value="status" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("status")}
                  color={errors?.status ? "failure" : "gray"}
                  helperText={errors.status && <span className="font-medium text-[10px]"> {errors.status?.message}!</span>}
                >
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </Select>
              </div>
              <div>
                <Label value="Nin" />
                <TextInput sizing="sm" type="text" disabled={isLoading} placeholder="NIN" {...register("nin")} />
              </div>
              <div>
                <Label value="BVN" />
                <TextInput sizing="sm" type="text" disabled={isLoading} placeholder="BVN" {...register("bvn")} />
              </div>
              <div>
                <Label value="Religion" />
                <Select sizing="sm" disabled={isLoading} {...register("religion")}>
                  <option value="" disabled hidden>
                    Select a Religion
                  </option>
                  {religionOption.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button disabled={isLoading} isProcessing={isLoading} size="xs" className="rounded-sm" type="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

const ImageUpload = ({ setImg, imgLink }: { imgLink?: string; setImg: any }) => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const res = await uploadFile({
      file,
      folder: "users",
      name: "user",
    });
    if ("data" in res) {
      const { links, data } = res.data;
      setImg({ link: links[0], avatar: data[0] });
    } else toast.error("Unable to upload file");
  };

  return (
    <div className="flex flex-col items-center gap-1">
      <div>
        {isLoading ? (
          <div className="w-20 h-20 flex items-center justify-center">
            <Spinner />
          </div>
        ) : imgLink?.length ? (
          <ImageView url={imgLink} className="w-20 h-20" />
        ) : (
          <div className="w-20 h-20 flex items-center justify-center">
            <MdCloudDownload />
          </div>
        )}
      </div>
      <div onClick={() => inputRef.current?.click()}>
        <Button size="xs" className="text-[8px]" outline gradientDuoTone="purpleToBlue">
          Change Image
        </Button>
        <input disabled={isLoading} ref={inputRef} onChange={handleFileUpload} className="hidden" type="file" />
      </div>
    </div>
  );
};
