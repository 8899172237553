import { api } from "../baseConfig";

export const notificationSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<{ count: number; data: INotifications[]; status: number }, { company?: string, user?: string, limit?: string}>({
      query: (params) => ({
        url: "/notifications/push_notification",
        method: "GET",
        params,
      }),
      providesTags: ["Carts"],
    }),
  }),
});

export const { useGetNotificationsQuery } = notificationSlice;
