import { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Banner, Button, Datepicker, Label, TextInput, Tooltip, Modal as FlowbiteModal } from "flowbite-react";
import { LiaFileInvoiceDollarSolid, LiaTimesSolid } from "react-icons/lia";
import BranchSwitcher from "../../components/BranchSwitcher";
import { FaPlus, FaTruck } from "react-icons/fa";
import ProductSearch from "../../components/ProductSearch";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import CustomSelect from "../../components/CustomSelect";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import { useLazyGetUserQuery, useLazyGetUsersQuery } from "../../redux/queries/users";
import { addToInvoice, invoiceForPreview, resetLocalInvoice, updateInvoice, updateInvoiceMeta } from "../../redux/slices/cart";
import { useDeleteCartMutation, useLazyGetCartItemsQuery } from "../../redux/queries/cart";
import { v4 as uuidv4 } from "uuid";
import VariantModal from "../../components/VariantModal";
import utills from "../../lib/functions";
import { CompanyUserAdd } from "../customers/ManageCustomer";
import Modal from "react-responsive-modal";
import InvoiceTable from "./InvoiceTable";
import { useLazyGetProductQuery } from "../../redux/queries/products";
import toast from "react-hot-toast";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaRegTrashCan } from "react-icons/fa6";

interface MyInterface {
  label: string;
  value: string;
}

export interface Draft {
  id: string;
  uuid: string;
  customer: string;
  total: number;
}

const AddInvoice = () => {
  const dispatch = useAppDispatch();
  const { defaultBranchUUid, user } = useAppSelector((state) => state.appUserConfig);
  const { invoice, invoiceMeta, isInvoiceCurrent, duplicateItem } = useAppSelector((state) => state.cartAndDraft);
  const [branch, setBranch] = useState(defaultBranchUUid);
  const [getProduct] = useLazyGetProductQuery();
  const [variantModal, setVariantModal] = useState(false);
  const [draftOpen, setDraftOpen] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [userDrafts, setUserDrafts] = useState<Draft[]>([]);
  const [variantItem, setVariantItem] = useState<Product>();
  const [customerFilter, setCustomerFilter] = useState({
    limit: "0,20",
    name: "",
    type: "pharmacy",
    status: 1,
  });

  const [getUserData] = useLazyGetUserQuery();
  const [getOnlineInvoice] = useLazyGetCartItemsQuery();
  const { data: companies, isFetching } = useGetCompaniesQuery(customerFilter);
  const [getCustomers, { data: customersList, isFetching: userFetching }] = useLazyGetUsersQuery();

  const handleCustomerSelect = useCallback(
    (data: MyInterface) => {
      dispatch(
        updateInvoiceMeta({
          delevery_fee: invoiceMeta.delevery_fee,
          customer: {
            label: "",
            value: "",
          },
          company: data,
        })
      );
      getCustomers({
        company: data.value,
        status: 1,
        limit: "0,50",
      });
    },
    [invoiceMeta.delevery_fee, getCustomers, dispatch]
  );


  const getInvoiceToRender = useCallback(async () => {
    const res = await getOnlineInvoice({ type: "11" });
    if (res.data?.data) {
      const record = res.data.data;
      if (Array.isArray(record)) {
        const drafts = record.map((item) => ({
          id: item.invoice_id,
          uuid: item.uuid,
          customer: item.buyer_company_detail?.name ?? "",
          total: item.cartitems.reduce((acc, x) => acc + x.price * x.quantity, 0),
        }));
        setUserDrafts(drafts);
        // renderInvoice(record[0]);
      }
    } else if (invoiceMeta.company.value) {
      getCustomers({
        limit: "0,60",
        company: invoiceMeta.company.value,
        status: 1,
      });
    }
  }, [invoiceMeta.company.value]);

  const refetchDrafts = async () => {
    const res = await getOnlineInvoice({ type: "11" });
    if (res.data?.data) {
      const record = res.data.data;
      if (Array.isArray(record)) {
        const drafts = record.map((item) => ({
          id: item.invoice_id,
          uuid: item.uuid,
          customer: item.buyer_company_detail?.name ?? "",
          total: item.cartitems.reduce((acc, x) => acc + x.price * x.quantity, 0),
        }));
        setUserDrafts(drafts);
      }
    }
  }

  const renderInvoice = useCallback(async (obj: ICartFromServer) => {
    if(!obj){
      dispatch(resetLocalInvoice());
      return
    }
    const userCompany = await getUserData(obj.buyer);

    const userCompanydata = userCompany?.data?.data;
    const properties = {
      delevery_fee: obj.delivery_fee.toString(),
      customer: {
        label: userCompanydata?.name ?? "",
        value: userCompanydata?.uuid ?? "",
      },
      company: {
        label: obj.buyer_company_detail.name ?? "",
        value: obj.buyer_company_detail.uuid ?? "",
      },
      invoice_id: obj.invoice_id,
      cart_uuid: obj.uuid,
    };

    const items = obj.cartitems.map((item) => ({
      cart_uuid: uuidv4(),
      uuid: item.item_uuid,
      price: item.price,
      image: item.item.images,
      name: item.name,
      quantity: item.quantity,
      company: item.company,
      verified: item.verified,
      base_unit: item.item.base_unit,
    }));
    if (properties.company.value)
      getCustomers({
        limit: "0,60",
        company: properties.company.value,
        status: 1,
        // type: "user",
      });

    dispatch(
      invoiceForPreview({
        invoice: items.reverse(),
        params: properties,
      })
    );
  }, []);

  const handleItemToCart = useCallback(
    (item: Product, variant?: { id: number; name: string; price: number }) => {
      const cart_uuid = uuidv4();
      const payload: InvoiceCartWithId = {
        cart_uuid,
        uuid: item.uuid,
        price: item.selling_price,
        name: item.name,
        quantity: 1,
        company: item.company.uuid,
        image: item.images_links,
        verified: item.search_by_barcode ? 1 : 0,
        base_unit: item.base_unit,
      };
      if (variant) {
        payload.variant_id = variant.id;
        payload.base_unit = variant.name;
        payload.price = variant.price;
      }
      const isAlreadyExist = invoice.find((item) => item.uuid === payload.uuid && variant?.id);
      if (isAlreadyExist) {
        dispatch(updateInvoice(payload));
      } else dispatch(addToInvoice(payload));
    },
    [invoice, dispatch]
  );

  const editVariant = async (uuid: string) => {
    const toastId = toast.loading("Retrieving variants, please wait...");
    const product = await getProduct(uuid);
    if (product.data) {
      setVariantItem(product.data.data);
      toast.success("Variant retrieved successfully", { id: toastId });
      setVariantModal(true);
    } else toast.error("Failed to retrieve variant", { id: toastId });
  };

  const handleRenderDraft = useCallback(async (draft: Draft) => {
    const toastId = toast.loading("Retrieving draft, please wait...");
    const onlineInvoices = await getOnlineInvoice({ type: "11" });
    if (onlineInvoices.data?.data) {
      const record = onlineInvoices.data.data;
      if (Array.isArray(record)) {
        const selectedDraft = record.find((item) => item.uuid === draft.uuid);
        if (selectedDraft) renderInvoice(selectedDraft);
      }
    }
    toast.success("Draft retrieved successfully", { id: toastId });
  }, []);

  useEffect(() => {
    getInvoiceToRender();
  }, []);

  useEffect(() => {
    if (invoice.length > 0) {
      let pickedItemCopy = structuredClone(invoice);
      const lastdata = pickedItemCopy.shift();
      if (lastdata) {
        const el = document.getElementById(lastdata.cart_uuid);
        setTimeout(() => el?.focus(), 1000);
      }
    }
  }, [invoice.length]);

  const updateDraft = (uuid: string) => {
    setUserDrafts((prev)=> prev.filter((item)=> item.uuid !== uuid))
    dispatch(resetLocalInvoice())
  }

  return (
    <>
      <div>
        <Breadcrumb title="Create Invoice" />
        <div className="mt-10">
          <Banner className="mb-5">
            <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
              <div className="mb-4 md:mb-0 md:mr-4">
                <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">Create Invoice For Customer.</h2>
                <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                  Search through you products and create an invoice.
                </p>
              </div>
              <div className="flex flex-shrink-0 items-center">
                <LiaFileInvoiceDollarSolid className="text-5xl text-gray-500 dark:text-white" />
              </div>
            </div>
          </Banner>
        </div>

        <div className="mt-3">
          <div className="flex items-center">
            <div className="max-w-sm">
              <Label value="Invoice Date" className="mb-1" />
              <Datepicker style={{ borderRadius: 3 }} />
            </div>
          </div>
        </div>
        <div className="mt-2">Invoice ID: {invoiceMeta.invoice_id}</div>

        <div>
          <div className="flex-1 my-4">
            <div className="flex gap-3">
              <div className="w-[300px]">
                <CustomSelect
                  isClearable={false}
                  loading={isFetching}
                  defaultValue={invoiceMeta.company.value ? { ...invoiceMeta.company, disabled: false } : undefined}
                  placeholder="Select Customer..."
                  inputClasses="h-[43px] pt-1 rounded-sm"
                  options={
                    companies?.data.map((customer) => ({
                      label: customer.name,
                      value: customer.uuid,
                    })) ?? []
                  }
                  onSelect={(val) => handleCustomerSelect({ label: val.label, value: val.value })}
                  onSearch={(text) => setCustomerFilter((prev) => ({ ...prev, name: text }))}
                />
              </div>

              <div className="w-[300px]">
                <CustomSelect
                  defaultValue={invoiceMeta.customer.value ? { ...invoiceMeta.customer, disabled: false } : undefined}
                  isClearable={false}
                  loading={userFetching}
                  placeholder="Select staff..."
                  inputClasses="h-[43px] pt-1 rounded-sm"
                  isSearchable={false}
                  options={
                    customersList?.data.map((customer) => ({
                      label: customer.name,
                      value: customer.uuid,
                    })) ?? []
                  }
                  onSelect={(val) => {
                    const userName = customersList?.data.find((item) => item.uuid === val.value);
                    if (userName) {
                      dispatch(
                        updateInvoiceMeta({
                          delevery_fee: invoiceMeta.delevery_fee,
                          company: invoiceMeta.company,
                          customer: {
                            label: userName.name,
                            value: val.value,
                          },
                        })
                      );
                    }
                  }}
                />
              </div>

              {invoiceMeta.company.value && (
                <Tooltip content="Add staff to company">
                  <button onClick={() => setAddUser(true)} className="border border-[#167490] p-3 size-[41px]">
                    <FaPlus className="text-[#167490]" />
                  </button>
                </Tooltip>
              )}

              <BranchSwitcher defaultCompany={branch} sizing="md" styling={{ borderRadius: 3 }} onChange={(text) => setBranch(text)} />
              <TextInput
                id="fee"
                icon={FaTruck}
                value={invoiceMeta.delevery_fee}
                min="0"
                onChange={(e) =>
                  dispatch(
                    updateInvoiceMeta({
                      delevery_fee: e.target.value,
                      customer: invoiceMeta.customer,
                      company: invoiceMeta.company,
                    })
                  )
                }
                type="number"
                step="any"
                className=""
                placeholder="Delivery fee"
                style={{ borderRadius: 2 }}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center mt-5">
          <div className="flex-1 relative">
            <ProductSearch
              sizing="lg"
              companyId={branch}
              onSelect={(item) => {
                console.log(item)
                if(item.quantity <= 0){
                  const userConfirmed = window.confirm(
                    `${item.name} quantity left is (${item.quantity}). Do you still want to add this item?`
                  );
                  if (!userConfirmed) {
                    return;
                  }
                }
                const isItemWithVariant = utills._display_variant(item.variants);
                if (isItemWithVariant) {
                  setVariantItem(item);
                  setVariantModal(true);
                  return;
                } else {
                  handleItemToCart(item);
                }
              }}
              error="Please Select Branch"
            />
          </div>
        </div>
        <InvoiceTable refetchDraft={refetchDrafts} branch={branch} editVariant={editVariant} openDraft={() => setDraftOpen(true)} />
      </div>

      <VariantModal
        isOpen={variantModal}
        close={() => {
          setVariantModal(false);
        }}
        item={variantItem}
        onSelectItemVariant={(product, variant) => handleItemToCart(product, variant)}
      />

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={addUser}
        onClose={() => setAddUser(false)}
      >
        <CompanyUserAdd
          user={companies?.data.find((x) => x.uuid === invoiceMeta.company.value)}
          close={() => {
            setAddUser(false);
            getCustomers({
              company: invoiceMeta.company.value,
              status: 1,
              limit: "0,50",
            });
          }}
          type={companies?.data.find((x) => x.uuid === invoiceMeta.company.value)?.type}
        />
      </Modal>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg z-10",
          root: "!z-[500]",
        }}
        center
        open={draftOpen}
        onClose={() => setDraftOpen(false)}
      >
        <Drafts updateDraft={updateDraft} close={() => setDraftOpen(false)} drafts={userDrafts} onSelect={handleRenderDraft} invoice_id={invoiceMeta.invoice_id} />
      </Modal>
    </>
  );
};
export default AddInvoice;

export const Drafts = ({
  close,
  drafts,
  onSelect,
  invoice_id,
  updateDraft
}: {
  close: () => void;
  drafts: Draft[];
  onSelect: (draft: Draft) => void;
  invoice_id?: string;
  updateDraft: (uuid: string) => void
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [deleteDraft, { isLoading }] = useDeleteCartMutation();

  const handleDelete = useCallback(async () => {
    if (!selected) return;
    const toastId = toast.loading("Deleting draft...");
    const res = await deleteDraft(selected).unwrap();
    if ("status" in res && res.status === 100) {
      toast.success("Draft deleted successfully", { id: toastId });
      updateDraft(selected)
      setOpenModal(false);
    } else {
      toast.error("Failed to delete draft", { id: toastId });
    }
  }, [selected]);

  return (
    <div className="w-[700px] rounded-t-lg bg-white">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Drafts</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <div className="p-3">
        {drafts.map((d) => (
          <div
            key={d.uuid}
            onClick={() => {
              onSelect(d);
              close();
            }}
            className="flex items-center gap-3 p-3 my-1 border cursor-pointer hover:bg-gray-100 rounded-sm shadow-sm"
          >
            <h3 className="flex-1 flex gap-1 items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(true);
                  setSelected(d.uuid);
                }}
                className="text-red-500 hover:text-red-300 mr-2"
              >
                <FaRegTrashCan />
              </button>
              ID: {d.id} {d.id === invoice_id && <IoIosCheckmarkCircle className="text-green-500" />}
            </h3>
            <h3 className="flex-1">Amount: {utills._currency_format(d.total, "NGN")}</h3>
            <h3 className="flex-1">Customer: {d.customer}</h3>
          </div>
        ))}

        <div className="flex mt-3 justify-end">
          <Button onClick={close}>Close</Button>
        </div>
      </div>

      <FlowbiteModal size="md" className="z-[999]" show={openModal} onClose={() => setOpenModal(false)}>
        <FlowbiteModal.Header>Delete Draft</FlowbiteModal.Header>
        <FlowbiteModal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">Are you sure you want to delete this draft?</p>
          </div>
        </FlowbiteModal.Body>
        <FlowbiteModal.Footer>
          <Button isProcessing={isLoading} disabled={isLoading} onClick={() => handleDelete()}>
            Delete
          </Button>
          <Button isProcessing={isLoading} disabled={isLoading} color="gray" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
        </FlowbiteModal.Footer>
      </FlowbiteModal>
    </div>
  );
};
