import { Button } from "flowbite-react";
import { MdOutlineMotionPhotosPause } from "react-icons/md";
import { PiWarningDiamondFill } from "react-icons/pi";
import { IoTrashOutline } from "react-icons/io5";
import { useState } from "react";
import { useGetmovedItemsQuery } from "../../redux/queries/products";
import utills from "../../lib/functions";
import ImageView from "../../components/ImageView";
import CustomPagination from "../../components/CustomPagination";
import BranchSwitcher from "../../components/BranchSwitcher";

interface UsersCard {
  id: string;
}

const CustomerDiscards = ({ id }: UsersCard) => {
  const [filters, setFilters] = useState({
    limit: "0,10",
    company_uuid: id,
    type: 4,
  });
  const { data: products, isLoading } = useGetmovedItemsQuery(filters);
  
  return (
    <div className="mt-3">
      <div className="mb-4 p-2 rounded-sm bg-white dark:bg-gray-700 flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Button
            outline={filters.type !== 4}
            className="rounded-sm _remove_radius"
            size="xs"
            onClick={() => {
              setFilters((prev) => ({ ...prev, type: 4 }));
            }}
          >
            {" "}
            <IoTrashOutline className="mr-2 h-5 w-5" />
            Trash
          </Button>
          <Button
            outline={filters.type !== 3}
            className="rounded-sm _remove_radius"
            size="xs"
            onClick={() => {
              setFilters((prev) => ({ ...prev, type: 3 }));
            }}
          >
            {" "}
            <MdOutlineMotionPhotosPause className="mr-2 h-5 w-5" />
            Scraps
          </Button>
          <Button
            onClick={() => {
              setFilters((prev) => ({ ...prev, type: 5 }));
            }}
            outline={filters.type !== 5}
            className="rounded-sm _remove_radius"
            size="xs"
          >
            {" "}
            <PiWarningDiamondFill className="mr-2 h-5 w-5" />
            Expires
          </Button>
        </div>

        <div>
          <BranchSwitcher
            sizing="sm"
            defaultCompany={filters.company_uuid}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, company_uuid: value }))
            }
          />
        </div>
      </div>

      <CustomPagination
        setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
        isLoading={isLoading}
        data={products?.data}
        total={products?.count ?? 0}
      >
        <table className="w-full text-[10px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Product Name
              </th>
              <th scope="col" className="px-6 py-3">
                quantity
              </th>
              <th scope="col" className="px-6 py-3">
                Price(₦)
              </th>
            </tr>
          </thead>
          <tbody>
            {products?.data?.map((item) => {
              return (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    className="px-6 py-4"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ImageView url={utills._default_img} className="w-8 h-8" />
                  </td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {item?.item?.name}
                  </th>
                  <td className="px-6 py-4">{item.quantity}</td>

                  <td className="px-6 py-4">
                    {utills._currency_format(Number(item?.item?.price), "NGN")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CustomPagination>
    </div>
  );
};

export default CustomerDiscards;
