import React, { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Badge, Button, Select, TextInput } from "flowbite-react";
import { IoIosAdd } from "react-icons/io";
import { MdSearch } from "react-icons/md";
import {
  useDeleteSellingGroupMutation,
  useGetSellingGroupsQuery,
} from "../../redux/queries/sellingGroup";
import { useAppSelector } from "../../lib/hook";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import moment from "moment";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import Modal from "react-responsive-modal";
import SellingGroupForm from "./SellingGroupForm";
import CustomPagination from "../../components/CustomPagination";
import BranchSwitcher from "../../components/BranchSwitcher";

const SellingGroups = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: defaultBranchUUid,
    name: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [sellingGroup, setSellingGroup] = useState<ISellingGroup | undefined>();

  const { data: sellingGroups, isLoading } = useGetSellingGroupsQuery(filters);
  const [deleteGroup] = useDeleteSellingGroupMutation();

  const handleDelete = async (uuid: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action) {
      utills._asynchronous_toast(
        deleteGroup,
        "Selling Group Deleted",
        "Unable to delete selling group",
        uuid
      );
    }
  };

  return (
    <div>
      <Breadcrumb
        title="Selling Groups List"
        rightButton={
          <Button size="xs" onClick={() => setIsOpen(true)}>
            <IoIosAdd className="mr-2 h-5 w-5" /> New Selling Group
          </Button>
        }
      />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <TextInput
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, name: e.target.value }))
              }
              type="text"
              icon={MdSearch}
              placeholder="search..."
            />
          </div>

          <div>
            <BranchSwitcher
              sizing="md"
              defaultCompany={filters.company}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company: value }))
              }
            />
          </div>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={sellingGroups?.data}
            total={sellingGroups?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    #
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Country
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {sellingGroups?.data.map((sellinggroup) => (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    key={sellinggroup.uuid}
                  >
                    <td className="px-6" onClick={(e) => e.stopPropagation()}>
                      <ImageView
                        url={utills._default_img}
                        className="w-10 h-10"
                      />
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {sellinggroup.name}{" "}
                      <div className="font-light text-xs">
                        {sellinggroup.description}
                      </div>
                    </th>
                    <td className="px-6 py-4">{sellinggroup.country.name}</td>
                    <td className="px-6 py-4">
                      <Badge
                        className="w-max"
                        color={
                          sellinggroup.status === 1 ? "success" : "failure"
                        }
                      >
                        {sellinggroup.status === 1 ? "active" : "inactive"}
                      </Badge>
                    </td>
                    <td className="px-6 py-4">
                      {moment(sellinggroup.created_at).format(
                        "dddd, MMMM Do YYYY, h:mm"
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <CustomDropdown
                        trigger={
                          <div className="">
                            <CiCircleMore className="text-2xl" />
                          </div>
                        }
                        menu={[
                          {
                            icon: FaRegEdit,
                            title: "Edit",
                            action: () => {
                              setSellingGroup(sellinggroup);
                              setIsOpen(true);
                            },
                          },
                          {
                            icon: IoTrashOutline,
                            title: "Delete",
                            action: () => handleDelete(sellinggroup.uuid),
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
      <Modal
        // showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSellingGroup(undefined);
        }}
      >
        <SellingGroupForm
          close={() => {
            setIsOpen(false);
            setSellingGroup(undefined);
          }}
          sellingGroup={sellingGroup}
        />
      </Modal>
    </div>
  );
};

export default SellingGroups;
