import { Banner, Button, Label, TextInput } from "flowbite-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { GiProgression } from "react-icons/gi";
import utills from "../../lib/functions";

const CreditScore = () => {
  const credits = [
    `${utills._currency_format(Number(50000) ?? 0, "NGN")} Pos transaction`,
    `${utills._currency_format(
      Number(50000) ?? 0,
      "NGN"
    )} purchase drug online`,
    "Stock Count",
    "Complete online order",
    "Call a doctor",
    "Job Post",
    "Loan Repayment",
    "Default",
    "Register Payments",
  ];

  const handleSubmit = async () => {
    // const res = await maintainPrice({ company: defaultBranchUUid, companies });
    // if ("data" in res) {
    //   if (res.data.status === 200) {
    //     toast.success(res.data.message);
    //   } else {
    //     toast.error("Failed to maintain price");
    //   }
    // } else toast.error("Failed to maintain price");
  };

  return (
    <div className="mt-10">
      <Banner className="mb-5">
        <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
          <div className="mb-4 md:mb-0 md:mr-4">
            <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
              Credit Score
            </h2>
          </div>
          <div className="flex flex-shrink-0 items-center">
            <GiProgression className="text-5xl text-gray-500 dark:text-white" />
          </div>
        </div>
      </Banner>

      <div className="p-4 flex flex-col justify-center items-center gap-3 w-full">
        {credits?.map((credit) => (
          <div
            key={credit}
            className="flex gap-2 items-center justify-between w-[80%]"
          >
            <Label>{credit}</Label>
            <TextInput
              sizing="sm"
              type="text"
              //disabled={isLoading}
            />
          </div>
        ))}
      </div>

      <div className="mt-10 flex items-center justify-end mr-10">
        <Button
          //   disabled={isLoading}
          //   isProcessing={isLoading}
          size="xs"
          className="rounded-sm"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CreditScore;
