import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  status?: number;
  company?: string;
  category?: string;
  item_uuid?: string;
  supplier?: string;
  type?: number | string;
  company_uuid?: string;
}

interface SingleProductResponse {
  status: number;
  message: string;
  data: Product;
}

interface EditProductHistoryResponse {
  data: EditProductHistory[];
  status: number;
  count: number;
}

interface moveItemFilter {
  type: number;
  item_uuid: string;
  quantity: number;
  reason: string;
}

interface WishListResponse {
  status: number;
  data: WishList[];
  count: number;
}

export const productSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<ProductResponse, PayloadFilter>({
      query: (filters) => ({
        url: "/items",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Products"],
    }),
    getProduct: builder.query<SingleProductResponse, string>({
      query: (id) => ({
        url: `/items/${id}`,
        method: "GET",
      }),
      providesTags: ["Products"],
    }),
    updateProduct: builder.mutation<
      SingleProductResponse,
      { id: string; data: any }
    >({
      query: ({ id, data }) => ({
        url: `/items/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    createProduct: builder.mutation<CreatProductResponse, any>({
      query: (data) => ({
        url: "/items",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    deleteProduct: builder.mutation<CreatProductResponse, string>({
      query: (id) => ({
        url: `/items/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products"],
    }),
    getProductEditHistory: builder.query<
      EditProductHistoryResponse,
      PayloadFilter
    >({
      query: (filters) => ({
        url: `/items/histories`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Products"],
    }),
    moveItem: builder.mutation<any, moveItemFilter>({
      query: (data) => ({
        url: "/items/movement",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    getmovedItems: builder.query<ProductResponse, PayloadFilter>({
      query: (filters) => ({
        url: "/items/movement",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Products"],
    }),
    itemDuplicate: builder.mutation<any, PayloadFilter>({
      query: (data) => ({
        url: "/items/item_duplication_request",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    addWishlist: builder.mutation<any, any>({
      query: (data) => ({
        url: "/items/wishlist",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    getWishList: builder.query<WishListResponse, PayloadFilter>({
      query: (filters) => ({
        url: "items/wishlists",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Products"],
    }),
    exportItems: builder.query<any, PayloadFilter>({
      query: (filters) => ({
        url: "/export/items",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Products"],
    }),
    importItems: builder.mutation<any, any>({
      query: (data) => ({
        url: "/import/items",
        method: "POST",
        body: JSON.stringify(data),
      }),
      invalidatesTags: ["Products"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useLazyGetProductsQuery,
  useLazyGetProductQuery,
  useUpdateProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductEditHistoryQuery,
  useMoveItemMutation,
  useGetmovedItemsQuery,
  useItemDuplicateMutation,
  useAddWishlistMutation,
  useGetWishListQuery,
  useLazyExportItemsQuery,
  useImportItemsMutation,
} = productSlice;
